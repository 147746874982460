import React, {
  createContext, useReducer, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import ReportingReducer from './ReportingReducer';

const storedContext = sessionStorage.getItem('ReportingContext');

const initialState = storedContext ? JSON.parse(storedContext) : {
  importToggled: false,
  selectedStores: [],
};

export const ReportingContext = createContext(initialState);

export const ReportingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ReportingReducer, initialState);

  useEffect(() => {
    sessionStorage.setItem('ReportingContext', JSON.stringify(state));
  }, [state]);

  const setSelectedStores = (selectedStores) => {
    dispatch({
      payload: { selectedStores },
      type: 'SET_SELECTED_STORES',
    });
  };

  const setImportToggled = (importToggled) => {
    dispatch({
      payload: { importToggled },
      type: 'SET_IMPORT_TOGGLED',
    });
  };

  const providerValue = useMemo(() => ({
    importToggled: state.importToggled,
    selectedStores: state.selectedStores,
    setImportToggled,
    setSelectedStores,
  }), [state]);

  return (
    <ReportingContext.Provider value={providerValue}>
      {children}
    </ReportingContext.Provider>
  );
};

ReportingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
