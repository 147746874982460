import '../../styles/scss/Error.scss';

const ErrorPage = () => (
  <div id='error-page'>
    <div id='error-details'>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  </div>
);

export default ErrorPage;
