import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputField from '../../../components/Form/FormInputField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import { displayErrorNotification } from '../../../components/Notification/Notify';
import { maxLengths } from '../../../helpers/validation';

const ResetMFA = ({
  onFailure, onSuccess, resetQuestions, visible, ...modalProps
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();

  const methods = useForm();
  const {
    handleSubmit,
    reset,
  } = methods;

  const handleMessageAcknowledgment = useCallback(() => {
    setShowMessage(false);
    onSuccess();
  }, [onSuccess]);

  const submitSecurityQuestions = async (questionAnswerPairs) => {
    try {
      const response = await axios.post('/api/authentication/challengeSecurityQuestions', { questionAnswerPairs });
      const { status } = response;
      if (status === 423) onFailure({ message: t('form.login.error.locked') });
      else if (status !== 200) throw new Error('Failed to verify security questions.');
      setShowMessage(true);
      reset();
    } catch {
      displayErrorNotification(t('form.login.mfa.reset.incorrectAnswers'));
    }
  };

  const {
    securityQuestions: {
      ANSWER_MAX_LENGTH,
    },
  } = maxLengths;

  return (

    <FormProvider {...methods}>
      {showMessage
        ? (
          <ModalForm
            {...modalProps}
            confirmAction={handleMessageAcknowledgment}
            cancelAction=''
            modalTitle={t('form.login.mfa.registration.title')}
            visible={visible}
          >
            <div>{t('form.login.mfa.reset.confirmationMessage')}</div>
          </ModalForm>
        )
        : (
          <ModalForm
            {...modalProps}
            confirmAction={handleSubmit(submitSecurityQuestions)}
            modalCancelText={t('buttons.cancel')}
            modalConfirmText={t('buttons.submit')}
            modalTitle={t('form.login.mfa.registration.title')}
            visible={visible}
          >
            <form className='mfa' id='mfa-reset' onSubmit={handleSubmit(submitSecurityQuestions)}>
              {resetQuestions.map((question, index) => (
                <div className='mfa-question' key={question}>
                  <div className='question'>{question}</div>
                  {/* adding hidden input to track question related to supplied answer */}
                  <FormInputField
                    defaultValue={question}
                    id={`mfa-question-${index + 1}`}
                    fieldName={`mfa-question-${index + 1}`}
                    type='hidden'
                  />
                  <div className='row answer'>
                    <FormInputField
                      autocomplete='off'
                      fieldName={`mfa-answer-${index + 1}`}
                      placeholder={`${t('form.login.mfa.reset.answerPlaceholder')} ${index + 1}`}
                      type='text'
                      validationRules={{
                        maxLength: {
                          message: t('form.fieldExceedsMaxLength', { maxLength: ANSWER_MAX_LENGTH }),
                          value: ANSWER_MAX_LENGTH,
                        },
                        required: t('form.required'),
                      }}
                    />
                  </div>
                </div>
              ))}
            </form>
          </ModalForm>
        )}
    </FormProvider>
  );
};

ResetMFA.propTypes = {
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resetQuestions: PropTypes.arrayOf(PropTypes.string).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ResetMFA;
