/**
 * Takes two string values, converts them to base-10 int, and subtract.
 * Used to sort tables
 * @param {string} string1 - a string that represents a number
 * @param {string} string2 - another string that represents a number
 * @returns - the result of subtracting the the second value from the first. Returns 0 if error.
 */
const intCompare = (string1, string2) => {
  try {
    const int1 = parseInt(string1, 10);
    const int2 = parseInt(string2, 10);
    return int1 - int2;
  } catch {
    return 0;
  }
};

/**
 * Takes two string values, converts them to floating point numbers, and subtract.
 * Used to sort tables
 * @param {string} string1 - a string that represents a floating point number
 * @param {string} string2 - another string that represents a floating point number
 * @returns - the result of subtracting the the second value from the first. Returns 0 if error.
 */
const floatCompare = (string1, string2) => {
  try {
    const int1 = parseFloat(string1);
    const int2 = parseFloat(string2);
    return int1 - int2;
  } catch {
    return 0;
  }
};

/**
 * Takes two string values, converts them to dates, and subtract.
 * Used to sort tables
 * @param {string} string1 - a string that represents a date
 * @param {string} string2 - another string that represents a date
 * @returns - the result of subtracting the the second value from the first. Returns 0 if error.
 */
const dateCompare = (string1, string2) => {
  try {
    const date1 = new Date(string1);
    const date2 = new Date(string2);
    return date1 - date2;
  } catch {
    return 0;
  }
};

module.exports = {
  dateCompare,
  floatCompare,
  intCompare,
};
