import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserCollection from '../../components/UserCollection/UserCollection';
import useAuthentication from '../../hooks/useAuthentication';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import { GlobalContext } from '../../context/Global/GlobalContext';
import '../../styles/scss/AccessManagement.scss';
import { displayErrorNotification } from '../../components/Notification/Notify';

const UserManagement = () => {
  const {
    sessionData: { groupId: loggedInGroupId },
  } = useContext(GlobalContext);
  const ADMIN_GROUP = 'FMADMIN';
  const isAdmin = loggedInGroupId === ADMIN_GROUP;

  const { callAPI } = useAuthentication();
  const { t } = useTranslation();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const callInitiated = useRef(false);

  useEffect(() => {
    const getUserList = async () => {
      try {
        setIsLoading(true);
        const { data, status, statusText } = await callAPI('/api/accessManagement/getUserList', {}, 'post');
        if (status === 200) {
          const { resultList } = data;
          setUsers(resultList);
        } else {
          throw Error(`${status} ${statusText}`);
        }
      } catch ({ message }) {
        displayErrorNotification(t('site.error', { message }));
      } finally {
        setIsLoading(false);
      }
    };

    if (!callInitiated.current) {
      callInitiated.current = true;
      getUserList();
    }
  }, [callAPI, t]);

  return (
    <div className='container access-management'>
      <header>
        <h1>{t('site.sidebar.accessManagement.users')}</h1>
        {isAdmin
          && (
            <Link to='/access/users/create'>
              <button type='submit' className='btn'>{t('users.createNewUser')}</button>
            </Link>
          ) }
      </header>
      <div className='row'>
        <div className='col-12'>
          {isLoading ? elastic : <UserCollection users={users} />}
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
