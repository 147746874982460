import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context/Global/GlobalContext';
import MenuDropdown from '../MenuDropdown/MenuDropdown';
import { displayErrorNotification, displayInfoNotification } from '../Notification/Notify';
import useAuthentication from '../../hooks/useAuthentication';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';

const UserActions = ({ userName }) => {
  const {
    emulationMode: {
      read: emulationModeEnabled = false,
    },
    emulatedUser = null,
    setEmulatedUser,
    setUserPrivileges,
  } = useContext(GlobalContext);

  const {
    resetOrderContext,
  } = useContext(CardOrderingContext);

  const { callAPI, setAuthenticated } = useAuthentication();
  const { logout: logOutSSO, user } = useAuth0();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const logOut = async () => {
    try {
      if (emulationModeEnabled) {
        resetOrderContext(null);
        setEmulatedUser(null);
      }
      await callAPI('/api/authentication/logout');
      setAuthenticated(false);
      if (user) {
        const logoutRedirectURI = `${window.location.origin}`;
        await logOutSSO({
          logoutParams: {
            returnTo: logoutRedirectURI,
          },
        });
        return;
      }
      navigate('/login');
    } catch {
      displayErrorNotification('header.logoutError');
    }
  };

  const endEmulation = () => {
    resetOrderContext(null);
    setEmulatedUser(null);
    setUserPrivileges([]);
    displayInfoNotification(t('emulationMode.ended'));
    const administrationViewRoutes = ['/', '/emulation'];
    if (!administrationViewRoutes.includes(pathname)) {
      navigate('/');
    }
  };

  const items = [<div key='logout-link' onClick={logOut} role='button' tabIndex={0}>{t('header.logout')}</div>];
  if (!emulationModeEnabled) {
    items.unshift(<Link key='profile-link' to='/profile'>{t('header.userProfile')}</Link>);
  } else if (emulatedUser) {
    items.unshift(<div key='end-emulation-mode' onClick={endEmulation} role='button' tabIndex={0}>{t('header.emulationMode.endEmulation')}</div>);
  }

  return (
    <>
      <div id='current-user-display-name'>{userName}</div>
      <MenuDropdown icon={emulationModeEnabled && emulatedUser ? 'fa-user-secret' : 'fa-user'} items={items} />
    </>
  );
};

UserActions.propTypes = {
  userName: PropTypes.string,
};

UserActions.defaultProps = {
  userName: null,
};

export default UserActions;
