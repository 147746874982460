import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { getReadableCountryAndState } from '../../utils/codes';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';

const StoreListing = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const { codes } = useContext(GlobalContext);

  const navigate = useNavigate();

  const handleClick = (path, store = undefined) => (event) => {
    event.stopPropagation();
    if (store) {
      navigate(path, { state: { store } });
    } else {
      navigate(path);
    }
  };

  const sortedStores = [...data];
  sortedStores.sort((
    { storeInfo: { storeNumber: storeA } },
    { storeInfo: { storeNumber: storeB } },
  ) => {
    const lowerA = storeA.toLowerCase();
    const lowerB = storeB.toLowerCase();
    if (lowerA > lowerB) {
      return 1;
    }
    if (lowerA < lowerB) {
      return -1;
    }
    return 0;
  });

  const storeCards = sortedStores.map((
    store,
  ) => {
    const {
      addresses: {
        store: {
          address1, address2, city, stateProvince, postalCode, countryCode,
        },
      },
      storeInfo: {
        storeNumber, paSigned, hasAch, divStatus, storeId, hasOutstandingOrders,
      },
    } = store;

    const {
      readableCountry,
      readableStateProvince,
    } = getReadableCountryAndState(countryCode, stateProvince, codes);

    const ACTIVE_STORE = 'A';
    const statusClass = divStatus === ACTIVE_STORE ? 'active' : 'inactive';

    return (
      <article
        key={storeId}
        className='store'
        onClick={handleClick('/store', store)}
      >
        <div className='header'>
          <h2>{storeNumber}</h2>
        </div>
        <div className='details'>
          <div>
            <h3>{t('storeDetails.storeAddress')}</h3>
            <address>
              <div>{address1 || ''}</div>
              {address2 && <div>{address2}</div>}
              <div>{`${city || ''}, ${readableStateProvince || ''} ${postalCode || ''}`}</div>
              <div>{readableCountry || ''}</div>
            </address>
          </div>
          <div className='status'>
            <div className={statusClass}>
              {t(`dashboard.${statusClass}Store`)}
            </div>
          </div>
        </div>
        {hasOutstandingOrders && (
          <div className='outstanding-orders'>
            <i className='fa-solid fa-credit-card' />
            {t('dashboard.hasOutstandingOrders')}
          </div>
        )}
        <div className='actions'>
          {(!hasAch || !paSigned) && <h3>{t('dashboard.actionsRequired')}</h3>}
          { !hasAch && (
            <div className='text-danger'>
              {t('storeDetails.setupAch')}
            </div>
          )}
          {!paSigned && (
            <div className='text-danger'>
              {t('dashboard.signParticipationAgreement')}
            </div>
          )}
        </div>
      </article>
    );
  });

  return (
    <section id='stores-container'>
      <header id='stores-header'>
        <h1>{t('dashboard.stores')}</h1>
      </header>
      { isLoading ? elastic : (
        <div id='stores'>
          {storeCards?.length ? storeCards : <span className='noStores'>{t('dashboard.noStores')}</span> }
        </div>
      )}
    </section>
  );
};

const addressPropType = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  contactName: PropTypes.string,
  contactPhone: PropTypes.string,
  countryCode: PropTypes.number,
  postalCode: PropTypes.string,
  stateProvince: PropTypes.number,
  storeName: PropTypes.string,
});

const detailsPropType = PropTypes.shape({
  clientPersonId: PropTypes.string,
  emailAddress: PropTypes.string,
  groupId: PropTypes.string,
  locale: PropTypes.string,
  phoneNumber: PropTypes.string,
  primaryContact: PropTypes.bool,
  signonId: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
});

const storeInfoPropType = PropTypes.shape({
  corporationName: PropTypes.string,
  division: PropTypes.number,
  hasAch: PropTypes.bool,
  merchant: PropTypes.number,
  paSigned: PropTypes.bool,
  posWaiver: PropTypes.string,
  storeNumber: PropTypes.string,
});

const usersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    details: detailsPropType,
  }),
);

const dataPropType = PropTypes.arrayOf(
  PropTypes.shape({
    addresses: PropTypes.shape({
      shipping: addressPropType,
      store: addressPropType,
    }),
    storeInfo: storeInfoPropType,
    users: usersPropType,
  }),
);

StoreListing.propTypes = {
  data: dataPropType,
  isLoading: PropTypes.bool,
};

StoreListing.defaultProps = [{
  addresses: {
    shipping: {
      address1: '',
      address2: '',
      city: '',
      contactName: '',
      contactPhone: '',
      countryCode: 0,
      postalCode: '',
      stateProvince: 0,
      storeName: '',
    },
    store: {
      address1: '',
      address2: '',
      city: '',
      contactName: '',
      contactPhone: '',
      countryCode: 0,
      postalCode: '',
      stateProvince: 0,
      storeName: '',
    },
  },
  storeInfo: {
    corporationName: '',
    division: 0,
    hasAch: false,
    merchant: 0,
    paSigned: false,
    posWaiver: '',
    storeNumber: '',
  },
  users: [],
}];

export default StoreListing;
