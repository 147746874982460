import React, {
  useState, useEffect, useContext, useMemo, useCallback,
  useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/scss/Stores.scss';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useSort } from '@table-library/react-table-library/sort';
import { usePagination } from '@table-library/react-table-library/pagination';
import EditShippingAddress from './EditShippingAddress';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { getReadableCountryAndState, getReadableAccountType, translateFromCodes } from '../../utils/codes';
import UserCollection from '../../components/UserCollection/UserCollection';
import ContentCard from '../../components/ContentCard/ContentCard';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import { sortHelper } from '../../utils/helpers';
import OrderDetails from './OrderDetails';
import Paginator from '../../components/Paginator/Paginator';

const StoreDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { callAPI } = useAuthentication();
  const callInitiated = useRef(false);

  const retrieveStoreFromStorage = () => {
    const serializedStore = sessionStorage.getItem('currentStoreDetail');
    return serializedStore ? JSON.parse(serializedStore) : null;
  };

  const [store, setStore] = useState(retrieveStoreFromStorage() || location?.state?.store || {});

  useEffect(() => {
    if (Object.keys(store).length) {
      sessionStorage.setItem('currentStoreDetail', JSON.stringify(store));
    } else {
      navigate('/');
    }
  }, [navigate, store]);

  const {
    codes,
    emulationMode: { read: emulationModeEnabled = false },
    isAdmin,
    userPrivileges,
  } = useContext(GlobalContext);
  const { t, i18n: { language: currentLocale = 'en-US' } } = useTranslation();
  const [editingShippingAddress, setEditingShippingAddress] = useState(false);
  const [achInfo, setAchInfo] = useState({});
  const achPopulated = !!Object.keys(achInfo).length;
  const [orderList, setOrderList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const retrievedOrders = useRef(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [isLoadingOrderDetails, setIsLoadingOrderDetails] = useState(false);
  const pagination = usePagination({ nodes: orderList }, {
    state: {
      page: 0,
      size: 15,
    },
  });

  const {
    state: {
      page: ordersPage = 0,
      size: ordersPageSize = 15,
      getTotalPages,
    } = {},
    fns: {
      onSetPage,
      onSetSize,
    } = {},
  } = pagination;

  const onPageChange = useCallback((newPage) => onSetPage(newPage), [onSetPage]);

  const onPageSizeChange = useCallback((newPageSize) => onSetSize(newPageSize), [onSetSize]);

  const {
    addresses: {
      store: {
        address1: storeAddr1,
        address2: storeAddr2,
        city: storeCity,
        stateProvince: storeStateProvince,
        postalCode: storePostalCode,
        countryCode: storeCountryCode,
      },
      shipping: {
        address1: shipAddr1,
        address2: shipAddr2,
        city: shipCity,
        stateProvince: shipStateProvince,
        postalCode: shipPostalCode,
        countryCode: shipCountryCode,
        contactPhone,
        contactName,
        storeName,
        storePrimaryEmail,
      },
    },
    storeInfo: {
      storeNumber,
      merchant,
      division,
      paSigned,
      hasAch,
      divStatus,
      hasOutstandingOrders,
      cimsStoreId,
    },
    users,
  } = store;

  const noActionsRequired = paSigned && hasAch;
  const ACTIVE_STORE = 'A';
  const statusClass = divStatus === ACTIVE_STORE ? 'active' : 'inactive';

  const actionItems = noActionsRequired ? null : (
    <section id='content-card-container' className='my-3'>
      {!paSigned && (
        <ContentCard
          header={t('storeDetails.actionItems.signParticipationAgreement.header')}
          message={t('storeDetails.actionItems.signParticipationAgreement.message')}
          type='error'
        />
      )}
      {!hasAch && (
        <ContentCard
          header={t('storeDetails.actionItems.setupAch.header')}
          message={t('storeDetails.actionItems.setupAch.message')}
          type='error'
        />
      )}
    </section>
  );

  const {
    readableCountry: storeReadableCountry,
    readableStateProvince: storeReadableStateProvince,
  } = getReadableCountryAndState(storeCountryCode, storeStateProvince, codes);

  const {
    readableCountry: shipReadableCountry,
    readableStateProvince: shipReadableStateProvince,
  } = getReadableCountryAndState(shipCountryCode, shipStateProvince, codes);

  const shippingAddressFormObject = useMemo(() => ({
    ...store.addresses.shipping,
  }), [store]);

  const methods = useForm({
    defaultValues: {
      ...store.addresses.shipping,
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  const outstandingOrderStatuses = useMemo(() => codes?.ORDER_STATUS?.filter(({ businessLogicType: blt }) => ['ORDERED', 'RELEASED', 'HOLD', 'ERROR'].includes(blt))?.map(({ value }) => value) || [], [codes]);

  const getOutstandingOrders = useCallback(async () => {
    try {
      const payload = {
        orderStatus: outstandingOrderStatuses,
        storeId: cimsStoreId,
      };
      const {
        data: {
          resultList = [],
          messageReturnCode,
        } = {},
        status,
        statusText,
      } = await callAPI('/api/cardOrdering/getReplenishmentOrderList', payload, 'post', true);
      if (status !== 200 || messageReturnCode !== '1000') {
        const errMsg = messageReturnCode || `${status} ${statusText}`;
        throw Error(errMsg);
      }
      setOrderList(resultList);
    } catch ({ message }) {
      displayErrorNotification(t('cardOrdering.errors.failedToRetrieveOrders', {
        message: message || t('site.unknownErrorOccurred'),
      }));
    }
  }, [callAPI, cimsStoreId, outstandingOrderStatuses, t]);

  useEffect(() => {
    if (cimsStoreId && hasOutstandingOrders && !retrievedOrders.current) {
      getOutstandingOrders();
      retrievedOrders.current = true;
    }
  }, [cimsStoreId, hasOutstandingOrders, getOutstandingOrders]);

  useEffect(() => reset(shippingAddressFormObject), [shippingAddressFormObject, reset]);

  const onEditClick = () => setEditingShippingAddress(true);

  const shipAddressOnDismiss = useCallback(() => {
    reset();
    setEditingShippingAddress(false);
  }, [reset]);

  const updateShippingAddress = useCallback(async (data) => {
    try {
      const {
        storeName: newStoreName,
        storePrimaryEmail: emailAddress,
        ...rest
      } = data;

      const payload = {
        ai: {
          ...rest,
        },
        division,
        emailAddress,
        merchant,
        storeName: newStoreName,
        storeNumber,
      };

      const { status, statusText } = await callAPI('/api/stores/saveShippingInfo', payload, 'post');
      if (status === 200) {
        setStore(prevStore => ({
          ...prevStore,
          addresses: {
            ...prevStore.addresses,
            shipping: {
              ...data,
            },
          },
        }));
        setEditingShippingAddress(false);
        displaySuccessNotification(t('storeDetails.shippingAddressUpdated'));
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      const errMsg = `${t('storeDetails.failedToUpdateShippingAddress')}: ${message}`;
      displayErrorNotification(errMsg);
    }
  }, [callAPI, division, merchant, storeNumber, t]);

  const shipAddressOnSubmit = useCallback(
    () => handleSubmit(updateShippingAddress)(),
    [handleSubmit, updateShippingAddress],
  );

  const EDIT_PRIVILEGE = 'STORE_MANAGEMENT_EDIT_STORES';
  const ACH_PRIVILEGE = 'ACH_VIEW_PAGE';
  const storePrivileges = userPrivileges?.find(({
    storeNumber: privStoreNumber,
    merchant: privMerch,
    division: privDivision,
  }) => privStoreNumber === storeNumber && privMerch === merchant && privDivision === division)?.privileges || [];

  const canViewAch = (isAdmin || storePrivileges.includes(ACH_PRIVILEGE)) && !emulationModeEnabled;
  const canEditShippingAddress = (isAdmin || storePrivileges.includes(EDIT_PRIVILEGE))
    && !!shipAddr1 && !emulationModeEnabled;

  const getAchInfo = useCallback(async () => {
    try {
      const payload = { division, merchant, storeNumber };
      const { data: currentAch, status, statusText } = await callAPI('/api/stores/getCurrentAch', payload, 'post');
      const achReturned = !!Object.keys(currentAch).length;
      const callSuccess = status === 200;
      if (callSuccess && achReturned) {
        setAchInfo(currentAch);
      } else if (callSuccess && !achReturned) {
        throw Error(t('storeDetails.noActiveAccount'));
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      const errMsg = `${t('storeDetails.failedToRetrieveAch')}: ${message}`;
      displayErrorNotification(errMsg);
    }
  }, [callAPI, division, merchant, storeNumber, t]);

  useEffect(() => {
    if (canViewAch && hasAch && !achPopulated && !callInitiated.current) {
      callInitiated.current = true;
      getAchInfo();
    }
  }, [canViewAch, hasAch, achPopulated, getAchInfo]);

  let routingNumber;
  let accountNumber;
  let accountType;
  if (achPopulated) {
    ({ bai: { routingNumber, accountNumber, accountType } } = achInfo);
  }

  const CARD_ORDER_COLUMNS = useMemo(() => [
    { label: t('storeDetails.cardOrders.orderNumber'), renderCell: ({ orderNumber }) => orderNumber || '--', sort: { sortKey: 'orderNum' } },
    { label: t('storeDetails.cardOrders.orderType'), renderCell: ({ orderType }) => translateFromCodes(codes, 'ORDER_TYPE', 'ORDER_TYPE', orderType) || '--', sort: { sortKey: 'orderType' } },
    { label: t('storeDetails.cardOrders.orderDate'), renderCell: ({ orderDate }) => (orderDate ? new Date(orderDate)?.toLocaleString(currentLocale) : '--'), sort: { sortKey: 'orderDate' } },
    { label: t('storeDetails.cardOrders.orderStatus'), renderCell: ({ status }) => translateFromCodes(codes, 'ORDER_STATUS', 'ORDER_STATUS', status) || '--', sort: { sortKey: 'status' } },
    { label: t('storeDetails.cardOrders.releaseDate'), renderCell: ({ releaseDate }) => (releaseDate ? new Date(releaseDate)?.toLocaleString(currentLocale) : '--'), sort: { sortKey: 'releaseDate' } },
    { label: t('storeDetails.cardOrders.requestorId'), renderCell: ({ requestorId }) => requestorId || '--', sort: { sortKey: 'requestorId' } },
    { label: t('cardOrdering.orderCheckout.comment'), renderCell: ({ customerPo }) => customerPo || '--', sort: { sortKey: 'customerPo' } },
  ], [t, codes, currentLocale]);

  const onRowClick = async ({ orderNumber }) => {
    try {
      const { orderNumber: detailsOrderNum } = orderDetails;
      setShowOrderDetails(true);
      if (detailsOrderNum !== orderNumber) {
        setIsLoadingOrderDetails(true);
        const {
          data: {
            resultList = [],
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/cardOrdering/getReplenishmentOrderDetail', { orderId: orderNumber }, 'post', true);
        if (status !== 200 || messageReturnCode !== '1000') {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }
        const [orderDetailResponse = {}] = resultList;
        setOrderDetails({
          ...orderDetailResponse,
          orderNumber,
        });
      }
    } catch ({ message }) {
      displayErrorNotification(t('cardOrdering.errors.failedToRetrieveOrderDetails', {
        message: message || t('site.unknownErrorOccurred'),
      }));
      setShowOrderDetails(false);
    } finally {
      setIsLoadingOrderDetails(false);
    }
  };

  const rowProps = {
    onClick: onRowClick,
  };

  const sort = useSort(
    orderList,
    {
      onChange: () => onSetPage(0),
    },
    {
      sortFns: {
        customerPo: (array) => [...array].sort(sortHelper('customerPo')),
        orderDate: (array) => [...array].sort(sortHelper('orderDate', { isNumeric: true })),
        orderNum: (array) => [...array].sort(sortHelper('orderNumber', { isNumeric: true })),
        orderType: (array) => [...array].sort(sortHelper('orderType', { codes, codesKey: 'ORDER_TYPE', translationParent: 'ORDER_TYPE' })),
        releaseDate: (array) => [...array].sort(sortHelper('releaseDate', { isNumeric: true })),
        requestorId: (array) => [...array].sort(sortHelper('requestorId')),
        status: (array) => [...array].sort(sortHelper('status', { codes, codesKey: 'ORDER_STATUS', translationParent: 'ORDER_STATUS' })),
      },
    },
  );

  const theme = useTheme([
    getTheme(),
    {
      Table: `
      --data-table-library_grid-template-columns:  repeat(6, minmax(0, 1fr)) auto;
      @media (max-width: 992px) {
          --data-table-library_grid-template-columns:  repeat(7, 1fr);
      `,
    },
  ]);

  const closeOrderDetails = useCallback(() => setShowOrderDetails(false), [setShowOrderDetails]);

  return (
    <div className='container' id='store-details'>
      {canEditShippingAddress && (
        <FormProvider {...methods}>
          <EditShippingAddress
            confirmAction={shipAddressOnSubmit}
            cancelAction={shipAddressOnDismiss}
            visible={editingShippingAddress}
            disabled={!isDirty || !!Object.keys(errors).length}
          />
        </FormProvider>
      )}
      <OrderDetails
        data={orderDetails}
        visible={showOrderDetails}
        cancelAction={closeOrderDetails}
        isLoading={isLoadingOrderDetails}
      />
      <header>
        <h1>
          {t('storeDetails.title.store', { storeNumber })}
        </h1>
        <div className={`status ${statusClass}`}>
          {t(`dashboard.${statusClass}Store`)}
        </div>
      </header>
      {actionItems}
      <div className='content-panels'>
        <div id='addresses'>
          <header>
            <h2>{t('storeDetails.addresses')}</h2>
          </header>
          <div className='info-section'>
            <div id='storeAddress'>
              <header className='mb-1'>
                <h4>{t('storeDetails.storeAddress')}</h4>
              </header>
              <address>
                <div>{storeAddr1}</div>
                {storeAddr2 && <div>{storeAddr2}</div>}
                <div>{`${storeCity}, ${storeReadableStateProvince} ${storePostalCode}`}</div>
                <div>{storeReadableCountry}</div>
              </address>
            </div>
            <div id='shippingAddress'>
              <header className='mb-1'>
                <h4>
                  <span>{t('storeDetails.shippingAddress')}</span>
                  {canEditShippingAddress && (
                    <i className='fa-regular fa-pen-to-square' id='shippingEditBtn' onClick={onEditClick} role='button' tabIndex={0} />
                  )}
                </h4>
              </header>
              <address>
                <div>{storeName || ''}</div>
                <div>{shipAddr1 || ''}</div>
                {shipAddr2 && <div>{shipAddr2}</div>}
                {shipCity && shipReadableStateProvince && shipPostalCode && (
                  <div>{`${shipCity}, ${shipReadableStateProvince} ${shipPostalCode}`}</div>
                )}
                <div>{shipReadableCountry || ''}</div>
              </address>
            </div>
            <div id='shippingContact'>
              <header className='mb-1'>
                <h4>{t('storeDetails.shippingContact')}</h4>
              </header>
              <address>
                <div>{contactName}</div>
                <div>{contactPhone}</div>
                <div>{storePrimaryEmail}</div>
              </address>
            </div>
          </div>
        </div>
        {canViewAch && hasAch && (
          <div id='store-ach'>
            <header>
              <h2>{t('site.sidebar.ach')}</h2>
            </header>
            <div className='info-section'>
              <div id='routingNumber'>
                <header className='mb-1'>
                  <h4>{t('ach.routingNumber')}</h4>
                </header>
                {routingNumber || ''}
              </div>
              <div id='accountNumber'>
                <header className='mb-1'>
                  <h4>{t('ach.accountNumber')}</h4>
                </header>
                {accountNumber || ''}
              </div>
              <div id='accountType'>
                <header className='mb-1'>
                  <h4>{t('ach.accountType')}</h4>
                </header>
                {getReadableAccountType(accountType, codes) || accountType}
              </div>
            </div>
          </div>
        )}
        {hasOutstandingOrders && (
          <div id='outstanding-orders'>
            <header>
              <h2>{t('storeDetails.cardOrders.title')}</h2>
            </header>
            <CompactTable
              columns={CARD_ORDER_COLUMNS}
              data={{ nodes: orderList }}
              layout={{ custom: true, horizontalScroll: true }}
              sort={sort}
              theme={theme}
              rowProps={rowProps}
              pagination={pagination}
            />
            <Paginator
              currentPage={ordersPage}
              totalPages={getTotalPages(orderList)}
              onPageChange={onPageChange}
              pageSize={ordersPageSize}
              onPageSizeChange={onPageSizeChange}
              startAtZero
            />
          </div>
        )}
        <div id='users'>
          <header>
            <h2>{t('storeDetails.users')}</h2>
          </header>
          <UserCollection users={users} />
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
