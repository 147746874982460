import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../hooks/useAuthentication';
import '../../styles/scss/Dashboard.scss';
import AdminDashboard from './AdminDashboard';
import StoreListing from './StoreListing';
import { displayErrorNotification } from '../../components/Notification/Notify';
import { GlobalContext } from '../../context/Global/GlobalContext';
import buildPayloadWithEmulation from '../../utils/emulate';

const {
  REACT_APP_ADMIN_GROUP,
  REACT_APP_USER_GROUP,
} = process.env;

const Dashboard = () => {
  const {
    emulationMode: { read: emulationModeEnabled = false },
    emulatedUser = null,
    sessionData: { groupId },
  } = useContext(GlobalContext);

  const { callAPI } = useAuthentication();
  const [stores, setStores] = useState([]);
  const { t } = useTranslation();
  const callInitiated = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const isStandardUser = [REACT_APP_ADMIN_GROUP, REACT_APP_USER_GROUP].includes(groupId);
  const isEmulating = emulationModeEnabled && emulatedUser;

  const emulationModeLanding = (
    <AdminDashboard />
  );

  useEffect(() => {
    const getStoreList = async () => {
      setIsLoading(true);
      try {
        const payload = buildPayloadWithEmulation({}, emulationModeEnabled, emulatedUser);
        const {
          data: {
            resultList = [],
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/stores/getStoreList', payload, 'post');

        if (status !== 200 || !['1000', '1001'].includes(messageReturnCode)) {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }
        setStores(resultList);
      } catch ({ message }) {
        displayErrorNotification(t('dashboard.failedToRetrieveStores', {
          message: message || t('site.unknownErrorOccurred'),
        }));
      }
      setIsLoading(false);
    };

    if (!callInitiated.current && (isStandardUser || isEmulating)) {
      getStoreList();
      callInitiated.current = true;
    }
  }, [callAPI, emulatedUser, emulationModeEnabled, isStandardUser, isEmulating, t]);

  return (
    <div className='container' id='dashboard'>
      { emulationModeEnabled && !emulatedUser
        ? emulationModeLanding
        : (
          <StoreListing
            data={stores}
            isLoading={isLoading}
          />
        )}
    </div>
  );
};

export default Dashboard;
