import React from 'react';
import { useTranslation } from 'react-i18next';

const LoginHeader = () => {
  const { t } = useTranslation();
  return (
    <header>
      <h1>
        <span>SVS</span>
        {t('site.title')}
      </h1>
    </header>
  );
};

export default LoginHeader;
