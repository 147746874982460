import React from 'react';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { CompactTable } from '@table-library/react-table-library/compact';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { downloadReport, makeCsvData } from '../../helpers/csvDownloader';

const DisplayData = ({
  tableData,
  sortFunctions,
  columnNames,
  columnWidths,
  exportableColumns,
  reportName,
  reportDate,
  storeList,
  csvSummary,
}) => {
  const { t } = useTranslation();
  // if you pass in a specific column width string, we use that instead of the default
  const numberOfColumns = columnNames.length;
  const gridTemplateString = columnWidths || `--data-table-library_grid-template-columns: repeat(${numberOfColumns}, 1fr)`;
  /**
   * Defines column sizes, css styles, and the number of columns
   */
  const theme = useTheme([
    getTheme(),
    {
      HeaderRow: `
        font-size: 12px;
        color: white;
        background-color: var(--muted-dark-text-color);
      `,
      Row: `
        font-size: 12px;
        color: white;
        background-color: var(--muted-dark-text-color);
      `,
      Table: `
        ${gridTemplateString};
      `,
    },
  ]);

  /**
    * Convert table data to CSV and call helper function to download file
    */
  const exportData = () => {
    const csvData = makeCsvData(exportableColumns, tableData.nodes);
    downloadReport(csvData, reportName, reportDate, storeList, csvSummary);
  };

  return (
    tableData.nodes.length > 0 ? (
      <>
        <CompactTable
          columns={columnNames}
          data={tableData}
          theme={theme}
          layout={{ custom: true, horizontalScroll: true }}
          sort={sortFunctions}
        />
        <div className='action-buttons'>
          <button type='button' className='btn generate btn-primary' onClick={exportData}>{t('reporting.uiElements.exportData')}</button>
        </div>
      </>
    ) : (
      <div className='no-data'>
        <i className='fa-solid fa-circle-exclamation' />
        <span>{t('reporting.uiElements.noDataFound')}</span>
      </div>
    )
  );
};

DisplayData.propTypes = {
  columnNames: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      renderCell: PropTypes.func,
    }),
  ).isRequired,
  columnWidths: PropTypes.string,
  csvSummary: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  exportableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      accessor: PropTypes.func,
      name: PropTypes.string,
    }),
  ).isRequired,
  reportDate: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  sortFunctions: PropTypes.func.isRequired,
  storeList: PropTypes.string.isRequired,
  tableData: PropTypes.objectOf(
    PropTypes.string,
  ).isRequired,
};

DisplayData.defaultProps = {
  columnWidths: '',
};

export default DisplayData;
