import {
  React, useCallback, useContext, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@table-library/react-table-library/sort';
import { useTranslation } from 'react-i18next';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import moment from 'moment';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const Commissions = ({
  smgr310Data, date,
}) => {
  const { t, i18n } = useTranslation();
  const storeList = storeListBuilder(smgr310Data, 'STORE');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);

  const [totalFtcCount, setTotalFtcCount] = useState(0);
  const [totalFtcAmount, setTotalFtcAmount] = useState(0);
  const [totalCommissionAmount, setTotalCommissionAmt] = useState(0);

  const reportData = smgr310Data;
  const data = useMemo(() => ({
    nodes: selectedStores?.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.STORE))
      : reportData,
  }), [reportData, selectedStores]);

  // on data change, compute the totals
  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const computeTotalFtcCount = nodes
        .map(item => parseInt(item.FTC_COUNT, 10))
        .reduce((accumulator, current) => accumulator + current);
      setTotalFtcCount(computeTotalFtcCount);
      const computeTotalFtcAmount = nodes
        .map(item => parseFloat(item.FTC_AMOUNT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalFtcAmount(computeTotalFtcAmount);
      const computeTotalCommissionAmount = nodes
        .map(item => parseFloat(item.COMMISSION_AMOUNT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalCommissionAmt(computeTotalCommissionAmount);
    } else {
      setTotalFtcCount(0);
      setTotalFtcAmount(0);
      setTotalCommissionAmt(0);
    }
  }, [data]);

  /**
  * defines two decimal places for parseFloat functions
  */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;

  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        ACTIVITY_DATE: (array) => [...array].sort((a, b) => dateCompare(a.ACTIVITY_DATE, b.ACTIVITY_DATE)),
        COMMISSION_AMOUNT: (array) => [...array].sort(
          (a, b) => floatCompare(a.COMMISSION_AMOUNT, b.COMMISSION_AMOUNT),
        ),
        COMMISSION_RATE: (array) => [...array].sort(
          (a, b) => floatCompare(a.COMMISSION_RATE, b.COMMISSION_RATE),
        ),
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        FTC_AMOUNT: (array) => [...array].sort((a, b) => floatCompare(a.FTC_AMOUNT, b.FTC_AMOUNT)),
        FTC_COUNT: (array) => [...array].sort((a, b) => intCompare(a.FTC_COUNT, b.FTC_COUNT)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        STORE: (array) => [...array].sort((a, b) => intCompare(a.STORE, b.STORE)),
      },
    },
  );

  /**
   * The columns and their corresponding data that will render in the table.
   * Commission Rate is multiplied by 100 to convert to percentage
   */
  const COLUMNS = [
    { label: t('reporting.uiElements.date'), renderCell: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'ACTIVITY_DATE' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.store'), renderCell: (item) => item.STORE.padStart(10, '0'), sort: { sortKey: 'STORE' } },
    { label: t('reporting.commissions.ftcCount'), renderCell: (item) => item.FTC_COUNT, sort: { sortKey: 'FTC_COUNT' } },
    { label: t('reporting.commissions.ftcAmount'), renderCell: (item) => parseFloat(item.FTC_AMOUNT).toLocaleString(convertedLocale, options), sort: { sortKey: 'FTC_AMOUNT' } },
    { label: t('reporting.commissions.commissionRate'), renderCell: (item) => parseFloat(item.COMMISSION_RATE * 100).toLocaleString(convertedLocale, { maximumFractionDigits: 3, minimumFractionDigits: 3 }), sort: { sortKey: 'COMMISSION_RATE' } },
    { label: t('reporting.commissions.commissionAmount'), renderCell: (item) => parseFloat(item.COMMISSION_AMOUNT).toLocaleString(convertedLocale, options), sort: { sortKey: 'COMMISSION_AMOUNT' } },
  ];
  const exportableColumns = [
    { accessor: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), name: t('reporting.uiElements.date') },
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.STORE.padStart(10, '0')}\t`, name: t('reporting.uiElements.store') },
    { accessor: (item) => item.FTC_COUNT, name: t('reporting.commissions.ftcCount') },
    { accessor: (item) => parseFloat(item.FTC_AMOUNT).toLocaleString(convertedLocale, options), name: t('reporting.commissions.ftcAmount') },
    { accessor: (item) => parseFloat(item.COMMISSION_RATE * 100).toLocaleString(convertedLocale, { maximumFractionDigits: 3, minimumFractionDigits: 3 }), name: t('reporting.commissions.commissionRate') },
    { accessor: (item) => parseFloat(item.COMMISSION_AMOUNT).toLocaleString(convertedLocale, options), name: t('reporting.commissions.commissionAmount') },
  ];

  const csvSummary = [
    `"${t('reporting.commissions.ftcCountTotal')}: ${parseInt(totalFtcCount, 10)}"`,
    `\n"${t('reporting.commissions.ftcAmountTotal')}: ${parseFloat(totalFtcAmount).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.commissions.commissionTotal')}: ${parseFloat(totalCommissionAmount).toLocaleString(convertedLocale, options)}"\n`,
  ];

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.commissions.ftcCountTotal')}: `}
              </span>
              <span>
                {parseInt(totalFtcCount, 10)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.commissions.ftcAmountTotal')}: `}
              </span>
              <span>
                {parseFloat(totalFtcAmount).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.commissions.commissionTotal')}: `}
              </span>
              <span>
                {parseFloat(totalCommissionAmount).toLocaleString(convertedLocale, options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        reportName='Commissions'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />

    </>
  );
};

Commissions.propTypes = {
  date: PropTypes.string.isRequired,
  smgr310Data: PropTypes.arrayOf(
    PropTypes.shape({
      ACTIVITY_DATE: PropTypes.string,
      COMMISSION_AMOUNT: PropTypes.string,
      COMMISSION_RATE: PropTypes.string,
      DIVISION: PropTypes.string,
      ENTITY_NAME: PropTypes.string,
      FTC_AMOUNT: PropTypes.string,
      FTC_COUNT: PropTypes.string,
      MERCHANT: PropTypes.string,
      STORE: PropTypes.string,
    }),
  ).isRequired,
};

export default Commissions;
