import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const CharacterCounter = ({
  inputValue, maxLength, validationRules,
}) => {
  const { t } = useTranslation();
  const maxCharacters = maxLength || validationRules?.maxLength?.value || 0;
  const characterCount = inputValue?.length || 0;

  return maxCharacters ? (
    <small className={`form-text form-message character-count${characterCount > maxCharacters ? ' exceeds-length' : ''}`}>
      {`${characterCount}/${maxCharacters} ${t('form.characters')}`}
    </small>
  ) : null;
};

CharacterCounter.propTypes = {
  inputValue: PropTypes.string,
  maxLength: PropTypes.number,
  validationRules: PropTypes.shape({
    maxLength: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        message: PropTypes.string,
        value: PropTypes.number,
      }),
    ]),
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    setValueAs: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
};

CharacterCounter.defaultProps = {
  inputValue: undefined,
  maxLength: undefined,
  validationRules: {},
};

export default CharacterCounter;
