const createContainer = (containerID) => {
  let element = document.querySelector(`#${containerID}`);
  if (element) return element;
  element = document.createElement('div');
  element.setAttribute('id', containerID);
  document.body.appendChild(element);
  return element;
};

export default createContainer;
