import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/scss/MenuDropdown.scss';

const MenuDropdown = ({ icon, items }) => {
  const [expanded, setExpanded] = useState(false);
  const arrowRef = useRef();
  const ref = useRef();
  const expandArrowClasses = () => `fa-solid fa-caret-right${expanded ? ' expanded' : ''}`;

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      const clickedInsideLocale = (ref?.current?.contains(target) || arrowRef?.current?.contains(target));
      if (!clickedInsideLocale) setExpanded(false);
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, expanded, arrowRef]);

  return (
    <div className='menu-dropdown'>
      <i className={`fa-solid ${icon}`} />
      <i
        className={expandArrowClasses()}
        onClick={() => setExpanded(!expanded)}
        role='button'
        tabIndex='0'
        ref={arrowRef}
      />
      {expanded
      && (
        <ul className='dropdown-content' ref={ref}>
          {items.map(item => <li key={item.key} onClick={(() => setExpanded(!expanded))}>{item}</li>)}
        </ul>
      )}
    </div>
  );
};

MenuDropdown.propTypes = {
  icon: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default MenuDropdown;
