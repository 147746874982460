import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Notification from './Notification';

const NotificationManager = ({ setNotify }) => {
  const [notifications, setNotifications] = useState([]);

  const createNotification = ({ autoClose, children, type }) => {
    setNotifications(previousNotifications => [
      ...previousNotifications,
      {
        autoClose,
        children,
        id: previousNotifications.length,
        type,
      },
    ]);
  };

  const deleteNotification = useCallback(id => {
    setNotifications(previousNotifications => previousNotifications.filter((_, index) => id !== index));
  }, []);

  useEffect(() => {
    setNotify(({ autoClose, children, type }) => {
      createNotification({ autoClose, children, type });
    });
  }, [setNotify]);

  const handleDeleteNotification = useCallback(
    id => () => {
      deleteNotification(id);
    },
    [deleteNotification],
  );

  return (
    <>
      {notifications.map(({ id, ...props }, index) => (
        <Notification
          key={id}
          onDelete={handleDeleteNotification(index)}
          {...props}
        />
      ))}
    </>
  );
};

NotificationManager.propTypes = {
  setNotify: PropTypes.func.isRequired,
};

export default NotificationManager;
