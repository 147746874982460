import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context/Global/GlobalContext';

const SidebarItem = ({
  children, icon, path, title,
}) => {
  const {
    setSidebarToggled,
  } = useContext(GlobalContext);

  const handleClick = useCallback(() => setSidebarToggled(false), [setSidebarToggled]);

  const [showFloatingMenu, setShowFloatingMenu] = useState(false);
  const { pathname } = useLocation();
  const currentPath = pathname === path;
  return (
    <>
      <li
        className={`sidebar-item${showFloatingMenu ? ' expanded' : ''}`}
        onFocus={() => setShowFloatingMenu(!showFloatingMenu)}
        onMouseEnter={() => setShowFloatingMenu(true)}
        onMouseLeave={() => setShowFloatingMenu(false)}
      >
        <Link aria-selected={currentPath} onClick={handleClick} to={path}>
          <span>{icon && <i className={icon} />}</span>
          <span>{title}</span>
        </Link>
      </li>
      {children
        && (
          <ul className='sub-items'>
            {children}
          </ul>
        ) }
    </>
  );
};

SidebarItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

SidebarItem.defaultProps = {
  children: undefined,
  icon: '',
};

export default SidebarItem;
