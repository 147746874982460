const emailAddressPattern = /^[\w!#$%&'*+\-/=?^`{|}~.]+@[\w.-]+\.[a-z]{2,4}$/i;

const usernamePattern = /^[\w.-]+$/;

const validateEmailAddress = (email) => emailAddressPattern.test(email);

const specialCharactersPattern = (specialCharacters) => {
  const specialCharactersRegex = specialCharacters.split('').map((char) => `\\${char}`).join('|');
  return new RegExp(`^(?=.*[A-Z])(?=.*\\d)(?=.*[${specialCharactersRegex}]).*$`);
};

// since this gets appended to signons in SECUSER, have to keep this in mind when handling max chars
const SIGNON_APPEND = '@1010.ALL';

const maxLengths = {
  accessManagement: {
    EMAIL_MAX_LENGTH: 233, // SECUSER.EMAIL_ADDRESS
    NAME_MAX_LENGTH: 30, // SECUSER.USER_NAME
    PHONE_MAX_LENGTH: 25, // SECUSER.PHONE_NUMBER
    SIGNON_MAX_LENGTH: 233 - SIGNON_APPEND.length, // 224, SECUSER.SIGNON_ID
  },
  cardOrdering: {
    CUSTOMER_PO_MAX_LENGTH: 25, // CIMS.REP_ORDER.CUSTOMER_PO
  },
  editShippingAddress: {
    ADDRESS_MAX_LENGTH: 50, // CIMS.ADDR_INFO.ADDRESS1, CIMS.ADDR_INFO.ADDRESS2
    CITY_MAX_LENGTH: 35, // CIMS.ADDR_INFO.CITY
    CONTACT_NAME_MAX_LENGTH: 50, // CIMS.ADDR_INFO.CONTACT_NAME
    CONTACT_PHONE_MAX_LENGTH: 25, // CIMS.ADDR_INFO.CONTACT_PHONE
    EMAIL_MAX_LENGTH: 320, // CIMS.EMAIL_LIST.EMAIL_ADDR
    POSTAL_CODE_MAX_LENGTH: 12, // CIMS.ADDR_INFO.POSTAL_CODE
    STORE_NAME_MAX_LENGTH: 255, // CIMS.STORE_ATTR.STORE_NAME
  },
  login: {
    PASSWORD_MAX_LENGTH: 255, // arbitrary - SECUSER.USER_PASSWORD is stored as encrypted string with 10-char max
  },
  mfa: {
    TOKEN_MAX_LENGTH: 6, // MFA token is six-digit number
  },
  securityQuestions: {
    ANSWER_MAX_LENGTH: 255, // arbitrary - SECURITY_QUESTION_ANSWER.ANSWER_VALUE stores up to 500 characters, but as an encrypted string
    QUESTION_MAX_LENGTH: 215, // SECURITY_QUESTION_ANSWER.QUESTION_VALUE
  },
};

const mfaTokenPattern = new RegExp(`[0-9]{${maxLengths.mfa.TOKEN_MAX_LENGTH}}`);

module.exports = {
  emailAddressPattern,
  maxLengths,
  mfaTokenPattern,
  specialCharactersPattern,
  usernamePattern,
  validateEmailAddress,
};
