import React, {
  createContext, useReducer, useMemo, useEffect, useContext, useRef, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CardOrderingReducer from './CardOrderingReducer';
import { GlobalContext } from '../Global/GlobalContext';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification } from '../../components/Notification/Notify';

const {
  REACT_APP_ADMIN_GROUP,
  REACT_APP_USER_GROUP,
} = process.env;

const STORAGE_KEY = 'carts';

const cartStorage = localStorage.getItem(STORAGE_KEY);
if (!cartStorage) {
  localStorage.setItem(STORAGE_KEY, JSON.stringify([]));
}

const initialState = {
  cart: [],
  groupId: null,
  selectedStore: null,
  userId: null,
};

/**
 * the cart is designed to persist across sessions for FMADMIN / FMUSER, but not for emulated users
 * this function is called on the Login screen and removes any carts belonging to a non-FMADMIN / FMUSER from local storage
 * emulation carts do persist across refreshes, however
 */
export const clearNonStandardUserCarts = () => {
  const storedCarts = localStorage.getItem(STORAGE_KEY);
  if (storedCarts) {
    const parsedCarts = JSON.parse(storedCarts)?.filter(({
      groupId,
    }) => [REACT_APP_ADMIN_GROUP, REACT_APP_USER_GROUP].includes(groupId)) || [];

    localStorage.setItem(STORAGE_KEY, JSON.stringify(parsedCarts));
  }
};

export const CardOrderingContext = createContext(initialState);

export const CardOrderingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CardOrderingReducer, initialState);
  const [currencyDetails, setCurrencyDetails] = useState({});
  const [invalidProductsInCart, setInvalidProductsInCart] = useState([]);
  const { callAPI } = useAuthentication();
  const { t } = useTranslation();
  const currencyDetailsRetrieved = useRef(false);
  const initializedCart = useRef(false);

  const {
    sessionData: { userId, groupId } = {},
    emulationMode: {
      read: emulationModeEnabled = false,
    },
    emulatedUser = null,
  } = useContext(GlobalContext);

  const keyUserId = emulatedUser?.userId || userId;
  const keyGroupId = emulatedUser?.groupId || groupId;

  const removeOtherCartsOnEmulationEnd = useCallback(() => {
    const allCarts = localStorage?.getItem(STORAGE_KEY);
    if (allCarts) {
      const parsedAllCarts = JSON.parse(allCarts);
      const clearedStorage = parsedAllCarts.filter(({
        userId: storedUserId,
        groupId: storedGroupId,
      }) => storedUserId === userId && storedGroupId === groupId);
      localStorage.setItem(STORAGE_KEY, JSON.stringify(clearedStorage));
    }
  }, [userId, groupId]);

  useEffect(() => {
    if (!emulatedUser && emulationModeEnabled && userId && groupId) {
      removeOtherCartsOnEmulationEnd();
    }
  }, [emulatedUser, userId, groupId, emulationModeEnabled, removeOtherCartsOnEmulationEnd]);

  const updateUserCartInStorage = useCallback(() => {
    const allCarts = localStorage?.getItem(STORAGE_KEY);
    let parsedAllCarts = [];
    if (allCarts) {
      parsedAllCarts = JSON.parse(allCarts);
      const userCartIndex = parsedAllCarts?.findIndex(({
        userId: storedUserId,
        groupId: storedGroupId,
      }) => storedUserId === keyUserId && storedGroupId === keyGroupId);
      if (userCartIndex > -1) {
        parsedAllCarts[userCartIndex] = state;
      } else {
        parsedAllCarts = parsedAllCarts.concat(state);
      }
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(parsedAllCarts));
  }, [keyUserId, keyGroupId, state]);

  const initializeCart = useCallback(() => {
    const allCarts = localStorage?.getItem(STORAGE_KEY);
    let userCart;
    if (allCarts) {
      const parsedAllCarts = JSON.parse(allCarts);
      userCart = parsedAllCarts?.find(({
        userId: storedUserId,
        groupId: storedGroupId,
      }) => keyUserId === storedUserId && keyGroupId === storedGroupId);
    }

    dispatch({
      payload: userCart || {
        ...initialState,
        groupId: keyGroupId,
        userId: keyUserId,
      },
      type: 'SET_STATE',
    });
  }, [keyUserId, keyGroupId]);

  useEffect(() => {
    initializedCart.current = false;
  }, [emulatedUser]);

  useEffect(() => {
    if (!initializedCart.current && keyUserId && keyGroupId) {
      initializeCart();
      initializedCart.current = true;
    }
  }, [initializeCart, keyUserId, keyGroupId]);

  useEffect(() => {
    const {
      userId: stateUserId,
      groupId: stateGroupId,
    } = state;

    const userPopulatedInState = stateUserId && stateGroupId;
    if (initializedCart.current && userPopulatedInState && stateUserId === keyUserId && keyGroupId === stateGroupId) {
      updateUserCartInStorage();
    }
  }, [state, keyUserId, keyGroupId, updateUserCartInStorage]);

  const { selectedStore, cart } = state;
  const currencyCode = useMemo(() => selectedStore?.merchCurrencyCode || null, [selectedStore]);

  useEffect(() => {
    if (!currencyCode) {
      setCurrencyDetails({});
    }
    currencyDetailsRetrieved.current = false;
  }, [currencyCode]);

  useEffect(() => {
    const getCurrencyDetails = async () => {
      try {
        const {
          data: {
            resultList = [],
            messageReturnCode,
          },
          status,
          statusText,
        } = await callAPI('/api/cardOrdering/getCurrencyDetails', {
          currencyCodeAlpha: null,
          currencyCodeNumeric: currencyCode,
        }, 'post', true);
        if (status !== 200 || messageReturnCode !== '1000') {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }
        const [details] = resultList;
        setCurrencyDetails(details);
      } catch ({ message }) {
        const errMsg = t('cardOrdering.errors.failedToRetrieveCurrencyDetails', {
          message: message || t('site.unknownErrorOccurred'),
        });
        displayErrorNotification(errMsg);
      }
    };

    if (currencyCode && !currencyDetailsRetrieved.current) {
      getCurrencyDetails();
      currencyDetailsRetrieved.current = true;
    }
  }, [currencyCode, callAPI, t]);

  const setSelectedStore = (newSelectedStore) => {
    dispatch({
      payload: { selectedStore: newSelectedStore },
      type: 'SET_SELECTED_STORE',
    });
  };

  const setCart = (newCart) => {
    dispatch({
      payload: { cart: newCart },
      type: 'SET_CART',
    });
  };

  const refreshCart = useCallback((productList) => {
    const productsToRemove = [];

    if (!cart?.length || !productList) {
      return;
    }

    cart.forEach(({
      productId, label, quantityInCart,
    }) => {
      const productDelta = {
        causes: [],
        label,
        productId,
        quantityInCart,
      };
      const latestProduct = productList?.find(({ productId: productListId }) => productId === productListId);
      if (!latestProduct) {
        productDelta.causes.push('PRODUCT_UNAVAILABLE');
      } else {
        const {
          whsAvailQty,
          inventoryActive,
          sku,
          skuDescription,
          unitSize,
        } = latestProduct;

        productDelta.label = `${sku} - ${skuDescription}`;

        if (!whsAvailQty || !unitSize || whsAvailQty < unitSize) {
          productDelta.causes.push('PRODUCT_UNAVAILABLE');
        } else if (!inventoryActive) {
          productDelta.causes.push('INACTIVE_PRODUCT');
        }
      }

      if (productDelta.causes.length) {
        productsToRemove.push(productDelta);
      }
    });

    if (productsToRemove.length) {
      const refreshedCart = [...cart].filter(({ productId }) => !productsToRemove.find(({
        productId: productToRemove,
      }) => productId === productToRemove));

      setCart(refreshedCart);

      productsToRemove.forEach(({
        label, causes, productId, quantityInCart,
      }) => {
        const errBody = (
          <div className='deltaErrors'>
            <p>{t('cardOrdering.productCard.notifications.removedFromCart', { label, quantity: quantityInCart })}</p>
            {causes?.map(key => (
              <p key={`${productId}-${key}`}>
                {t(`cardOrdering.deltaErrors.${key}`)}
              </p>
            ))}
          </div>
        );
        displayErrorNotification(errBody, false);
      });
    }
  }, [cart, t]);

  const resetOrderContext = useCallback((newSelectedStore) => {
    setSelectedStore(newSelectedStore);
    setCart([]);
    setInvalidProductsInCart([]);
  }, []);

  const refreshStore = useCallback((storeList) => {
    if (selectedStore) {
      const { storeId, label } = selectedStore;
      const storeDelta = {
        causes: [],
        description: label,
        storeId,
      };
      const latestStore = storeList.find(({
        storeId: refreshStoreId,
      }) => refreshStoreId === storeId);

      if (!latestStore) {
        storeDelta.causes.push('STORE_UNAVAILABLE');
      }

      const shouldResetOrder = storeDelta.causes.includes('STORE_UNAVAILABLE');

      if (shouldResetOrder) {
        displayErrorNotification(t('cardOrdering.productCard.notifications.cartHasBeenReset', { label }), false);
        resetOrderContext(null);
      } else {
        setSelectedStore(latestStore);
      }
    }
  }, [selectedStore, resetOrderContext, t]);

  useEffect(() => {
    const productsToRemove = [];

    invalidProductsInCart.forEach(errProductId => {
      if (!cart.find(({ productId }) => productId === errProductId)) {
        productsToRemove.push(errProductId);
      }
    });

    if (productsToRemove.length) {
      const updatedErrorList = invalidProductsInCart.filter((productId) => !productsToRemove.includes(productId));
      setInvalidProductsInCart(updatedErrorList);
    }
  }, [cart, invalidProductsInCart]);

  const providerValue = useMemo(() => ({
    cart,
    currencyDetails,
    invalidProductsInCart,
    refreshCart,
    refreshStore,
    removeOtherCartsOnEmulationEnd,
    resetOrderContext,
    selectedStore,
    setCart,
    setInvalidProductsInCart,
    setSelectedStore,
  }), [cart, selectedStore, refreshCart, refreshStore,
    currencyDetails, invalidProductsInCart, resetOrderContext,
    removeOtherCartsOnEmulationEnd]);

  return (
    <CardOrderingContext.Provider value={providerValue}>
      {children}
    </CardOrderingContext.Provider>
  );
};

CardOrderingProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
