import React, {
  useState, useCallback, useEffect, useContext,
  useRef,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UserFields from '../AccessManagement/UserFields';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import useAuthentication from '../../hooks/useAuthentication';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import { GlobalContext } from '../../context/Global/GlobalContext';
import '../../styles/scss/AccessManagement.scss';

const UserProfile = () => {
  const {
    sessionData,
    setSession,
  } = useContext(GlobalContext);

  const { userId } = sessionData;
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const [user, setUser] = useState({});
  const { details: { signonId = '' } = {} } = user;
  const userPopulated = !!Object.keys(user).length;
  const [isLoading, setIsLoading] = useState(true);
  const methods = useForm({ defaultValues: user });
  const callInitiated = useRef(false);
  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields },
  } = methods;

  const getUserDetails = useCallback(async () => {
    try {
      if (!userPopulated && userId && !callInitiated.current) {
        callInitiated.current = true;
        const { data, status, statusText } = await callAPI('/api/accessManagement/getUserProfile', {}, 'get');
        if (status === 200) {
          const { profileDetails } = data;
          setUser(profileDetails);
        } else {
          throw Error(`${status} ${statusText}`);
        }
      }
    } catch ({ message }) {
      const errMsg = `${t('users.couldNotRetrieveUserDetails')}: ${message}`;
      displayErrorNotification(errMsg);
    } finally {
      setIsLoading(false);
    }
  }, [callAPI, userPopulated, userId, t]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => reset(user), [user, reset]);

  const updateUser = async (payload) => {
    const response = await callAPI('/api/accessManagement/editUserDetail', payload, 'post');
    const { status, statusText } = response;
    if (status !== 200) {
      throw Error(`${status} ${statusText}`);
    }
    return response;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const { details } = data;
      await updateUser(details);
      setUser(data);
      let changedFields;
      ({ details: changedFields = {} } = dirtyFields);
      changedFields = Object.keys(changedFields);
      const userInfoInContext = ['userName', 'locale'];
      const updateContext = changedFields.some(field => userInfoInContext.includes(field));
      if (updateContext) {
        const updatedUserInfo = {};
        changedFields.forEach(key => {
          updatedUserInfo[key] = details[key];
        });
        setSession({
          ...sessionData,
          ...updatedUserInfo,
        });
      }
      displaySuccessNotification(t('users.userUpdated'));
    } catch ({ message }) {
      const errMsg = `${t('users.failedToUpdateUserDetails')}: ${message}`;
      displayErrorNotification(errMsg);
    }
    setIsLoading(false);
  };

  const clickReset = () => reset(user);

  return (
    <div className='access-management container' id='user-profile'>
      <div className='row g-2 mb-2'>
        <div className='col-12'>
          <h1>{t('header.userProfile')}</h1>
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? elastic : userPopulated && (
            <div className='row g-2'>
              <div className='col-12 form-section'>
                <div className='row'>
                  <UserFields signonId={signonId} />
                </div>
              </div>
              <div className='action-buttons'>
                <button className='btn' type='submit' disabled={!isDirty}>{t('buttons.update')}</button>
                <button className='btn button-link' type='button' disabled={!isDirty} onClick={clickReset}>{t('buttons.reset')}</button>
              </div>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default UserProfile;
