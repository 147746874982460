import '../../styles/scss/Filter.scss';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FilterSelect from './FilterSelect';
import ActiveFilter from './ActiveFilter';
import { filterTypesPropType } from './FilterPropTypes';

const Filter = ({ filterTypes, onSubmit, ...selectProps }) => {
  const [activeFilters, setActiveFilters] = useState([]);

  const clearFilters = () => setActiveFilters([]);

  const applyFilters = () => {
    if (onSubmit) {
      onSubmit(activeFilters);
    }
  };

  return (
    <div className='filter'>
      <div id='filter-select'>
        <FilterSelect
          {...selectProps}
          filterTypes={filterTypes}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
        />
        {!!activeFilters.length
        && (
          <button
            className='btn btn-primary clear-filters'
            type='submit'
            onClick={clearFilters}
            disabled={!activeFilters.length}
          >
            <i className='fa-solid fa-close' />
          </button>
        )}
        <button
          className='btn btn-primary search-button'
          type='submit'
          onClick={applyFilters}
          disabled={!activeFilters.length}
        >
          <i className='fa-solid fa-magnifying-glass' />
        </button>
      </div>
      <div className='active-filter-list'>
        {activeFilters.map(filter => {
          const { value } = filter;
          return (
            <ActiveFilter
              key={value}
              filterData={filter}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
            />
          );
        })}
      </div>
    </div>
  );
};

Filter.propTypes = {
  filterTypes: filterTypesPropType,
  onSubmit: PropTypes.func.isRequired,
};
Filter.defaultProps = {
  filterTypes: [],
};

export default Filter;
