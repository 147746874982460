import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/scss/Notification.scss';

const Notification = ({
  autoClose,
  children,
  id,
  onDelete,
  type,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const { REACT_APP_ERROR_AUTOCLOSE_TIME_MS: autoCloseTime = '2500' } = process.env;
  let classList;
  let icon;

  switch (type) {
  case 'error':
    classList = 'notification-error';
    icon = <i className='fas fa-times' />;
    break;
  case 'info':
    classList = 'notification-info';
    icon = <i className='fas fa-info' />;
    break;
  case 'success':
    classList = 'notification-success';
    icon = <i className='fas fa-check' />;
    break;
  case 'warning-1':
    classList = 'notification-warning warning-1';
    icon = <i className='fas fa-exclamation' />;
    break;
  case 'warning-2':
    classList = 'notification-warning warning-2';
    icon = <span className='fas fa-exclamation' />;
    break;
  case 'warning-3':
    classList = 'notification-warning warning-3';
    icon = <i className='fas fa-exclamation' />;
    break;
  case 'warning-4':
    classList = 'notification-warning warning-4';
    icon = <i className='fas fa-exclamation' />;
    break;
  default:
    break;
  }

  const timeToDelete = 500;
  const timeToOpen = 0;
  const timeToClose = parseInt(autoCloseTime, 10);

  React.useEffect(() => {
    if (isClosing) {
      const timeoutId = setTimeout(onDelete, timeToDelete);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return undefined;
  }, [isClosing, onDelete]);

  React.useEffect(() => {
    if (!isOpened) {
      const timeoutId = setTimeout(() => setIsOpened(true));

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return undefined;
  }, [isOpened, timeToOpen]);

  React.useEffect(() => {
    if (autoClose) {
      const timeoutId = setTimeout(() => setIsClosing(true), timeToClose);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return undefined;
  }, [autoClose, timeToClose]);

  return (
    <div className={`notification ${classList}${isClosing ? ' closing' : ''}${isOpened ? ' opened' : ''}`} id={id}>
      <div className='notification-icon'>
        {icon}
      </div>
      <div className='notification-content'>
        <span>{children}</span>
      </div>
      <div className='notification-control' onClick={() => setIsClosing(true)} role='button' tabIndex={0}>
        <span className='fa-solid fa-xmark' aria-hidden='true' />
      </div>
    </div>
  );
};

Notification.propTypes = {
  autoClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  onDelete: PropTypes.func.isRequired,
  type: PropTypes.oneOf(Object.keys({
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning',
  })).isRequired,
};

Notification.defaultProps = {
  autoClose: false,
};

export default Notification;
