import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useSort } from '@table-library/react-table-library/sort';
import PropTypes from 'prop-types';

const CollapsibleSection = ({
  store, data, theme, columns, sortFunctions,
}) => {
  const { t } = useTranslation();
  const nodes = data.filter(d => d.DIV_STORE_NUM.padStart(10, '0') === store.padStart(10, '0'));
  const tableData = { nodes };
  const dataTarget = `#store${store}`;
  const generatedId = `store${store}`;
  const sort = useSort(
    tableData,
    {
      onChange: null,
    },
    {
      sortFns: sortFunctions,
    },
  );
  return (nodes.length > 0 ? (
    <>
      <button className='collapsibleSection' data-bs-toggle='collapse' data-bs-target={dataTarget} aria-expanded='false' aria-controls='collapse' type='button'>
        <div className='collapseButton'>
          {t('reporting.uiElements.store')}
          {' '}
          {store.padStart(10, '0')}
        </div>
        <i className='fa-solid fa-chevron-down' />
      </button>
      <div className='collapse' id={generatedId}>
        <CompactTable
          columns={columns}
          data={tableData}
          layout={{ custom: true, horizontalScroll: true }}
          theme={theme}
          sort={sort}
        />
      </div>
    </>
  ) : null
  );
};

CollapsibleSection.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.func,
      renderCell: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ACTIVITY_DATE: PropTypes.string,
      BILLING_AMT: PropTypes.string,
      CIMS_AMT: PropTypes.string,
      DISCOUNT_STLMNT: PropTypes.string,
      DIVISION: PropTypes.string,
      MERCHANT: PropTypes.string,
      NET_AMT: PropTypes.string,
      OTHER_CHARGES: PropTypes.string,
      SETTLEMENT_AMT: PropTypes.string,
      SHIPPING_AMT: PropTypes.string,
    }),
  ).isRequired,
  sortFunctions: PropTypes.objectOf(PropTypes.func).isRequired,
  store:
    PropTypes.string.isRequired,
  theme: PropTypes.shape({
    Table: PropTypes.shape({
      BaseCell: PropTypes.string,
      BaseRow: PropTypes.string,
      Body: PropTypes.string,
      Cell: PropTypes.string,
      Header: PropTypes.string,
      HeaderCell: PropTypes.string,
      HeaderRow: PropTypes.string,
      Row: PropTypes.string,
      Table: PropTypes.string,
    }),
  }).isRequired,
};

export default CollapsibleSection;
