import {
  createContext, useCallback, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification, displayInfoNotification, displaySuccessNotification } from '../../components/Notification/Notify';

const LoginContext = createContext({});

export const LoginProvider = ({ children }) => {
  const { callAPI, setAuthenticated } = useAuthentication();
  const [awaitingSSO, setAwaitingSSO] = useState(false);
  const [displayRegisterMFA, setDisplayRegisterMFA] = useState(false);
  const [displayResetMFA, setDisplayResetMFA] = useState(false);
  const [displayResetPassword, setDisplayResetPassword] = useState(false);
  const [displaySecurityQuestionSetup, setDisplaySecurityQuestionSetup] = useState(false);
  const [displaySignonMFA, setDisplaySignonMFA] = useState(false);
  const [isSSO, setIsSSO] = useState(false);
  const [resetQuestions, setResetQuestions] = useState([]);
  const [securityQuestionsSetup, setSecurityQuestionsSetup] = useState(false);
  const [userDetailsRetrieved, setUserDetailsRetrieved] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const { t } = useTranslation();

  const {
    loginWithRedirect,
  } = useAuth0();

  const handleLoginFailure = useCallback(({ message }) => {
    if (message) displayErrorNotification(t(message));
    setAuthenticated(false);
    setDisplayRegisterMFA(false);
    setDisplayResetMFA(false);
    setDisplaySecurityQuestionSetup(false);
    setDisplaySignonMFA(false);
    navigate('/logout');
  }, [navigate, setAuthenticated, t]);

  const handleCancelRegisterMFA = useCallback(() => {
    handleLoginFailure({ message: 'form.login.mfa.errors.verificationCancelled' });
  }, [handleLoginFailure]);

  const handleCancelResetMFA = useCallback(() => {
    setDisplayResetMFA(false);
    setDisplaySignonMFA(true);
  }, [setDisplayResetMFA, setDisplaySignonMFA]);

  const handleCancelResetPassword = useCallback(() => {
    setDisplayResetPassword(false);
  }, [setDisplayResetPassword]);

  const handleCancelSecurityQuestionSetup = useCallback(() => {
    handleLoginFailure({ message: 'form.login.errors.securityQuestionsCanceled' });
  }, [handleLoginFailure]);

  const handleCancelSignonMFA = useCallback(() => {
    handleLoginFailure({ message: 'form.login.mfa.errors.verificationCancelled' });
  }, [handleLoginFailure]);

  const handleLoginSuccess = useCallback(({ message = 'form.login.loginComplete', navigationPath = '/' } = {}) => {
    setDisplayRegisterMFA(false);
    setDisplayResetMFA(false);
    setDisplaySecurityQuestionSetup(false);
    setDisplaySignonMFA(false);
    setAuthenticated(true);
    displaySuccessNotification(t(message));
    return navigate(navigationPath || from, { replace: true });
  }, [from, navigate, setAuthenticated, t]);

  const handleRegisterMFASuccess = useCallback(({ message }) => {
    setDisplayRegisterMFA(false);
    if (!securityQuestionsSetup) setDisplaySecurityQuestionSetup(true);
    else handleLoginSuccess({ message, path: '/' });
  }, [handleLoginSuccess, securityQuestionsSetup]);

  const handleResetPasswordSuccess = useCallback(({ message = 'form.login.password.reset.success' }) => {
    setDisplayResetPassword(false);
    displaySuccessNotification(t(message));
  }, [t]);

  const handleResetPasswordFailure = useCallback(({ message = 'form.login.password.reset.errors.submitFailure' }) => {
    setDisplayResetPassword(false);
    displayErrorNotification(t(message));
  }, [t]);

  const handleResetToken = useCallback(() => {
    setDisplaySignonMFA(false);
    setDisplayResetMFA(true);
  }, []);

  const handleResetTokenSuccess = useCallback(() => {
    setDisplayResetMFA(false);
    navigate('/logout');
  }, [navigate]);

  const handleSignonMFASuccess = useCallback(() => {
    handleLoginSuccess({ message: t('form.login.mfa.signon.success'), path: '/' });
  }, [handleLoginSuccess, t]);

  const handleSecurityQuestionSetupSuccess = useCallback(() => {
    setDisplaySecurityQuestionSetup(false);
    setSecurityQuestionsSetup(true);
    handleLoginSuccess({ message: t('form.login.mfa.securityQuestions.success'), path: '/' });
  }, [handleLoginSuccess, t]);

  const getUserDetails = useCallback(async (username) => {
    try {
      const response = await callAPI('/api/authentication/getUserDetails', { username }, 'post');
      const { data = {}, status } = response;

      if (status === 401) throw new Error('form.login.errors.loginFailed');

      setUserDetailsRetrieved(true);
      const { isSsoGroup } = data;
      if (isSsoGroup === 'Y') {
        setIsSSO(true);
        setAwaitingSSO(true);
        loginWithRedirect();
      }
    } catch (error) {
      const { message } = error;
      handleLoginFailure({ message: t(message) });
    }
  }, [callAPI, handleLoginFailure, loginWithRedirect, t]);

  const updateLogInState = useCallback((data) => {
    const {
      mfa: {
        mfaTokenRequired,
        registerMFADeviceRequired,
        rememberedDevice,
      } = {},
      securityQuestions,
    } = data;

    const securityQuestionsComplete = !!securityQuestions?.length;

    setResetQuestions(securityQuestions);
    setSecurityQuestionsSetup(securityQuestionsComplete);

    // if remembered device or not a MFA user, return with no MFA modals displayed
    if (rememberedDevice || !mfaTokenRequired) handleLoginSuccess();

    // since they are still here, they are a MFA user
    // first check to see if they need to register a MFA device
    if (registerMFADeviceRequired) setDisplayRegisterMFA(true);
    // if they have a registered device, see if they have MFA reset questions setup
    else if (!securityQuestionsComplete) setDisplaySecurityQuestionSetup(true);
    // if those are good, verify we need to show the MFA signon form
    else if (mfaTokenRequired) setDisplaySignonMFA(true);
  }, [handleLoginSuccess]);

  const logIn = useCallback(async (credentials) => {
    try {
      const response = await callAPI('/api/authentication/login', credentials, 'post');
      const { data = {}, status } = response;
      const { messageReturnCode, token } = data;
      if (status === 401) throw new Error('form.login.errors.loginFailed');
      if (status === 403) {
        switch (messageReturnCode) {
        case '2003':
          throw new Error('form.login.errors.invalidIpAddress');
        case '2005':
          displayInfoNotification(t('form.login.errors.passwordExpired'));
          navigate(`/reset-password?token=${encodeURIComponent(token)}`);
          return;
        case '2011':
          throw new Error('form.login.errors.locked');
        default:
          throw new Error('form.login.errors.actionForbidden');
        }
      }
      updateLogInState(data);
    } catch (error) {
      const { message } = error;
      handleLoginFailure({ message: t(message) });
    }
  }, [callAPI, handleLoginFailure, navigate, t, updateLogInState]);

  const providerValue = useMemo(() => ({
    awaitingSSO,
    displayRegisterMFA,
    displayResetMFA,
    displayResetPassword,
    displaySecurityQuestionSetup,
    displaySignonMFA,
    getUserDetails,
    handleCancelRegisterMFA,
    handleCancelResetMFA,
    handleCancelResetPassword,
    handleCancelSecurityQuestionSetup,
    handleCancelSignonMFA,
    handleLoginFailure,
    handleLoginSuccess,
    handleRegisterMFASuccess,
    handleResetPasswordFailure,
    handleResetPasswordSuccess,
    handleResetToken,
    handleResetTokenSuccess,
    handleSecurityQuestionSetupSuccess,
    handleSignonMFASuccess,
    isSSO,
    logIn,
    resetQuestions,
    securityQuestionsSetup,
    setAwaitingSSO,
    setDisplayRegisterMFA,
    setDisplayResetMFA,
    setDisplayResetPassword,
    setDisplaySecurityQuestionSetup,
    setDisplaySignonMFA,
    setIsSSO,
    setResetQuestions,
    setSecurityQuestionsSetup,
    setUserDetailsRetrieved,
    userDetailsRetrieved,
  }), [
    awaitingSSO,
    displayRegisterMFA,
    displayResetMFA,
    displayResetPassword,
    displaySecurityQuestionSetup,
    displaySignonMFA,
    getUserDetails,
    handleCancelRegisterMFA,
    handleCancelResetMFA,
    handleCancelResetPassword,
    handleCancelSecurityQuestionSetup,
    handleCancelSignonMFA,
    handleLoginFailure,
    handleLoginSuccess,
    handleRegisterMFASuccess,
    handleResetPasswordFailure,
    handleResetPasswordSuccess,
    handleResetToken,
    handleResetTokenSuccess,
    handleSecurityQuestionSetupSuccess,
    handleSignonMFASuccess,
    logIn,
    isSSO,
    resetQuestions,
    securityQuestionsSetup,
    userDetailsRetrieved,
  ]);

  return (
    <LoginContext.Provider value={providerValue}>
      { children }
    </LoginContext.Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default LoginContext;
