import PropTypes from 'prop-types';

const filterTypesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

const activeFilterTypesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    criteria: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }),
);

export {
  filterTypesPropType,
  activeFilterTypesPropType,
};
