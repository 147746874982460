import React, {
  useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import '../../styles/scss/Paginator.scss';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';

const Paginator = ({
  currentPage, onPageChange, onPageSizeChange, pageSize, pageSizeOptions, startAtZero, totalPages,
}) => {
  const [pageRangeDisplayed, setPageRangeDisplayed] = useState(1);
  const [marginPagesDisplayed, setMarginPagesDisplayed] = useState(0);
  const { t } = useTranslation();

  const setPageRange = () => {
    const windowWidth = window.innerWidth;

    const BREAKPOINTS = {
      lg: { range: 5, width: 992 },
      md: { range: 3, width: 768 },
      sm: { range: 1, width: 576 },
    };

    const {
      sm: { width: smWidth, range: smRange },
      md: { width: mdWidth, range: mdRange },
      lg: { width: lgWidth, range: lgRange },
    } = BREAKPOINTS;

    setMarginPagesDisplayed(windowWidth < smWidth ? 0 : 1);

    if (windowWidth < mdWidth) {
      setPageRangeDisplayed(smRange);
    } else if (windowWidth < lgWidth) {
      setPageRangeDisplayed(mdRange);
    } else {
      setPageRangeDisplayed(lgRange);
    }
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    if (onPageSizeChange) {
      onPageSizeChange(newPageSize);
    }
    if (onPageChange) {
      onPageChange(startAtZero ? 0 : 1);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setPageRange);
    setPageRange();
    return () => window.removeEventListener('resize', setPageRange);
  }, []);

  // ReactPaginate starts at page 0; when startAtZero prop is false, this component starts at 1
  // when false, page numbers must be decremented by 1 to align with ReactPaginate
  const handlePageChange = useCallback(({ selected: pageSelection }) => {
    const newPage = startAtZero ? pageSelection : pageSelection + 1;
    if (onPageChange) {
      onPageChange(newPage);
    }
  }, [onPageChange, startAtZero]);

  const forcePage = startAtZero ? currentPage : currentPage - 1;

  return (
    <div className='paginator'>
      <select className='page-size' onChange={handlePageSizeChange} value={pageSize}>
        {pageSizeOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className='page-container'>
        <ReactPaginate
          containerClassName='pages'
          activeClassName='active'
          pageClassName='page'
          breakClassName='page page-break'
          nextClassName='page'
          previousClassName='page'
          pageLinkClassName='pagination-link'
          nextLinkClassName='pagination-link'
          previousLinkClassName='pagination-link'
          breakLinkClassName='pagination-link'
          onPageChange={handlePageChange}
          pageCount={totalPages}
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={marginPagesDisplayed}
          breakLabel='...'
          previousLabel='<'
          nextLabel='>'
          forcePage={forcePage} // decrementing page to align with ReactPaginate logic
        />
        { !marginPagesDisplayed && (
          <div className='text-center mt-1'>
            {t('misc.countOfTotal', {
              count: startAtZero ? currentPage + 1 : currentPage,
              total: totalPages,
            })}
          </div>
        )}
      </div>

    </div>
  );
};

Paginator.propTypes = {
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  startAtZero: PropTypes.bool,
  totalPages: PropTypes.number,
};

Paginator.defaultProps = {
  currentPage: 1,
  onPageChange: undefined,
  onPageSizeChange: undefined,
  pageSize: 15,
  pageSizeOptions: [10, 15, 25, 50, 100],
  startAtZero: false,
  totalPages: 1,
};

export default Paginator;
