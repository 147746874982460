import React, { useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

const FormToggleSwitch = ({
  defaultValue,
  fieldName,
  validationRules,
  handleChange,
  ...toggleProps
}) => {
  const {
    control,
    setValue,
    watch,
  } = useFormContext();

  const {
    field: {
      name,
      onBlur,
      value,
    },
  } = useController({
    control,
    name: fieldName,
    rules: validationRules,
  });

  // watches the fieldName value provided to allow form to track current selection
  watch([fieldName]);

  return (
    // using Controller component from react-hook-form to control non-native input element
    <ToggleSwitch
      checked={defaultValue || value}
      onBlur={onBlur}
      onChange={useCallback(event => {
        setValue(name, event, { shouldDirty: true });
        if (handleChange) {
          handleChange(event);
        }
      }, [handleChange, name, setValue])}
      {...toggleProps}
    />
  );
};

FormToggleSwitch.propTypes = {
  defaultValue: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  validationRules: PropTypes.shape({
    maxLength: PropTypes.number,
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    setValueAs: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),

};

FormToggleSwitch.defaultProps = {
  defaultValue: undefined,
  handleChange: undefined,
  validationRules: {},
};

export default FormToggleSwitch;
