import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../../styles/scss/PasswordDisclaimer.scss';

const PasswordDisclaimer = ({ additionalClasses }) => {
  const { t } = useTranslation();

  return (
    <div className={`password-disclaimer ${additionalClasses || ''}`}>
      {t('passwordDisclaimer')}
    </div>
  );
};

PasswordDisclaimer.propTypes = {
  additionalClasses: PropTypes.string,
};

PasswordDisclaimer.defaultProps = {
  additionalClasses: undefined,
};

export default PasswordDisclaimer;
