import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputField from '../../components/Form/FormInputField';
import ModalForm from '../../components/ModalForm/ModalForm';
import useAuthentication from '../../hooks/useAuthentication';
import { emailAddressPattern, maxLengths } from '../../helpers/validation';
import PasswordDisclaimer from '../../components/PasswordDisclaimer/PasswordDisclaimer';

const ResetPassword = ({
  cancelAction, onFailure, onSuccess, visible, ...modalProps
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const { callAPI } = useAuthentication();
  const { i18n, t } = useTranslation();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
  } = methods;

  const handleCancelForm = useCallback(() => {
    reset();
    cancelAction();
  }, [cancelAction, reset]);

  const handleMessageAcknowledgment = useCallback(() => {
    setShowMessage(false);
    onSuccess('form.login.password.reset.success');
  }, [onSuccess]);

  const requestPasswordReset = async (formData) => {
    try {
      const {
        email,
      } = formData;
      const locale = i18n.language.replace('-', '_');
      const response = await callAPI('/api/security/resetPassword', { email, locale }, 'post');
      const { data: { successful } = {} } = response;

      if (!successful) {
        throw new Error(t('form.login.password.reset.errors.submitFailure'));
      }
      setShowMessage(true);
    } catch (error) {
      const { message } = error;
      onFailure(message);
    } finally {
      reset();
    }
    return true;
  };

  const {
    accessManagement: {
      EMAIL_MAX_LENGTH,
    },
  } = maxLengths;

  return (
    <FormProvider {...methods}>
      {showMessage
        ? (
          <ModalForm
            {...modalProps}
            confirmAction={handleMessageAcknowledgment}
            cancelAction=''
            modalTitle={t('form.login.password.reset.title')}
            visible={visible}
          >
            <div>{t('form.login.password.reset.confirmation')}</div>
          </ModalForm>
        )
        : (
          <ModalForm
            {...modalProps}
            cancelAction={handleCancelForm}
            confirmAction={handleSubmit(requestPasswordReset)}
            modalCancelText={t('buttons.cancel')}
            modalConfirmText={t('buttons.submit')}
            modalTitle={t('form.login.password.reset.title')}
            visible={visible}
          >
            <form id='forgot-password' onSubmit={handleSubmit(requestPasswordReset)}>
              <div className='mb-3'>{t('form.login.password.reset.description')}</div>
              <div className='row'>
                <FormInputField
                  autocomplete='off'
                  fieldName='email'
                  placeholder='username@domain.tld'
                  type='email'
                  validationRules={{
                    maxLength: {
                      message: t('form.fieldExceedsMaxLengthGeneric'),
                      value: EMAIL_MAX_LENGTH,
                    },
                    pattern: {
                      message: t('form.login.password.reset.errors.invalidEmail'),
                      value: emailAddressPattern,
                    },
                    required: t('form.required'),
                  }}
                />
              </div>
            </form>
            <PasswordDisclaimer
              additionalClasses='mt-3'
            />
          </ModalForm>
        )}
    </FormProvider>
  );
};

ResetPassword.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ResetPassword;
