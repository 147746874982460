import { useLocation, Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuthentication from '../../hooks/useAuthentication';
import Error from '../../pages/Error/Error';

const RequireAuthentication = ({ authCondition }) => {
  const { authenticated } = useAuthentication();
  const location = useLocation();

  if (!authenticated) {
    return <Navigate to='/login' state={{ from: location }} replace />;
  }
  return authCondition ? <Outlet /> : <Error />;
};

RequireAuthentication.propTypes = {
  authCondition: PropTypes.bool,
};

RequireAuthentication.defaultProps = {
  authCondition: true,
};

export default RequireAuthentication;
