import i18n from '../i18n';

/*
Countries / states / provinces are retrieved from codes as numeric values and the labels are in English.
We need translations for these, but our translations are based on a file within the app and not retrieved remotely like other SVS apps
So, we use the businessLogicType of the codes entries to create our own translations for countries / states / provinces.
The businessLogicTypes were pulled from the COUNTRYID (US and Canada only), US_STATES, and CA_PROVINCES codes

Translations follow this format in the JSON file:
  - Countries: "country.businessLogicTypeForCountry"
    - example, United States: "country.United States"
  - States / Provinces: "stateProvince.businessLogicTypeForCountry.businessLogicTypeForStateProvince"
    - example, Kentucky: "stateProvince.United States.Kentucky"
*/

// at launch only supporting US / Canada
const supportedCountries = ['United States', 'Canada'];

/**
 * function to handle translations related to codes
 * if a translation exists, use it - otherwise, use the fallback provided as an argument
 * @param {string} translation i18n translation string
 * @param {string} fallback fallback translation string
 * @returns final translation string
 */
const translateWithFallback = (translation, fallback) => (i18n.exists(translation) ? i18n.t(translation) : fallback);

const getReadableCountryAndState = (countryCode, stateProvinceCode, codes) => {
  // setting defaults as codes values if all else fails
  let readableCountry = countryCode;
  let readableStateProvince = stateProvinceCode;

  const countryCodeMatch = codes?.COUNTRYID?.filter(({
    value, businessLogicType,
  }) => value === countryCode && supportedCountries.includes(businessLogicType)) || [];

  if (countryCodeMatch.length) {
    const [{ businessLogicType: countryBlt }] = countryCodeMatch;
    const countryTranslation = `country.${countryBlt}`;
    readableCountry = translateWithFallback(countryTranslation, countryBlt);
    const stateProvinceKey = countryBlt === 'United States' ? 'US_STATES' : 'CA_PROVINCES';
    const stateProvinceMatch = codes[stateProvinceKey]?.filter(({ value }) => value === stateProvinceCode) || [];
    if (stateProvinceMatch.length) {
      const [{ businessLogicType: stateProvinceBlt }] = stateProvinceMatch;
      const stateProvinceTranslation = `stateProvince.${countryBlt}.${stateProvinceBlt}`;
      readableStateProvince = translateWithFallback(stateProvinceTranslation, stateProvinceBlt);
    }
  }

  return { readableCountry, readableStateProvince };
};

const getCountryOptions = (codes) => codes?.COUNTRYID?.filter(
  ({ businessLogicType }) => supportedCountries.includes(businessLogicType),
)?.map(({ value, businessLogicType }) => ({
  label: translateWithFallback(`country.${businessLogicType}`, businessLogicType),
  value,
})) || [];

const getStateProvinceOptions = (countryCode, codes) => {
  let stateProvinceOptions = [];

  const countryCodeMatch = codes?.COUNTRYID?.filter(({
    value, businessLogicType,
  }) => value === countryCode && supportedCountries.includes(businessLogicType)) || [];

  if (countryCodeMatch.length) {
    const [{ businessLogicType: countryBlt }] = countryCodeMatch;
    const stateProvinceKey = countryBlt === 'United States' ? 'US_STATES' : 'CA_PROVINCES';

    stateProvinceOptions = codes[stateProvinceKey]?.map(({ value, businessLogicType: stateProvinceBlt }) => ({
      label: translateWithFallback(`stateProvince.${countryBlt}.${stateProvinceBlt}`, stateProvinceBlt),
      value,
    })) || [];
  }

  return stateProvinceOptions;
};

const getReadableAccountType = (accountType, codes) => {
  let readableAccountType = accountType; // setting default as the codes value if all else fails
  const { businessLogicType } = codes?.ACH_ACCT_TYPE?.find(({ value }) => value === accountType) || {};
  if (businessLogicType) {
    const achTranslation = `ACH_ACCT_TYPE.${businessLogicType}`;
    readableAccountType = translateWithFallback(achTranslation, businessLogicType);
  }
  return readableAccountType;
};

const translateFromCodes = (codes, codesKey, translationParent, value) => {
  const { businessLogicType: blt } = codes[codesKey]?.find(({ value: codesValue }) => codesValue === value) || {};
  if (!blt) {
    return value?.toString();
  }
  const translationKey = translationParent ? `${translationParent}.${blt}` : blt;
  return translateWithFallback(translationKey, blt);
};

export {
  getReadableCountryAndState,
  getCountryOptions,
  getStateProvinceOptions,
  getReadableAccountType,
  translateFromCodes,
};
