import React, {
  useState, useEffect, useCallback, useContext, useMemo, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import '../../styles/scss/CardOrderEntry.scss';
import '../../styles/scss/StyledSelect.scss';
import { Link } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification } from '../../components/Notification/Notify';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';
import ProductCard from '../../components/CardOrdering/ProductCard';
import Paginator from '../../components/Paginator/Paginator';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { buildCardOrderingStoreListPayload, formatCardOrderingStoreList } from '../../utils/helpers';

const CardOrderEntry = () => {
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const {
    refreshCart,
    refreshStore,
    resetOrderContext,
    selectedStore,
  } = useContext(CardOrderingContext);

  const {
    emulatedUser,
    emulationMode: {
      read: emulationEnabled = false,
    },
    isAdminCreatedUser,
  } = useContext(GlobalContext);

  const initialStoreList = selectedStore ? [{ ...selectedStore }] : [];

  const [storeList, setStoreList] = useState(initialStoreList);
  const [products, setProducts] = useState([]);
  const [labelFilter, setLabelFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [retrievedStores, setRetrievedStores] = useState(false);
  const [refreshedCart, setRefreshedCart] = useState(false);
  const [refreshedStoreProducts, setRefreshedStoreProducts] = useState(false);
  const hasMounted = useRef(false);

  const onPageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({
      behavior: 'instant',
      top: 0,
    });
  }, [setCurrentPage]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStoreList = async () => {
      try {
        setIsLoading(true);
        const payload = buildCardOrderingStoreListPayload(isAdminCreatedUser, emulationEnabled, emulatedUser);
        const {
          data: {
            resultList = [],
            messageReturnCode,
          },
          status,
          statusText,
        } = await callAPI('/api/stores/getStoreList', payload, 'post', true);
        if (status !== 200 || !['1000', '1001'].includes(messageReturnCode)) {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }

        const formattedResults = formatCardOrderingStoreList(resultList);
        setStoreList(formattedResults);
        refreshStore(formattedResults);
      } catch ({ message }) {
        const errMsg = t('dashboard.failedToRetrieveStores', {
          message: message || t('site.unknownErrorOccurred'),
        });
        displayErrorNotification(errMsg);
      } finally {
        setIsLoading(false);
        setRetrievedStores(true);
      }
    };

    if (!retrievedStores && !hasMounted.current) {
      getStoreList();
      hasMounted.current = true;
    }
  }, [callAPI, refreshStore, t, emulatedUser, emulationEnabled,
    retrievedStores, isAdminCreatedUser]);

  const selectedStoreCimsId = useMemo(() => selectedStore?.value, [selectedStore]);

  useEffect(() => {
    setRefreshedStoreProducts(false);
  }, [selectedStoreCimsId]);

  useEffect(() => {
    const refreshStoreProducts = async () => {
      try {
        setIsLoading(true);
        const {
          data: {
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/cardOrdering/refreshStoreProducts', { cimsStoreId: selectedStoreCimsId }, 'post', true);

        if (status !== 200 || messageReturnCode !== '1000') {
          const errorMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errorMsg);
        }
      } catch ({ message }) {
        displayErrorNotification(t('cardOrdering.errors.failedToRefreshProducts', { message }), false);
      } finally {
        setRefreshedStoreProducts(true); // proceed to list products regardless of refresh result
        setIsLoading(false);
      }
    };

    if (selectedStoreCimsId && retrievedStores && !emulationEnabled && !refreshedStoreProducts) {
      refreshStoreProducts();
    }
  }, [callAPI, selectedStoreCimsId, t, refreshedStoreProducts, emulationEnabled, retrievedStores]);

  useEffect(() => {
    const getStoreProductInventory = async () => {
      try {
        setIsLoading(true);
        const {
          data: {
            resultList,
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/cardOrdering/getStoreProductInventory', {
          includeDetachedProductsWithInventory: false,
          includeNotOrderedProducts: true,
          inWarehouseOnly: true,
          productTypeFilter: -1,
          skuFilter: null,
          storeId: selectedStoreCimsId,
          usageDays: -1,
        }, 'post', true);
        if (status !== 200 || !['1000', '1001'].includes(messageReturnCode)) {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }
        const productsWithQuantity = resultList.map((product) => ({
          ...product,
          quantity: undefined,
        }));
        setProducts(productsWithQuantity);
        refreshCart(resultList);
      } catch ({ message }) {
        const errMsg = t('cardOrdering.errors.failedToRetrieveProducts', {
          message: message || t('site.unknownErrorOccurred'),
        });
        displayErrorNotification(errMsg);
      } finally {
        setIsLoading(false);
        setRefreshedCart(true);
      }
    };

    if (selectedStoreCimsId && !refreshedCart && (emulationEnabled || (!emulationEnabled && refreshedStoreProducts))) {
      getStoreProductInventory();
    }
  }, [selectedStoreCimsId, callAPI, refreshCart, refreshedCart, t, refreshedStoreProducts, emulationEnabled]);

  const resetOrder = useCallback((newSelectedStore) => {
    resetOrderContext(newSelectedStore);
    setRefreshedCart(false);
    setProducts([]);
    setLabelFilter('');
    setCurrentPage(1);
  }, [resetOrderContext]);

  const onStoreSelect = useCallback((newValue) => {
    resetOrder(newValue);
  }, [resetOrder]);

  const handleReset = () => {
    resetOrder(null);
  };

  const onFilterChange = (event) => {
    const newValue = event.target.value;
    setLabelFilter(newValue);
    setCurrentPage(1);
  };

  const onPageSizeChange = useCallback((newPageSize) => {
    setPageSize(newPageSize);
  }, [setPageSize]);

  const filteredProducts = labelFilter ? products.filter(({ sku, skuDescription }) => {
    const labelLower = `${sku} - ${skuDescription}`.toLowerCase();
    return labelLower.includes(labelFilter.trim().toLowerCase());
  }) : [...products];

  const minStartIndex = (currentPage - 1) * pageSize;
  const startIndex = minStartIndex < 0 ? 0 : minStartIndex;

  const maxEndIndex = currentPage * pageSize;
  const lastIndex = filteredProducts.length - 1;
  const endIndex = maxEndIndex > lastIndex ? lastIndex + 1 : maxEndIndex;

  const paginatedProducts = [...filteredProducts].slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredProducts.length / pageSize);

  const pageSizeOptions = [12, 24, 48];

  const onQuantityChange = useCallback(({ newQuantity, productId }) => {
    const index = products.findIndex(({
      productId: listProductId,
    }) => listProductId === productId);

    if (index > -1) {
      setProducts(prevState => {
        const newState = [...prevState];
        newState[index].quantity = newQuantity;
        return newState;
      });
    }
  }, [products]);

  return (
    <div className='container' id='card-order-entry'>
      <header className='mb-3'>
        <h1>{t('site.sidebar.cardOrdering.orderEntry')}</h1>
      </header>
      <div className='store-select row'>
        <div className='col-12 col-md-6 col-lg-4'>
          <Select
            className='styled-select'
            classNamePrefix='styled-select'
            onChange={onStoreSelect}
            options={storeList}
            placeholder={t('cardOrdering.orderEntry.selectStorePlaceholder')}
            value={selectedStore}
            isDisabled={!!selectedStore}
          />
        </div>
      </div>
      {isLoading && <div>{elastic}</div>}
      {selectedStore && !isLoading && (
        <div className='product-list mt-3'>
          <header>
            <h2>{t('cardOrdering.orderEntry.products')}</h2>
          </header>
          <div className='description-filter row my-3'>
            <div className='col-12 col-md-6 col-lg-4'>
              <input
                className='form-control'
                value={labelFilter}
                onChange={onFilterChange}
                placeholder={t('cardOrdering.orderEntry.filterPlaceholder')}
              />
            </div>
          </div>
          <div className='product-panel'>
            {filteredProducts.length ? (
              <>
                <div className='products'>
                  {paginatedProducts.map((product) => (
                    <ProductCard
                      data={product}
                      key={product.productId}
                      quantity={product.quantity}
                      onQuantityChange={onQuantityChange}
                    />
                  ))}
                </div>
                <Paginator
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                  pageSize={pageSize}
                  onPageSizeChange={onPageSizeChange}
                  pageSizeOptions={pageSizeOptions}
                />
              </>
            ) : <p>{t('cardOrdering.orderEntry.noProducts')}</p>}
          </div>
        </div>
      )}
      <div className='row mt-3'>
        <div className='col-12 action-buttons'>
          <button className='btn btn-primary reset-button dropin' id='reset' onClick={handleReset} tabIndex={0} type='button'>{t('buttons.reset')}</button>
          <Link to='/card-ordering/checkout'>
            <button className='btn generate dropin' disabled={!selectedStore} type='button'>{t('cardOrdering.orderEntry.checkout')}</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardOrderEntry;
