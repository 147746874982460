import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification } from '../../components/Notification/Notify';
import LocaleSelector from '../../components/Header/LocaleSelector';
import RegisterMFA from './MFA/RegisterMFA';
import ResetMFA from './MFA/ResetMFA';
import SignonMFA from './MFA/SignonMFA';
import SecurityQuestionSetup from './MFA/SecurityQuestionSetup';
import useLogin from '../../hooks/useLogin';

const SSOCallback = () => {
  const {
    displayRegisterMFA,
    displayResetMFA,
    displaySecurityQuestionSetup,
    displaySignonMFA,
    handleCancelRegisterMFA,
    handleCancelResetMFA,
    handleCancelSecurityQuestionSetup,
    handleCancelSignonMFA,
    handleLoginFailure,
    handleLoginSuccess,
    handleRegisterMFASuccess,
    handleResetToken,
    handleResetTokenSuccess,
    handleSecurityQuestionSetupSuccess,
    handleSignonMFASuccess,
    resetQuestions,
    setDisplayRegisterMFA,
    setDisplaySignonMFA,
    setResetQuestions,
    setSecurityQuestionsSetup,
  } = useLogin();
  const { callAPI } = useAuthentication();
  const { isAuthenticated, isLoading, user } = useAuth0();

  const { t } = useTranslation();

  const navigate = useNavigate();

  /**
   * @description Create user session after Auth0 populates its context
   */
  useEffect(() => {
    if (isLoading) return;
    const validateSSOUser = async () => {
      try {
        // If Auth0 says user is not authenticated send them back to login screen
        if (!isAuthenticated) {
          displayErrorNotification(t('form.login.errors.loginFailed'));
          navigate('/');
        }

        // If we do not have a user and we are authenticated return, this would be caused by users only deleting certain cookies and then navigating to this endpoint
        if (!user) return;

        const response = await callAPI('/api/authentication/verifySSOAuthentication', { username: user.email });
        const {
          data: {
            mfa: {
              mfaTokenRequired,
              registerMFADeviceRequired,
              rememberedDevice,
            },
            securityQuestions,
          } = {},
        } = response;

        const securityQuestionsComplete = !!securityQuestions?.length;

        setResetQuestions(securityQuestions);
        setSecurityQuestionsSetup(securityQuestionsComplete);

        // if remembered device or not a MFA user, return with no MFA modals displayed
        if (rememberedDevice || !mfaTokenRequired) handleLoginSuccess();

        // since they are still here, they are a MFA user
        // first check to see if they need to register a MFA device
        if (registerMFADeviceRequired) setDisplayRegisterMFA(true);
        // if they have a registered device, see if they have MFA reset questions setup
        else if (mfaTokenRequired) setDisplaySignonMFA(true);
      } catch (error) {
        const { message } = error;
        handleLoginFailure({ message });
      }
    };
    validateSSOUser();
  }, [callAPI, handleLoginFailure, handleLoginSuccess, isAuthenticated, isLoading, navigate,
    setDisplayRegisterMFA, setDisplaySignonMFA, setResetQuestions, setSecurityQuestionsSetup, t, user]);

  return (
    <>
      <div className='header-menu'>
        <LocaleSelector />
      </div>
      <div className='sso container'>
        <RegisterMFA
          cancelAction={handleCancelRegisterMFA}
          onFailure={handleLoginFailure}
          onSuccess={handleRegisterMFASuccess}
          visible={displayRegisterMFA}
        />
        <ResetMFA
          cancelAction={handleCancelResetMFA}
          resetQuestions={resetQuestions}
          onFailure={handleLoginFailure}
          onSuccess={handleResetTokenSuccess}
          visible={displayResetMFA}
        />
        <SignonMFA
          cancelAction={handleCancelSignonMFA}
          onFailure={handleLoginFailure}
          onSuccess={handleSignonMFASuccess}
          resetAction={handleResetToken}
          visible={displaySignonMFA}
        />
        <SecurityQuestionSetup
          cancelAction={handleCancelSecurityQuestionSetup}
          onFailure={handleLoginFailure}
          onSuccess={handleSecurityQuestionSetupSuccess}
          visible={displaySecurityQuestionSetup}
        />
        { elastic }
      </div>
    </>
  );
};

export default SSOCallback;
