import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context/Global/GlobalContext';
import Header from '../../components/Header/Header';
import Sidebar from './Sidebar';
import '../../styles/scss/Viewport.scss';
import SiteRoutes from './SiteRoutes';

const Viewport = () => {
  const {
    sidebarToggled,
    sessionData: { locale },
  } = useContext(GlobalContext);

  const { i18n } = useTranslation();

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname !== '/access/users/manage') {
      sessionStorage.removeItem('userToEdit');
    }

    if (pathname !== '/store') {
      sessionStorage.removeItem('currentStoreDetail');
    }
  }, [pathname]);

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale.replace('_', '-'));
    }
  }, [locale, i18n]);

  return (
    <>
      <Header />
      <main className={`${sidebarToggled ? 'toggled' : ''}`}>
        <Sidebar />
        <section>
          <div id='viewport'>
            <SiteRoutes />
          </div>
        </section>
      </main>
    </>
  );
};

export default Viewport;
