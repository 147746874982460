import React, { useContext, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputField from '../../components/Form/FormInputField';
import ModalForm from '../../components/ModalForm/ModalForm';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { getCountryOptions, getStateProvinceOptions } from '../../utils/codes';
import FormReactSelectField from '../../components/Form/FormReactSelectField';
import { emailAddressPattern, maxLengths } from '../../helpers/validation';

const isProvinceCountry = (country) => {
  const provinceCountries = ['Canada'];
  const { businessLogicType } = country;
  return provinceCountries.includes(businessLogicType);
};

const EditShippingAddress = ({ ...modalProps }) => {
  const { t } = useTranslation();
  const { codes } = useContext(GlobalContext);

  const countryOptions = getCountryOptions(codes);
  const { COUNTRYID: countries } = codes;

  const { getValues, setValue } = useFormContext();

  const {
    countryCode: formCountry,
  } = getValues();

  const provinceCountrySelected = countries.some(c => isProvinceCountry(c) && c.value === formCountry);
  let stateProvincePlaceholder;
  if (!formCountry) {
    stateProvincePlaceholder = t('storeDetails.editShippingAddressForm.selectCountry');
  } else {
    const placeholderType = provinceCountrySelected ? 'Province' : 'State';
    stateProvincePlaceholder = t(`storeDetails.editShippingAddressForm.select${placeholderType}`);
  }

  // populate state / province list based on what country is currently selected
  const stateProvinceOptions = getStateProvinceOptions(formCountry, codes);

  // clear selected state / province when the country selected changes
  const onCountryChange = useCallback(() => {
    setValue('stateProvince', null, { shouldDirty: true });
  }, [setValue]);

  const {
    editShippingAddress: {
      ADDRESS_MAX_LENGTH,
      CITY_MAX_LENGTH,
      CONTACT_NAME_MAX_LENGTH,
      CONTACT_PHONE_MAX_LENGTH,
      EMAIL_MAX_LENGTH,
      POSTAL_CODE_MAX_LENGTH,
      STORE_NAME_MAX_LENGTH,
    },
  } = maxLengths;

  return (
    <ModalForm
      {...modalProps}
      modalCancelText={t('buttons.cancel')}
      modalConfirmText={t('buttons.update')}
      modalTitle={t('storeDetails.editShippingAddressForm.title')}
    >
      <form>
        <div className='editShippingAddress row gy-2'>
          <FormInputField
            fieldName='storeName'
            label={t('storeDetails.editShippingAddressForm.siteName')}
            type='text'
            showCharacterCount
            maxLength={STORE_NAME_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: STORE_NAME_MAX_LENGTH }),
                value: STORE_NAME_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldName='address1'
            label={t('storeDetails.editShippingAddressForm.address1')}
            type='text'
            showCharacterCount
            maxLength={ADDRESS_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: ADDRESS_MAX_LENGTH }),
                value: ADDRESS_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldName='address2'
            label={t('storeDetails.editShippingAddressForm.address2')}
            type='text'
            showCharacterCount
            maxLength={ADDRESS_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: ADDRESS_MAX_LENGTH }),
                value: ADDRESS_MAX_LENGTH,
              },
            }}
          />
          <FormInputField
            fieldClasses='col-12 col-sm-6'
            fieldName='city'
            label={t('storeDetails.editShippingAddressForm.city')}
            type='text'
            showCharacterCount
            maxLength={CITY_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: CITY_MAX_LENGTH }),
                value: CITY_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormReactSelectField
            fieldClasses='col-12 col-sm-6'
            fieldName='stateProvince'
            label={t(`storeDetails.editShippingAddressForm.${provinceCountrySelected ? 'province' : 'state'}`)}
            maxMenuHeight={150}
            options={formCountry ? stateProvinceOptions : []}
            placeholder={stateProvincePlaceholder}
            validationRules={{
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldClasses='col-12 col-sm-6'
            fieldName='postalCode'
            label={t('storeDetails.editShippingAddressForm.postalCode')}
            type='text'
            showCharacterCount
            maxLength={POSTAL_CODE_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: POSTAL_CODE_MAX_LENGTH }),
                value: POSTAL_CODE_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormReactSelectField
            label={t('storeDetails.editShippingAddressForm.country')}
            fieldClasses='col-12 col-sm-6'
            fieldName='countryCode'
            options={countryOptions}
            handleChange={onCountryChange}
            validationRules={{
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldClasses='col-12 col-sm-6'
            fieldName='contactName'
            label={t('storeDetails.editShippingAddressForm.contactName')}
            type='text'
            showCharacterCount
            maxLength={CONTACT_NAME_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: CONTACT_NAME_MAX_LENGTH }),
                value: CONTACT_NAME_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldName='contactPhone'
            fieldClasses='col-12 col-sm-6'
            label={t('storeDetails.contactPhone')}
            type='text'
            showCharacterCount
            maxLength={CONTACT_PHONE_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: CONTACT_PHONE_MAX_LENGTH }),
                value: CONTACT_PHONE_MAX_LENGTH,
              },
              required: t('form.required'),
            }}
          />
          <FormInputField
            fieldClasses='col-12'
            fieldName='storePrimaryEmail'
            label={t('storeDetails.contactEmail')}
            key='contactEmail'
            type='email'
            showCharacterCount
            maxLength={EMAIL_MAX_LENGTH}
            validationRules={{
              maxLength: {
                message: t('form.fieldExceedsMaxLength', { maxLength: EMAIL_MAX_LENGTH }),
                value: EMAIL_MAX_LENGTH,
              },
              pattern: {
                message: t('form.user.invalidEmail'),
                value: emailAddressPattern,
              },
              required: t('form.required'),
            }}
          />
        </div>
      </form>
    </ModalForm>

  );
};

export default EditShippingAddress;
