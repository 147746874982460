import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputField from '../../../components/Form/FormInputField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import { displayErrorNotification } from '../../../components/Notification/Notify';
import useAuthentication from '../../../hooks/useAuthentication';
import { elastic } from '../../../components/LoadingAnimations/LoadingAnimations';
import { maxLengths, mfaTokenPattern } from '../../../helpers/validation';

const RegisterMFA = ({
  onFailure, onSuccess, visible, ...modalProps
}) => {
  const { callAPI } = useAuthentication();

  const [qrCode, setQRCode] = useState((<div id='placeholder'>{elastic}</div>));
  const [secret, setSecret] = useState('');

  const { t } = useTranslation();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
  } = methods;

  const registerMFA = async (data) => {
    try {
      const { authToken } = data;
      const { data: { successful } } = await callAPI('/api/authentication/registerMFA', { authToken, secret }, 'post');
      if (!successful) throw new Error(t('form.login.mfa.errors.invalidToken'));
      onSuccess({ message: t('form.login.mfa.registration.complete') });
      reset();
    } catch (error) {
      const { message } = error;
      displayErrorNotification(message);
    }
    return true;
  };

  useEffect(() => {
    async function getQRCode() {
      try {
        const { status, data: { mfaSecret, qrDataURL } } = await axios.post('/api/authentication/getMFAQRCode');
        if (status !== 200) throw new Error(t('form.login.mfa.errors.renderQRCodeError'));
        setSecret(mfaSecret);
        setQRCode(<img className='img-fluid' src={qrDataURL} alt='qr-code' />);
      } catch (error) {
        const { message } = error;
        onFailure({ message });
      }
      return null;
    }

    if (visible) getQRCode();
  }, [onFailure, t, visible]);

  const {
    mfa: { TOKEN_MAX_LENGTH },
  } = maxLengths;

  return (
    <FormProvider {...methods}>
      <ModalForm
        {...modalProps}
        confirmAction={handleSubmit(registerMFA)}
        modalCancelText={t('buttons.cancel')}
        modalConfirmText={t('buttons.submit')}
        modalTitle={t('form.login.mfa.registration.title')}
        visible={visible}
      >
        <form className='mfa' id='mfa-registration' onSubmit={handleSubmit(registerMFA)}>
          <div className='message'>{t('form.login.mfa.registration.QRMessage')}</div>
          <div id='qr-code'>
            {qrCode}
          </div>
          <div className='message'>{t('form.login.mfa.registration.MFAMessage')}</div>
          <div className='row'>
            <FormInputField
              autocomplete='off'
              fieldName='authToken'
              placeholder='123456'
              type='text'
              maxLength={TOKEN_MAX_LENGTH}
              validationRules={{
                pattern: {
                  message: t('form.login.mfa.errors.invalidToken'),
                  value: mfaTokenPattern,
                },
                required: t('form.required'),
              }}
            />
          </div>
        </form>
      </ModalForm>
    </FormProvider>
  );
};

RegisterMFA.propTypes = {
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default RegisterMFA;
