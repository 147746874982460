import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';
import { ReactComponent as Cards } from './images/svs-cards.svg';
import { GlobalContext } from '../../context/Global/GlobalContext';
import '../../styles/scss/Header.scss';
import LocaleSelector from './LocaleSelector';
import SidebarToggle from '../SidebarMenu/SidebarToggle';
import UserActions from './UserActions';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';

const Header = () => {
  const { t } = useTranslation();

  const {
    isAdmin,
    sessionData: { userName = null } = {},
    emulationMode: {
      read: emulationModeEnabled = false,
    } = {},
    emulatedUser = null,
    userPrivileges,
  } = useContext(GlobalContext);

  const emulatedUserName = emulatedUser?.userName || null;

  const userNameInHeader = emulationModeEnabled && emulatedUser ? emulatedUserName : userName;

  const {
    cart,
  } = useContext(CardOrderingContext);

  const CARD_ORDER_ENTRY_PRIVILEGE = 'CARD_ORDERING_PLACE_ORDERS';
  const emulatingAdmin = emulationModeEnabled && emulatedUser?.isAdmin;
  const hasCardOrderingPrivilege = userPrivileges.some(({
    privileges,
  }) => privileges?.includes(CARD_ORDER_ENTRY_PRIVILEGE));

  const canOrderCards = isAdmin || emulatingAdmin || hasCardOrderingPrivilege;

  return (
    <header className={emulatedUser ? 'emulation-mode' : ''}>
      <div className='header-items'>
        <SidebarToggle />
        <Cards />
        <div id='headerTitle'>
          {t('site.title')}
        </div>
      </div>
      {emulationModeEnabled && emulatedUser && (
        <div className='header-items' id='emulation-info'>
          <div id='emulation-title'>
            <span>{t('site.sidebar.emulationMode')}</span>
            <i
              alt={t('site.sidebar.emulationMode')}
              className='fa-solid fa-user-secret'
            />
          </div>
          <i
            id='info-tooltip-icon'
            alt={`${t('header.emulationMode.nowEmulating')} ${emulatedUserName} | ${t('header.emulationMode.endInstructions').replace(/"/g, '')}`}
            className='fa-solid fa-circle-info'
            data-tooltip-id='emulation-info-tooltip'
            data-tooltip-place='bottom'
          />
          <Tooltip id='emulation-info-tooltip'>
            <div className='mb-1'>
              <span>{t('header.emulationMode.nowEmulating')}</span>
              <span id='nowEmulating' className='ms-2'>{emulatedUserName}</span>
            </div>
            {t('header.emulationMode.endInstructions')}
          </Tooltip>
        </div>
      )}
      <div className='header-items' id='header-links'>
        <div className='header-menu'>
          <LocaleSelector />
        </div>
        { canOrderCards && (
          <div className='header-menu'>
            <Link to='/card-ordering/checkout' className='header-cart'>
              <div className=''>
                <i className='fa-solid fa-shopping-cart' />
                {cart.length}
              </div>
            </Link>
          </div>
        )}
        <div className={`header-menu ${emulatedUser ? ' emulating' : ''}`}>
          <UserActions userName={userNameInHeader} />
        </div>
      </div>
    </header>
  );
};

export default Header;
