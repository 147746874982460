import React from 'react';
import { useTranslation } from 'react-i18next';
import MenuDropdown from '../MenuDropdown/MenuDropdown';

const LocaleSelector = () => {
  const { i18n } = useTranslation();
  const { options: { resources } } = i18n;
  const locales = Object.keys(resources);
  const changeLocale = (locale) => () => i18n.changeLanguage(locale);

  const items = locales.map((locale, index) => <div key={locale} onClick={changeLocale(locale)} role='button' tabIndex={index}>{locale}</div>);

  return <MenuDropdown icon='fa-globe' items={items} />;
};

export default LocaleSelector;
