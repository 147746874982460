import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormInputField from '../../../components/Form/FormInputField';
import FormToggleSwitch from '../../../components/Form/FormToggleSwitch';
import ModalForm from '../../../components/ModalForm/ModalForm';
import useAuthentication from '../../../hooks/useAuthentication';
import { displayErrorNotification } from '../../../components/Notification/Notify';
import { maxLengths, mfaTokenPattern } from '../../../helpers/validation';

const SignonMFA = ({
  cancelAction, resetAction, onFailure, onSuccess, ...modalProps
}) => {
  const { callAPI } = useAuthentication();
  const { t } = useTranslation();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    setValue,
    watch,
  } = methods;
  const mfaTokenValue = watch('mfaToken');

  const handleCancelForm = useCallback(() => {
    reset();
    cancelAction();
  }, [cancelAction, reset]);

  const handleToggleChange = useCallback((value) => {
    setValue('mfaRemember', value);
  }, [setValue]);

  const signonMFA = useCallback(async (tokenData) => {
    try {
      const {
        mfaRemember,
        mfaToken: authToken,
      } = tokenData;
      const response = await callAPI('/api/authentication/signonMFA', { authToken, mfaRemember }, 'post');
      const { data, status } = response;
      const { successful } = data;

      if (!successful) {
        reset();
        if (status === 423) onFailure({ message: t('form.login.error.locked') });
        if (status === 500) throw new Error();
        throw new Error(t('form.login.mfa.errors.invalidToken'));
      }
      onSuccess();
    } catch (error) {
      const { message } = error;
      reset();
      displayErrorNotification(message);
    }
    return true;
  }, [callAPI, onFailure, onSuccess, reset, t]);

  useEffect(() => {
    if (mfaTokenValue?.length >= 6) handleSubmit(signonMFA)();
  }, [handleSubmit, mfaTokenValue, signonMFA]);

  const {
    mfa: { TOKEN_MAX_LENGTH },
  } = maxLengths;

  return (
    <FormProvider {...methods}>
      <ModalForm
        {...modalProps}
        cancelAction={handleCancelForm}
        modalCancelText={t('buttons.cancel')}
        modalConfirmText={t('buttons.submit')}
        modalTitle={t('form.login.mfa.signon.title')}
      >
        <form className='mfa' id='mfa-signon' onSubmit={handleSubmit(signonMFA)}>
          <div className='text-center mb-3'>{t('form.login.mfa.signon.description')}</div>
          <div className='row'>
            <FormInputField
              autocomplete='off'
              fieldName='mfaToken'
              maxLength={TOKEN_MAX_LENGTH}
              placeholder='123456'
              type='text'
              validationRules={{
                pattern: {
                  message: t('form.login.mfa.errors.invalidToken'),
                  value: mfaTokenPattern,
                },
                required: t('form.required'),
              }}
            />
          </div>
          <div id='mfa-controls'>
            <FormToggleSwitch
              fieldName='mfaRemember'
              handleChange={handleToggleChange}
              rightLabel={t('form.login.mfa.signon.remember')}
              verticalAlign='middle'
              wrapperClassName='storeToggle'
            />
            <button className='button-link' onClick={resetAction} type='button'>{t('form.login.mfa.signon.reset')}</button>
          </div>
        </form>
      </ModalForm>
    </FormProvider>
  );
};

SignonMFA.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
};

export default SignonMFA;
