import { translateFromCodes } from './codes';
import buildPayloadWithEmulation from './emulate';

/**
 * helper function for generating the payload to getStoreList (response populates list of stores to order from)
 * @param {boolean} isAdminCreatedUser whether caller is a non-admin user (FMUSER)
 * @param {boolean} emulationEnabled whether caller has the emulation read FA
 * @param {object} emulatedUser user info for user currently being emulated
 */
const buildCardOrderingStoreListPayload = (isAdminCreatedUser, emulationEnabled, emulatedUser) => {
  const initialPayload = {
    activeOnly: true,
    cimsOnly: true,
  };

  const emulatingAdminCreatedUser = emulationEnabled && emulatedUser && !emulatedUser?.isAdmin;
  if (isAdminCreatedUser || emulatingAdminCreatedUser) {
    initialPayload.roleFilter = ['CARD_ORDERING_PLACE_ORDERS'];
  }

  return buildPayloadWithEmulation(initialPayload, emulationEnabled, emulatedUser);
};

const hasValue = (val) => {
  if (Array.isArray(val)) {
    return !!val.length;
  }
  if (typeof val === 'object' && val !== null) {
    return !!Object.keys(val).length;
  }
  return !!val;
};

const roundDownToPrecision = ({ amount, numberFractionalDigits }) => {
  const [integerAmount, decimalAmount = '00'] = amount.toString().split('.'); // using decimal separator of '.' as we will always have this format from SOA before localization is applied
  const precisionDecimalAmount = decimalAmount.substring(0, numberFractionalDigits);
  const roundedAmount = `${integerAmount}.${precisionDecimalAmount}`;
  return roundedAmount;
};

const formatCurrency = (amount, locale = 'en-US', currencyData = { currencyCodeAlpha: 'USD' }) => {
  const {
    currencyCodeAlpha = 'USD',
    numberFractionalDigits = 2,
  } = currencyData;

  const roundedAmount = roundDownToPrecision({ amount, numberFractionalDigits });

  const formattedCurrency = new Intl.NumberFormat(locale, { currency: currencyCodeAlpha, style: 'currency' }).format(roundedAmount);

  return formattedCurrency;
};

const sortHelper = (key, options = {}) => (a, b) => {
  let valA = a[key];
  let valB = b[key];

  const {
    isNumeric = false, codes = [], codesKey, translationParent,
  } = options;

  if (isNumeric) {
    return valA - valB;
  }

  if (codes && codesKey && translationParent) {
    valA = translateFromCodes(codes, codesKey, translationParent, valA);
    valB = translateFromCodes(codes, codesKey, translationParent, valB);
  }

  if (valA < valB) {
    return -1;
  }
  if (valA > valB) {
    return 1;
  }
  return 0;
};

const formatDate = (value, locale) => new Date(value)?.toLocaleString(locale) || '';

const formatCardOrderingStoreList = (resultList) => resultList?.map(store => {
  const {
    storeInfo: {
      carrier, carrierService, cimsStoreId,
      merchCurrencyCode, perOrderFee, storeNumber,
      storeId,
    },
    addresses: { shipping = {} } = {},
  } = store;

  const { storeName, ...shippingRest } = shipping;

  return {
    carrier,
    carrierService,
    label: `${storeNumber} - ${storeName}`,
    merchCurrencyCode,
    perOrderFee,
    shippingAddress: shippingRest,
    storeId,
    value: cimsStoreId,
  };
})?.sort(sortHelper('label'));

export {
  buildCardOrderingStoreListPayload,
  hasValue,
  formatCurrency,
  formatCardOrderingStoreList,
  formatDate,
  sortHelper,
};
