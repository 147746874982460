import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import '../../styles/scss/ModalForm.scss';
import { useTranslation } from 'react-i18next';

Modal.setAppElement('#root');

const ModalForm = ({
  children,
  cancelAction,
  confirmAction,
  modalCancelText,
  modalConfirmText,
  disabled,
  modalTitle,
  visible,
  noFooter,
  id,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleClick = async () => {
    setIsSubmitting(true);
    await confirmAction();
    setIsSubmitting(false);
  };

  return (
    <Modal
      id={id}
      className='modal'
      dialogClassName='modal-dialog-centered'
      isOpen={visible}
      onClickBackdrop={cancelAction}
      overlayClassName='modal-overlay'
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
    >
      <div className='modal-header'>
        <h5 className='modal-title'>{modalTitle}</h5>
        {!confirmAction && (
          <div className='modal-close'>
            <i
              aria-hidden='true'
              className='fa fa-window-close fa-xl'
              onClick={cancelAction}
            />
          </div>
        )}
      </div>
      <div className='modal-body'>
        {children}
      </div>
      {!noFooter && (
        <div className='modal-footer'>
          {cancelAction && (
            <button type='button' className='btn btn-secondary' onClick={cancelAction}>
              {modalCancelText || t('buttons.cancel')}
            </button>
          )}
          <button type='submit' className='btn btn-primary' onClick={handleClick} disabled={disabled || isSubmitting}>
            {modalConfirmText || t('buttons.submit')}
          </button>
        </div>
      )}
    </Modal>
  );
};
ModalForm.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  children: PropTypes.element,
  confirmAction: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  modalCancelText: PropTypes.string,
  modalConfirmText: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  noFooter: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

ModalForm.defaultProps = {
  children: null,
  confirmAction: undefined,
  disabled: false,
  id: undefined,
  modalCancelText: 'Cancel',
  modalConfirmText: 'Confirm',
  noFooter: false,
};

export default ModalForm;
