import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import Error from '../Error/Error';
import Dashboard from '../Dashboard/Dashboard';
import StoreDetails from '../Stores/StoreDetails';
import UserProfile from '../UserProfile/UserProfile';
import UserManagement from '../AccessManagement/UserManagement';
import Reporting from '../Reporting/Reporting';
import ManageUser from '../AccessManagement/ManageUser';
import CreateUser from '../AccessManagement/CreateUser';
import { GlobalContext } from '../../context/Global/GlobalContext';
import RequireAuthentication from '../../components/RequireAuthentication/RequireAuthentication';
import CardOrderEntry from '../CardOrdering/CardOrderEntry';
import CardOrderCheckout from '../CardOrdering/CardOrderCheckout';
import UserSelection from '../Emulation/UserSelection';

const SiteRoutes = () => {
  const {
    userPrivileges,
    isAdmin,
    emulationMode: {
      read: emulationEnabled = false,
    },
    emulatedUser = null,
  } = useContext(GlobalContext);

  const emulatingAdmin = emulationEnabled && emulatedUser?.isAdmin;
  const adminAccess = isAdmin || emulatingAdmin;

  const hasReporting = adminAccess || !!userPrivileges?.length;

  const CARD_ORDER_ENTRY_PRIVILEGE = 'CARD_ORDERING_PLACE_ORDERS';
  const hasCardOrderingPrivilege = userPrivileges?.some(({
    privileges,
  }) => privileges?.includes(CARD_ORDER_ENTRY_PRIVILEGE));

  const canOrderCards = adminAccess || hasCardOrderingPrivilege;

  return (
    <Routes>
      <Route element={<RequireAuthentication authCondition={isAdmin} />}>
        <Route
          element={<UserManagement />}
          path='/access/users'
        />
        <Route
          element={<ManageUser />}
          path='/access/users/manage'
        />
        <Route
          element={<CreateUser />}
          path='/access/users/create'
        />
      </Route>
      <Route element={<RequireAuthentication authCondition={!emulationEnabled} />}>
        <Route
          element={<UserProfile />}
          path='/profile'
        />
      </Route>
      <Route element={<RequireAuthentication authCondition={canOrderCards} />}>
        <Route
          element={(
            <CardOrderEntry />
          )}
          path='/card-ordering/entry'
        />
        <Route
          element={(
            <CardOrderCheckout />
          )}
          path='/card-ordering/checkout'
        />
      </Route>
      <Route element={<RequireAuthentication authCondition={hasReporting} />}>
        <Route
          element={<Reporting />}
          path='/reporting'
        />
      </Route>
      <Route
        element={<StoreDetails />}
        path='/store'
      />
      <Route element={<RequireAuthentication authCondition={emulationEnabled} />}>
        <Route
          element={<UserSelection />}
          path='/emulation'
        />
      </Route>
      <Route
        element={<Dashboard />}
        path='/'
      />
      <Route
        element={<Error />}
        path='*'
      />
    </Routes>
  );
};

export default SiteRoutes;
