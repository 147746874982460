import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import FranchiseManagement from './FranchiseManagement';
import { AuthenticationProvider } from './context/Authentication/AuthenticationProvider';
import { GlobalProvider } from './context/Global/GlobalContext';
import SSOProvider from './context/Authentication/SSOProvider';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SSOProvider>
    <AuthenticationProvider>
      <BrowserRouter>
        <GlobalProvider>
          <FranchiseManagement />
        </GlobalProvider>
      </BrowserRouter>
    </AuthenticationProvider>
  </SSOProvider>,
);
