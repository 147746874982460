import React, {
  createContext, useMemo, useReducer, useEffect, useRef, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GlobalReducer from './GlobalReducer';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification } from '../../components/Notification/Notify';

const storedContext = sessionStorage.getItem('GlobalContext');

const initialState = storedContext ? JSON.parse(storedContext) : {
  clientTheme: '',
  codes: {},
  emulatedUser: null,
  emulationMode: {
    read: false,
    write: false,
  },
  isAdmin: false,
  isAdminCreatedUser: false,
  notifications: {},
  sessionData: {},
  showRenderCounters: false,
  sidebarToggled: true,
  userLoggedIn: false,
  userPrivileges: [],
};

const {
  REACT_APP_ADMIN_GROUP,
  REACT_APP_USER_GROUP,
  REACT_APP_READ_ONLY_FA,
  REACT_APP_READ_WRITE_FA,
} = process.env;

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialState);
  const { authenticated, callAPI } = useAuthentication();
  const initialDataCalled = useRef(false);
  const userPrivilegesCalled = useRef(false);
  const { t } = useTranslation();

  const { sessionData: { groupId = '', formAction }, emulatedUser } = state;

  const setIsAdmin = (isAdmin) => {
    dispatch({
      payload: { isAdmin },
      type: 'SET_IS_ADMIN',
    });
  };

  const setIsAdminCreatedUser = (isAdminCreatedUser) => {
    dispatch({
      payload: { isAdminCreatedUser },
      type: 'SET_IS_ADMIN_CREATED_USER',
    });
  };

  const isAdmin = groupId === REACT_APP_ADMIN_GROUP;
  useEffect(() => setIsAdmin(isAdmin), [isAdmin]);

  const isAdminCreatedUser = groupId === REACT_APP_USER_GROUP;
  useEffect(() => setIsAdminCreatedUser(isAdminCreatedUser), [isAdminCreatedUser]);

  const setEmulationMode = (emulationMode) => {
    dispatch({
      payload: { emulationMode },
      type: 'SET_EMULATION_MODE',
    });
  };

  const setEmulatedUser = (newEmulatedUser) => {
    dispatch({
      payload: { emulatedUser: newEmulatedUser },
      type: 'SET_EMULATED_USER',
    });
  };

  const faDescs = formAction?.map(({ formActionDesc }) => formActionDesc) || [];
  const readWrite = faDescs.includes(REACT_APP_READ_WRITE_FA);
  const readOnly = faDescs.includes(REACT_APP_READ_ONLY_FA);
  const emulationModeEnabled = readWrite || readOnly;
  useEffect(() => {
    setEmulationMode({
      read: emulationModeEnabled,
      write: readWrite,
    });
    if (!emulationModeEnabled) {
      setEmulatedUser(null);
    }
  }, [formAction, readWrite, emulationModeEnabled]);

  const setSession = (sessionData) => {
    dispatch({
      payload: { sessionData },
      type: 'SET_SESSION',
    });
  };

  useEffect(() => {
    const getCodes = async () => {
      const businessLogicTypeList = [
        'COUNTRYID', 'US_STATES', 'CA_PROVINCES', 'ACH_ACCT_TYPE', 'CARRIERID',
        'CARRIER_SERVICE_FEDERAL EXPRESS', 'CARRIER_SERVICE_UPS', 'CARRIER_SERVICE_GENERAL_LOGISTICS_SYSTEMS',
        'CARRIER_SERVICE_DX2', 'CARRIER_SERVICE_HERMES', 'CARRIER_SERVICE_USF_HOLLAND', 'CARRIER_SERVICE_PRIMELINE',
        'CARRIER_SERVICE_ROADWAY', 'CARRIER_SERVICE_TNT', 'ORDER_TYPE', 'ORDER_STATUS',
      ];

      try {
        const { data: codes, status, statusText } = await callAPI('/api/portal-lib/getCodes', { businessLogicTypeList }, 'post');
        if (status === 200) {
          dispatch({
            payload: { codes },
            type: 'SET_CODES',
          });
        } else {
          throw Error(`${status} ${statusText}`);
        }
      } catch ({ message }) {
        displayErrorNotification(t('site.error', { message }));
      }
    };

    const getSessionData = async () => {
      const keys = [
        'userId', 'groupId', 'locale', 'signonId', 'userName',
        'groupInternal', 'hasMFAKey', 'inMFAGroup', 'formAction',
      ];

      try {
        const { data: sessionData, status, statusText } = await callAPI('/api/main/getSessionData', { keys }, 'post');
        if (status === 200) {
          setSession(sessionData);
        } else {
          throw Error(`${status} ${statusText}`);
        }
      } catch ({ message }) {
        displayErrorNotification(t('site.error', { message }));
      }
    };

    if (authenticated && !initialDataCalled.current) {
      initialDataCalled.current = true;
      getCodes();
      getSessionData();
    }
  }, [authenticated, callAPI, t]);

  const setUserPrivileges = (userPrivileges) => {
    dispatch({
      payload: { userPrivileges },
      type: 'SET_USER_PRIVILEGES',
    });
  };

  const getUserPrivileges = useCallback(async (payload) => {
    try {
      const { data, status, statusText } = await callAPI('/api/main/getUserPrivileges', payload, 'post');
      if (status === 200) {
        const { resultList: userPrivileges } = data;
        setUserPrivileges(userPrivileges);
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      displayErrorNotification(t('site.error', { message }));
    }
  }, [callAPI, t]);

  useEffect(() => {
    if ((groupId && !isAdmin && !emulationModeEnabled && authenticated && !userPrivilegesCalled.current)) {
      userPrivilegesCalled.current = true;
      getUserPrivileges({});
    }
  }, [callAPI, groupId, isAdmin, authenticated, emulationModeEnabled, emulatedUser, getUserPrivileges, t]);

  const setNotifications = (notifications) => {
    dispatch({
      payload: { notifications },
      type: 'SET_NOTIFICATIONS',
    });
  };

  const setSidebarToggled = (sidebarToggled) => {
    dispatch({
      payload: { sidebarToggled },
      type: 'SET_SIDEBAR_TOGGLED',
    });
  };

  const setUserLoggedIn = (userLoggedIn) => {
    dispatch({
      payload: { userLoggedIn },
      type: 'SET_USER_LOGGED_IN',
    });
  };

  const providerValue = useMemo(() => ({
    clientTheme: state.clientTheme,
    codes: state.codes,
    emulatedUser: state.emulatedUser,
    emulationMode: state.emulationMode,
    getUserPrivileges,
    isAdmin: state.isAdmin,
    isAdminCreatedUser: state.isAdminCreatedUser,
    notifications: state.notifications,
    sessionData: state.sessionData,
    setEmulatedUser,
    setEmulationMode,
    setIsAdmin,
    setNotifications,
    setSession,
    setSidebarToggled,
    setUserLoggedIn,
    setUserPrivileges,
    sidebarToggled: state.sidebarToggled,
    userLoggedIn: state.userLoggedIn,
    userPrivileges: state.userPrivileges,
  }), [state, getUserPrivileges]);

  useEffect(() => {
    sessionStorage.setItem('GlobalContext', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    if (!authenticated) {
      setUserLoggedIn(false);

      const codes = {};
      dispatch({
        payload: { codes },
        type: 'SET_CODES',
      });

      setSession({});
      setUserPrivileges([]);

      initialDataCalled.current = false;
      userPrivilegesCalled.current = false;
    } else {
      setUserLoggedIn(true);
    }
  }, [authenticated]);

  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
    </GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
