/**
* Downloads a CSV file to the user's computer
* @param {string} reportData - CSV table data
* @param {string} reportName - the name of the report used in the file name
* @param {string} date - the date that the report was generated
* @param {string} storeList - the list of stores the report was generated for
* @param {string} csvSummary - the totals that will be added to the top of the report
*/
const downloadReport = (reportData, reportName, date, storeList, csvSummary) => {
  // prepend UTF-8 BOM to the beginning of the data so that Excel recognises French characters.
  // See https://stackoverflow.com/a/41363077/11928960
  const blob = new Blob(
    [
      new Uint8Array([0xEF, 0xBB, 0xBF]),
      `"${storeList}"\n`,
      csvSummary,
      reportData,
    ],
    { type: 'text/csv;charset=utf-8' },
  );
  const blobUrl = window.URL.createObjectURL(blob);
  const save = document.createElement('a');
  const formattedDate = date.replaceAll('-', '');
  save.download = 'newreport';
  if (typeof save.download !== 'undefined') {
    save.href = blobUrl;
    save.target = '_blank';
    save.download = `${reportName}_${formattedDate}`;
    save.dispatchEvent(new MouseEvent('click'));
  } else {
    window.location.href = blobUrl;
  }
  window.URL.revokeObjectURL(blobUrl);
};

/**
 * escapes new lines and quotes in react table
 * @param {*} cell - a cell in the react table
 * @returns a string with all values escaped properly
 */
const escapeCsvCell = (cell) => {
  if (cell == null) {
    return '';
  }
  const sc = cell.toString();
  if (sc === '' || sc === '""') {
    return sc;
  }
  if (
    sc.includes('"')
    || sc.includes(',')
    || sc.includes('\n')
    || sc.includes('\r')
  ) {
    return `"${sc.replace(/"/g, '""')}"`;
  }
  return sc;
};

/**
 * Converts the table data to CSV, escaping values in the process
 * @param {*} columns - the column names in the order they should be exported
 * @param {*} exportableData - the corresponding data
 * @returns {string} - csv formatted table data
 */
const makeCsvData = (columns, exportableData) => exportableData.reduce((csvString, rowItem) => (
  `${csvString
  + columns
    .map(({ accessor }) => escapeCsvCell(accessor(rowItem)))
    .join(',')
  }\r\n`
), `${columns.map(({ name }) => escapeCsvCell(name)).join(',')}\r\n`);

module.exports = {
  downloadReport,
  makeCsvData,
};
