export default [
  'form.login.mfa.securityQuestions.questions.1',
  'form.login.mfa.securityQuestions.questions.2',
  'form.login.mfa.securityQuestions.questions.3',
  'form.login.mfa.securityQuestions.questions.4',
  'form.login.mfa.securityQuestions.questions.5',
  'form.login.mfa.securityQuestions.questions.6',
  'form.login.mfa.securityQuestions.questions.7',
  'form.login.mfa.securityQuestions.questions.8',
  'form.login.mfa.securityQuestions.questions.9',
  'form.login.mfa.securityQuestions.questions.10',
  'form.login.mfa.securityQuestions.questions.11',
  'form.login.mfa.securityQuestions.questions.12',
  'form.login.mfa.securityQuestions.questions.13',
  'form.login.mfa.securityQuestions.questions.14',
  'form.login.mfa.securityQuestions.questions.15',
  'form.login.mfa.securityQuestions.questions.16',
  'form.login.mfa.securityQuestions.questions.17',
  'form.login.mfa.securityQuestions.questions.18',
  'form.login.mfa.securityQuestions.questions.19',
  'form.login.mfa.securityQuestions.questions.20',
  'form.login.mfa.securityQuestions.questions.21',
  'form.login.mfa.securityQuestions.questions.22',
  'form.login.mfa.securityQuestions.questions.23',
  'form.login.mfa.securityQuestions.questions.24',
  'form.login.mfa.securityQuestions.questions.25',
  'form.login.mfa.securityQuestions.questions.26',
  'form.login.mfa.securityQuestions.questions.27',
  'form.login.mfa.securityQuestions.questions.28',
  'form.login.mfa.securityQuestions.questions.29',
  'form.login.mfa.securityQuestions.questions.30',
  'form.login.mfa.securityQuestions.questions.31',
  'form.login.mfa.securityQuestions.questions.32',
  'form.login.mfa.securityQuestions.questions.33',
  'form.login.mfa.securityQuestions.questions.34',
  'form.login.mfa.securityQuestions.questions.35',
];
