const buildPayloadWithEmulation = (originalPayload, emulationModeEnabled, emulatedUser) => {
  const payload = { ...originalPayload };
  if (emulationModeEnabled && emulatedUser) {
    const { userId, groupId } = emulatedUser;
    payload.oboUser = {
      groupId,
      userId,
    };
  }
  return payload;
};

export default buildPayloadWithEmulation;
