import React, {
  useState, useCallback, useEffect, useContext, useRef,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserFields from './UserFields';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import FormToggleSwitch from '../../components/Form/FormToggleSwitch';
import ModalForm from '../../components/ModalForm/ModalForm';
import useAuthentication from '../../hooks/useAuthentication';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import { GlobalContext } from '../../context/Global/GlobalContext';

const ManageUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const initialDataCalled = useRef(false);

  const retrieveUserFromStorage = () => {
    const serializedUser = sessionStorage.getItem('userToEdit');
    return serializedUser ? JSON.parse(serializedUser) : null;
  };

  const {
    sessionData: { groupId: loggedInGroupId },
  } = useContext(GlobalContext);

  const [user, setUser] = useState(retrieveUserFromStorage() || location?.state?.user || {});

  const userPopulated = !!Object.keys(user).length;

  const {
    details: {
      groupId,
      signonId = '',
      userId,
      userName,
    } = {},
  } = user;

  const ADMIN_GROUP = 'FMADMIN';
  const userIsAdmin = groupId === ADMIN_GROUP;
  const loggedInUserIsAdmin = loggedInGroupId === ADMIN_GROUP;
  const readOnly = userIsAdmin || !loggedInUserIsAdmin;

  useEffect(() => {
    if (userPopulated) {
      sessionStorage.setItem('userToEdit', JSON.stringify(user));
    } else {
      navigate('/access/users');
    }
  }, [navigate, user, userPopulated]);

  const [deactivateUserModalVisible, setDeactivateUserModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const methods = useForm({ defaultValues: user });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: {
      isDirty, dirtyFields, isSubmitting,
    },
  } = methods;

  const getStorePrivileges = useCallback(async () => {
    try {
      const { data, status, statusText } = await callAPI('/api/accessManagement/getStoreUserInfo', { groupId, userId }, 'post');

      if (status === 200) {
        const { resultList: storesList } = data;
        setUser(prevUser => ({
          ...prevUser,
          stores: storesList,
        }));
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      displayErrorNotification(t('site.error', { message }));
    } finally {
      setIsLoading(false);
    }
  }, [callAPI, userId, groupId, t]);

  useEffect(() => {
    if (userPopulated && !readOnly && !initialDataCalled.current) {
      initialDataCalled.current = true;
      getStorePrivileges();
    }
  }, [getStorePrivileges, readOnly, userPopulated]);

  useEffect(() => reset(user), [user, reset]);

  const { stores: formStores } = getValues();

  const handleToggleChange = (index, privilegeNames) => (newValue) => {
    if (!newValue) {
      privilegeNames.forEach(privName => setValue(`stores[${index}].privileges.${privName}`, false, { shouldDirty: true }));
    }
  };

  const generateToggleSwitch = (storeNumber, storeId, index) => {
    const { privileges, enabled: storeEnabled } = formStores[index];
    const privilegeNames = Object.keys(privileges);
    return (
      <div className='store-assignment' key={storeId}>
        <h4 className={storeEnabled ? 'enabled' : ''}>
          <span>{storeNumber}</span>
          <FormToggleSwitch
            fieldName={`stores[${index}].enabled`}
            handleChange={handleToggleChange(index, privilegeNames)}
            verticalAlign='middle'
            wrapperClassName='storeToggle'
          />
        </h4>
        <div className={`privilege-toggles ${storeEnabled ? ' enabled' : ''}`}>
          {storeEnabled && privilegeNames.map(privName => (
            <FormToggleSwitch
              key={privName}
              topLabel={t(`roles.privileges.${privName}`)}
              fieldName={`stores[${index}].privileges.${privName}`}
              verticalAlign='middle'
              wrapperClassName='privilegeToggle'
            />
          ))}
        </div>
      </div>
    );
  };

  const editUserDetail = async (payload) => {
    const response = await callAPI('/api/accessManagement/editUserDetail', payload, 'post');
    const { status, statusText } = response;
    if (status !== 200) {
      throw Error(`${status} ${statusText}`);
    }
    return response;
  };

  const assignStoreRoles = async (stores) => {
    const payload = {
      isNewUser: false,
      refreshForStores: false,
      refreshForUsers: true,
      storeList: stores.filter(({ enabled }) => !!enabled).map(({
        store: {
          merchant, division, storeNumber,
        },
        privileges,
      }) => ({
        division,
        merchant,
        privileges: Object.keys(privileges).filter(priv => !!privileges[priv]),
        storeNumber,
      })),
      userList: [{
        groupId,
        privileges: null,
        userId,
      }],
    };

    const response = await callAPI('/api/accessManagement/assignUsersToStores', payload, 'post');
    const { status, statusText } = response;
    if (status !== 200) {
      throw Error(`${status} ${statusText}`);
    }
    return response;
  };

  const updateUser = async (data) => {
    let userDetailsError = false;
    let storeRolesError = false;
    const fieldsChanged = Object.keys(dirtyFields);
    const userDetailsChanged = fieldsChanged.includes('details');
    const storeRolesChanged = fieldsChanged.includes('stores');

    try {
      if (userDetailsChanged) {
        const { details } = data;
        await editUserDetail(details);
      }
    } catch ({ message }) {
      userDetailsError = true;
      displayErrorNotification(`${t('users.failedToUpdateUserDetails')}: ${message}`);
    }

    try {
      if (storeRolesChanged) {
        const { stores } = data;
        await assignStoreRoles(stores);
      }
    } catch ({ message }) {
      storeRolesError = true;
      displayErrorNotification(`${t('users.failedToUpdateStoreAssignments')}: ${message}`);
    }

    if (!storeRolesError && !userDetailsError) {
      setUser(data);
      displaySuccessNotification(t('users.userUpdated'));
    } else if (userDetailsChanged && !userDetailsError && storeRolesError) {
      setUser({
        ...user,
        details: data.details,
      });
    } else if (storeRolesChanged && !storeRolesError && userDetailsError) {
      setUser({
        ...user,
        stores: data.stores,
      });
    }
  };

  const onSubmit = async (data) => {
    const { stores } = data;
    const storesEnabled = stores.some(({ enabled }) => !!enabled);
    if (!storesEnabled) {
      displayErrorNotification(t('users.atLeastOneStoreMustBeEnabled'));
    } else {
      await updateUser(data);
    }
  };

  const dismissModal = useCallback(() => setDeactivateUserModalVisible(false), []);
  const confirmModal = useCallback(() => {
    setDeactivateUserModalVisible(false);
    displaySuccessNotification(t('users.userDeactivated', { name: userName }));
    navigate('/users');
  }, [navigate, t, userName]);
  const clickReset = () => reset(user);

  return (
    <div className='container access-management' id='manage-user'>
      <ModalForm
        cancelAction={dismissModal}
        confirmAction={confirmModal}
        modalCancelText={t('buttons.cancel')}
        modalConfirmText={t('buttons.confirm')}
        modalTitle={t('users.deactivateUser')}
        visible={deactivateUserModalVisible}
      >
        <p>{t('users.confirmUserDeactivation', { name: userName })}</p>
      </ModalForm>
      <div className='row g-2 mb-2'>
        <div className='col-12'>
          <h1>{t(readOnly ? 'users.viewUser' : 'users.manageUser')}</h1>
        </div>
      </div>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? elastic : (
            <>
              <UserFields signonId={signonId} />
              { !readOnly && (
                <div className='row field-group'>
                  <div className='col-12'>
                    <h6>{t('users.storeLevelAccessRoles')}</h6>
                  </div>
                  <div className='stores-privileges'>
                    {formStores?.map(({
                      store: { storeNumber }, storeId,
                    }, index) => generateToggleSwitch(storeNumber, storeId, index))}
                  </div>
                </div>
              )}
              {!readOnly && (
                <div className='action-buttons'>
                  <button className='btn' type='submit' disabled={!isDirty || isSubmitting}>{t('buttons.update')}</button>
                  <button className='btn button-link' type='button' disabled={!isDirty || isSubmitting} onClick={clickReset}>{t('buttons.reset')}</button>
                </div>
              )}
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default ManageUser;
