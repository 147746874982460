import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Image = ({
  src, alt, fallback, className,
}) => {
  const [errored, setErrored] = useState(false);

  const onError = () => {
    setErrored(true);
  };

  return (
    <img
      className={className}
      alt={alt}
      src={(errored && fallback) || src}
      onError={onError}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  fallback: PropTypes.string,
  src: PropTypes.string.isRequired,
};

Image.defaultProps = {
  className: '',
  fallback: undefined,
};

export default Image;
