import {
  React, useCallback, useContext, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@table-library/react-table-library/sort';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const TransactionsByStore = ({
  rpt590Data,
  date,
}) => {
  const { t, i18n } = useTranslation();

  const storeList = storeListBuilder(rpt590Data, 'STORE');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);

  const reportData = rpt590Data;
  const data = useMemo(() => ({
    nodes: selectedStores.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.STORE))
      : reportData,
  }), [reportData, selectedStores]);

  const [totalCount, setTotalCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const computeTotalCount = nodes
        .map(item => parseInt(item.TRANS_COUNT, 10))
        .reduce((accumulator, current) => accumulator + current);
      setTotalCount(computeTotalCount);
      const computeTotalAmount = nodes
        .map(item => parseFloat(item.TRANS_AMOUNT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalAmount(computeTotalAmount);
    } else {
      setTotalCount(0);
      setTotalAmount(0);
    }
  }, [data]);
  /**
  * defines two decimal places for parseFloat functions
  */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;
  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        ACTIVITY_DATE: (array) => [...array].sort((a, b) => dateCompare(a.ACTIVITY_DATE, b.ACTIVITY_DATE)),
        CLIENT_CODE: (array) => [...array].sort((a, b) => a.CLIENT_CODE.localeCompare(b.CLIENT_CODE)),
        CREDIT_DEBIT_IND: (array) => [...array].sort((a, b) => a.CREDIT_DEBIT_IND.localeCompare(b.CREDIT_DEBIT_IND)),
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        STORE: (array) => [...array].sort((a, b) => intCompare(a.STORE, b.STORE)),
        TRANS_AMOUNT: (array) => [...array].sort((a, b) => floatCompare(a.TRANS_AMOUNT, b.TRANS_AMOUNT)),
        TRANS_CODE: (array) => [...array].sort((a, b) => intCompare(a.TRANS_CODE, b.TRANS_CODE)),
        TRANS_COUNT: (array) => [...array].sort((a, b) => intCompare(a.TRANS_COUNT, b.TRANS_COUNT)),
        TRANSACTION_DESCRIPTION: (array) => [...array].sort((a, b) => a['TRANSACTION DESCRIPTION'].localeCompare(b['TRANSACTION DESCRIPTION'])),
      },
    },
  );
  /**
 * The columns and their corresponding data that will render in the table.
 */
  const COLUMNS = [
    { label: t('reporting.uiElements.clientCode'), renderCell: (item) => item.CLIENT_CODE, sort: { sortKey: 'CLIENT_CODE' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.storeNumber'), renderCell: (item) => item.STORE.padStart(10, '0'), sort: { sortKey: 'STORE' } },
    { label: t('reporting.settlementSummary.activityDate'), renderCell: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'ACTIVITY_DATE' } },
    { label: t('reporting.hostReconciliationData.transactionCode'), renderCell: (item) => item.TRANS_CODE, sort: { sortKey: 'TRANS_CODE' } },
    { label: t('reporting.hostReconciliationData.description'), renderCell: (item) => item['TRANSACTION DESCRIPTION'], sort: { sortKey: 'TRANSACTION_DESCRIPTION' } },
    { label: t('reporting.uiElements.creditDebit'), renderCell: (item) => item.CREDIT_DEBIT_IND, sort: { sortKey: 'CREDIT_DEBIT_IND' } },
    { label: t('reporting.transactionsByStore.count'), renderCell: (item) => item.TRANS_COUNT, sort: { sortKey: 'TRANS_COUNT' } },
    { label: t('reporting.redemptions.amount'), renderCell: (item) => parseFloat(item.TRANS_AMOUNT).toLocaleString(convertedLocale, options), sort: { sortKey: 'TRANS_AMOUNT' } },
  ];

  const exportableColumns = [
    { accessor: (item) => item.CLIENT_CODE, name: t('reporting.uiElements.clientCode') },
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.STORE.padStart(10, '0')}\t`, name: t('reporting.uiElements.storeNumber') },
    { accessor: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), name: t('reporting.settlementSummary.activityDate') },
    { accessor: (item) => item.TRANS_CODE, name: t('reporting.hostReconciliationData.transactionCode') },
    { accessor: (item) => item['TRANSACTION DESCRIPTION'], name: t('reporting.hostReconciliationData.description') },
    { accessor: (item) => item.CREDIT_DEBIT_IND, name: t('reporting.uiElements.creditDebit') },
    { accessor: (item) => item.TRANS_COUNT, name: t('reporting.transactionsByStore.count') },
    { accessor: (item) => parseFloat(item.TRANS_AMOUNT).toLocaleString(convertedLocale, options), name: t('reporting.redemptions.amount') },
  ];

  const csvSummary = [
    `"${t('reporting.transactionsByStore.totalCount')}: ${parseFloat(totalCount).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.transactionsByStore.totalAmount')}: ${parseFloat(totalAmount).toLocaleString(convertedLocale, options)}"\n`,
  ];

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <header>
        <h3>{t('reporting.transactionsByStore.storeAndTransactionSummary')}</h3>
      </header>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.transactionsByStore.totalCount')}: `}
              </span>
              <span>
                {parseFloat(totalCount).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.transactionsByStore.totalAmount')}: `}
              </span>
              <span>
                {parseFloat(totalAmount).toLocaleString(convertedLocale, options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        reportName='StoreActivity'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />
    </>
  );
};

TransactionsByStore.propTypes = {
  date: PropTypes.string.isRequired,
  rpt590Data: PropTypes.arrayOf(
    PropTypes.shape({
      ACTIVITY_DATE: PropTypes.string,
      CLIENT_CODE: PropTypes.string,
      CREDIT_DEBIT_IND: PropTypes.string,
      DIVISION: PropTypes.string,
      MERCHANT: PropTypes.string,
      STORE: PropTypes.string,
      TRANS_AMOUNT: PropTypes.string,
      TRANS_CODE: PropTypes.string,
      TRANS_COUNT: PropTypes.string,
      TRANSACTION_DESCRIPTION: PropTypes.string,
    }),
  ).isRequired,
};

export default TransactionsByStore;
