import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  'en-CA': {
    translation: await import('./locales/en-CA/translation.json').then((module) => module.default),
  },
  'en-US': {
    translation: await import('./locales/en-US/translation.json').then((module) => module.default),
  },
  'fr-CA': {
    translation: await import('./locales/fr-CA/translation.json').then((module) => module.default),
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
