import {
  displayInfoNotification,
  displaySuccessNotification,
} from '../Notification/Notify';

const validate = (quantity, maxPacks) => {
  const newErrors = [];
  if (quantity > maxPacks) newErrors.push('ABOVE_AVAIL_QTY');
  if (quantity < 0) newErrors.push('INVALID_QTY');
  return newErrors;
};

export const addToCart = ({
  cart, label, message, productId, quantity, setCart,
}) => {
  const cartWithNewProduct = [...cart];
  cartWithNewProduct.push({
    label,
    productId,
    quantityInCart: quantity,
  });
  setCart(cartWithNewProduct);
  displaySuccessNotification(message);
};

export const removeFromCart = ({
  cart, message, productId, setCart, setQuantity,
}) => {
  const cartWithoutProduct = cart.filter(({ productId: cartProductId }) => productId !== cartProductId);
  setCart(cartWithoutProduct);
  setQuantity(0);
  displayInfoNotification(message);
};

export const updateCart = ({
  cart, cartIndex, message, quantity, setCart,
}) => {
  const updatedCart = [...cart];
  updatedCart[cartIndex].quantityInCart = quantity;
  displayInfoNotification(message);
  setCart(updatedCart);
};

export const initializeCart = ({
  cart,
  cartIndex,
  inCart,
  specifiedQuantity,
}) => {
  const valueSet = specifiedQuantity !== undefined;

  if (inCart) {
    const cartQuantity = cart[cartIndex].quantityInCart;
    return {
      cartButtonClassName: '',
      cartButtonTextKey: 'updateCart',
      initialQuantity: valueSet ? specifiedQuantity : cartQuantity,
      quantityInCart: cartQuantity,
    };
  }
  return {
    cartButtonClassName: ' add-to-cart',
    cartButtonTextKey: 'addToCart',
    initialQuantity: valueSet ? specifiedQuantity : 0,
    quantityInCart: null,
  };
};

export const manageErrorList = (
  productId,
  invalidProductsInCart,
  setInvalidProductsInCart,
  belongsInErrorList,
  inErrorList,
) => {
  if (belongsInErrorList && !inErrorList) {
    setInvalidProductsInCart(prevState => [...prevState, productId]);
  } else if (!belongsInErrorList && inErrorList) {
    const newListRemovedProduct = invalidProductsInCart.filter(errProductId => errProductId !== productId);
    setInvalidProductsInCart(newListRemovedProduct);
  }
};

export const parseInput = (event) => {
  const sanitizedInput = Number(event.target.value.replace(/^0+/, '')) || 0;
  event.target.value = sanitizedInput.toString();
  return sanitizedInput;
};

export const processCartEvent = ({
  cart,
  cartIndex,
  inCart,
  label,
  productId,
  quantity,
  setCart,
  setQuantity,
  t,
}) => {
  if (inCart && !quantity) {
    const message = t('cardOrdering.productCard.notifications.removedFromCart', { label, quantity });
    removeFromCart({
      cart, message, productId, setCart, setQuantity,
    });
  } else if (inCart) {
    const message = t('cardOrdering.productCard.notifications.cartUpdated', { label, quantity });
    updateCart({
      cart, cartIndex, message, quantity, setCart,
    });
  } else {
    const message = t('cardOrdering.productCard.notifications.addedToCart', { label, quantity });
    addToCart({
      cart, label, message, productId, quantity, setCart,
    });
  }
};

export const validateAndSetErrors = (quantity, maxPacks, outOfStock, inventoryActive) => {
  if (!outOfStock && inventoryActive) {
    return validate(quantity, maxPacks);
  }
  return [];
};
