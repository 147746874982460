/**
 *
 * @param {Array} obj
 * @param {Array|String} path The path in array or string form to use in checking the passed {@link obj} object
 * @param {*} defaultValue The value to return if there is no result found when parsing the passed {@link obj} object with the {@link path} value
 * @returns {(Array|*)} Either the value found at the {@link path} value within the {@link obj} array or the {@link defaultValue} value passed into the function
 */

const getValueFromPath = (obj, path, defaultValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;

  /* Check if path is string or array by having the regular expression check to see if
     we have period and bracket characters indicative of an array path represented by a string. */
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  // Find value by having the reducer check to see if the previous value exists and returns the
  const result = pathArray.reduce((prevObj, key) => prevObj?.[key], obj);

  return result || defaultValue;
};

module.exports = {
  getValueFromPath,
};
