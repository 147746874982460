import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Image from '../../components/Image/Image';
import ModalForm from '../../components/ModalForm/ModalForm';
import { spin } from '../../components/LoadingAnimations/LoadingAnimations';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { translateFromCodes } from '../../utils/codes';
import { formatDate } from '../../utils/helpers';
import cardPlaceholder from '../CardOrdering/images/svs-giftcard-placeholder.png';

const {
  REACT_APP_CIMS_SKU_IMAGE_PATH,
} = process.env;

const OrderDetails = ({
  data, visible, cancelAction, isLoading,
}) => {
  const { t, i18n: { language: currentLocale = 'en-US' } } = useTranslation();
  const {
    codes = {},
  } = useContext(GlobalContext);

  const {
    orderNumber, orderDate, orderType, storeName, trackingNumber, carrierId,
    carrierService, orderStatus, requestorId, customerPo, shipQty,
    orderQty, orderReturnQty, lineItems,
  } = data;

  const { businessLogicType: carrierBlt } = codes?.CARRIERID?.find(({ value }) => value === carrierId) || {};

  const orderLines = lineItems?.map(({
    dtsSku,
    dtsSkuDescription,
    orderQty: lineOrderQty,
    shipQty: lineShipQty,
    returnQty: lineReturnQty,
  }) => {
    const label = `${dtsSku} - ${dtsSkuDescription}`;

    return (
      <div className='line-details' key={dtsSku}>
        <header>
          {label}
        </header>
        <div className='line-content row'>
          <div className='col-12 col-md-3 card-image'>
            <Image
              className='img-fluid'
              src={`${REACT_APP_CIMS_SKU_IMAGE_PATH}/${dtsSku}.png`}
              alt={label}
              fallback={cardPlaceholder}
            />
          </div>
          <div className='col-12 col-md-9'>
            <div className='row'>
              <div className='col-12 col-md-4'>
                <div className='form-label'>
                  {t('storeDetails.cardOrders.orderQuantity')}
                </div>
                {lineOrderQty}
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-label'>
                  {t('storeDetails.cardOrders.shippedQuantity')}
                </div>
                {lineShipQty}
              </div>
              <div className='col-12 col-md-4'>
                <div className='form-label'>
                  {t('storeDetails.cardOrders.returnQuantity')}
                </div>
                {lineReturnQty}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <ModalForm
      id='order-details'
      cancelAction={cancelAction}
      closeInHeader
      modalTitle={t('storeDetails.cardOrders.orderDetails')}
      noFooter
      visible={visible}
    >
      {isLoading ? spin : (
        <div id='order-details-content'>
          <div className='row'>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.orderNumber')}
              </div>
              {orderNumber}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.orderDate')}
              </div>
              {formatDate(orderDate, currentLocale)}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.orderType')}
              </div>
              {translateFromCodes(codes, 'ORDER_TYPE', 'ORDER_TYPE', orderType)}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.orderStatus')}
              </div>
              {translateFromCodes(codes, 'ORDER_STATUS', 'ORDER_STATUS', orderStatus)}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.editShippingAddressForm.siteName')}
              </div>
              {storeName}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('cardOrdering.orderCheckout.carrier')}
              </div>
              {translateFromCodes(codes, 'CARRIERID', 'CARRIER.CARRIERID', carrierId)}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('cardOrdering.orderCheckout.carrierService')}
              </div>
              {translateFromCodes(codes, `CARRIER_SERVICE_${carrierBlt}`, `CARRIER.CARRIER_SERVICE_${carrierBlt}`, carrierService)}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.trackingNumber')}
              </div>
              {trackingNumber}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.orderQuantity')}
              </div>
              {orderQty}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.shippedQuantity')}
              </div>
              {shipQty}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.returnQuantity')}
              </div>
              {orderReturnQty}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('storeDetails.cardOrders.requestorId')}
              </div>
              {requestorId}
            </div>
            <div className='col-12 col-md-6 col-lg-3'>
              <div className='form-label'>
                {t('cardOrdering.orderCheckout.comment')}
              </div>
              {customerPo}
            </div>
          </div>
          <div className='line-items mt-4'>
            <div className='form-label mb-2'>
              {t('cardOrdering.orderEntry.products')}
            </div>
            {orderLines}
          </div>
        </div>
      )}
    </ModalForm>
  );
};

OrderDetails.propTypes = {
  cancelAction: PropTypes.func.isRequired,
  data: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.objectOf(
          PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        ),
      ),
    ]),
  ),
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
};

OrderDetails.defaultProps = {
  data: {},
  isLoading: false,
  visible: false,
};

export default OrderDetails;
