import {
  React, useCallback, useContext, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSort } from '@table-library/react-table-library/sort';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import moment from 'moment';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const CardShipmentReport = ({
  smgr269Data, date,
}) => {
  const { t, i18n } = useTranslation();

  const storeList = storeListBuilder(smgr269Data, 'STORE');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);
  const reportData = smgr269Data;
  const data = useMemo(() => ({
    nodes: selectedStores.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.STORE))
      : reportData,
  }), [reportData, selectedStores]);

  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const [totalTax, setTotalTax] = useState(0);

  // on data change, compute the totals
  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const computeTotalQuantity = nodes
        .map(item => parseFloat(item.QUANTITY))
        .reduce((accumulator, current) => accumulator + current);
      setTotalQuantity(computeTotalQuantity);
      const computeTotalFee = nodes
        .map(item => parseFloat(item.FEE))
        .reduce((accumulator, current) => accumulator + current);
      setTotalFee(computeTotalFee);
      const computeTotalTax = nodes
        .map(item => parseFloat(item.TAX))
        .reduce((accumulator, current) => accumulator + current);
      setTotalTax(computeTotalTax);
    } else {
      setTotalQuantity(0);
      setTotalFee(0);
      setTotalTax(0);
    }
  }, [data]);

  /**
  * defines two decimal places for parseFloat functions
  */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;

  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        ENTITY_NAME: (array) => [...array].sort((a, b) => a.ENTITY_NAME.localeCompare(b.ENTITY_NAME)),
        FEE: (array) => [...array].sort((a, b) => floatCompare(a.FEE, b.FEE)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        ORDER_NUMBER: (array) => [...array].sort((a, b) => intCompare(a.ORDER_NUMBER, b.ORDER_NUMBER)),
        QUANTITY: (array) => [...array].sort((a, b) => intCompare(a.QUANTITY, b.QUANTITY)),
        RATE: (array) => [...array].sort((a, b) => floatCompare(a.RATE, b.RATE)),
        SHIP_DATE: (array) => [...array].sort((a, b) => dateCompare(a.SHIP_DATE, b.SHIP_DATE)),
        SKU: (array) => [...array].sort((a, b) => intCompare(a.SKU, b.SKU)),
        SKU_DESCRIPTION: (array) => [...array].sort((a, b) => a.SKU_DESCRIPTION.localeCompare(b.SKU_DESCRIPTION)),
        STORE_NUMBER: (array) => [...array].sort((a, b) => intCompare(a.STORE, b.STORE)),
        TAX: (array) => [...array].sort((a, b) => floatCompare(a.TAX, b.TAX)),
        TAX_AREA_ID: (array) => [...array].sort((a, b) => a.TAX_AREA_ID.localeCompare(b.TAX_AREA_ID)),
        TRACKING_NUMBER: (array) => [...array].sort((a, b) => a.TRACKING_NUMBER.localeCompare(b.TRACKING_NUMBER)),
      },
    },
  );
  /**
 * The columns and their corresponding data that will render in the table.
 */
  const COLUMNS = [
    { label: t('reporting.cardShipment.entityName'), renderCell: (item) => item.ENTITY_NAME, sort: { sortKey: 'ENTITY_NAME' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.storeNumber'), renderCell: (item) => item.STORE.padStart(10, '0'), sort: { sortKey: 'STORE_NUMBER' } },
    { label: t('reporting.cardShipment.order'), renderCell: (item) => item.ORDER_NUMBER, sort: { sortKey: 'ORDER_NUMBER' } },
    { label: t('reporting.cardShipment.shipDate'), renderCell: (item) => moment(item.SHIP_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'SHIP_DATE' } },
    { label: t('reporting.cardShipment.trackingNum'), renderCell: (item) => item.TRACKING_NUMBER, sort: { sortKey: 'ENTITY_NAME' } },
    { label: t('reporting.cardShipment.sku'), renderCell: (item) => item.SKU, sort: { sortKey: 'SKU' } },
    { label: t('reporting.cardShipment.skuDescription'), renderCell: (item) => item.SKU_DESCRIPTION, sort: { sortKey: 'SKU_DESCRIPTION' } },
    { label: t('reporting.cardShipment.quantity'), renderCell: (item) => item.QUANTITY, sort: { sortKey: 'QUANTITY' } },
    { label: t('reporting.cardShipment.fee'), renderCell: (item) => parseFloat(item.FEE).toLocaleString(convertedLocale, options), sort: { sortKey: 'FEE' } },
    { label: t('reporting.cardShipment.tax'), renderCell: (item) => parseFloat(item.TAX).toLocaleString(convertedLocale, options), sort: { sortKey: 'TAX' } },
    { label: t('reporting.cardShipment.rate'), renderCell: (item) => parseFloat(item.RATE).toLocaleString(convertedLocale, options), sort: { sortKey: 'RATE' } },
    { label: t('reporting.cardShipment.taxAreaID'), renderCell: (item) => item.TAX_AREA_ID, sort: { sortKey: 'TAX_AREA_ID' } },
  ];

  const exportableColumns = [
    { accessor: (item) => item.ENTITY_NAME, name: t('reporting.cardShipment.entityName') },
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.STORE.padStart(10, '0')}\t`, name: t('reporting.uiElements.storeNumber') },
    { accessor: (item) => item.ORDER_NUMBER, name: t('reporting.cardShipment.order') },
    { accessor: (item) => moment(item.SHIP_DATE).format('MM/DD/YYYY'), name: t('reporting.cardShipment.shipDate') },
    { accessor: (item) => item.TRACKING_NUMBER, name: t('reporting.cardShipment.trackingNum') },
    { accessor: (item) => item.SKU, name: t('reporting.cardShipment.sku') },
    { accessor: (item) => item.SKU_DESCRIPTION, name: t('reporting.cardShipment.skuDescription') },
    { accessor: (item) => item.QUANTITY, name: t('reporting.cardShipment.quantity') },
    { accessor: (item) => parseFloat(item.FEE).toLocaleString(convertedLocale, options), name: t('reporting.cardShipment.fee') },
    { accessor: (item) => parseFloat(item.TAX).toLocaleString(convertedLocale, options), name: t('reporting.cardShipment.tax') },
    { accessor: (item) => parseFloat(item.RATE).toLocaleString(convertedLocale, options), name: t('reporting.cardShipment.rate') },
    { accessor: (item) => item.TAX_AREA_ID, name: t('reporting.cardShipment.taxAreaID') },
  ];

  const csvSummary = [
    `"${t('reporting.cardShipment.totalQuantity')}: ${parseInt(totalQuantity, 10)}"`,
    `\n"${t('reporting.cardShipment.totalFee')}: ${parseFloat(totalFee).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.cardShipment.totalTax')}: ${parseFloat(totalTax).toLocaleString(convertedLocale, options)}"\n`,
  ];
  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.cardShipment.totalQuantity')}: `}
              </span>
              <span>
                {parseInt(totalQuantity, 10)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.cardShipment.totalFee')}: `}
              </span>
              <span>
                {parseFloat(totalFee).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.cardShipment.totalTax')}: `}
              </span>
              <span>
                {parseFloat(totalTax).toLocaleString(convertedLocale, options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        reportName='Shipments'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />
    </>
  );
};

CardShipmentReport.propTypes = {
  date: PropTypes.string.isRequired,
  smgr269Data: PropTypes.arrayOf(
    PropTypes.shape({
      DIVISION: PropTypes.string.isRequired,
      ENTITY_NAME: PropTypes.string.isRequired,
      FEE: PropTypes.string.isRequired,
      MERCHANT: PropTypes.string.isRequired,
      ORDER_NUMBER: PropTypes.string.isRequired,
      QUANTITY: PropTypes.string.isRequired,
      RATE: PropTypes.string.isRequired,
      SHIP_DATE: PropTypes.string.isRequired,
      SKU: PropTypes.string.isRequired,
      SKU_DESCRIPTION: PropTypes.string.isRequired,
      STORE: PropTypes.string.isRequired,
      TAX: PropTypes.string.isRequired,
      TAX_AREA_ID: PropTypes.string.isRequired,
      TRACKING_NUMBER: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CardShipmentReport;
