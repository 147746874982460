import {
  React, useCallback, useContext, useMemo, useEffect, useState,
} from 'react';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import PropTypes from 'prop-types';
import '../../styles/scss/Reporting.scss';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import CollapsibleSection from '../../components/Reporting/CollapsibleSection';
import { makeCsvData, downloadReport } from '../../helpers/csvDownloader';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const SettlementSummary = ({
  smgr201Data,
  date,
}) => {
  const { t, i18n } = useTranslation();
  const storeList = storeListBuilder(smgr201Data, 'DIV_STORE_NUM');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);
  const [totalSettlementAmt, setTotalSettlementAmt] = useState(0);
  const [totalBillingAmt, setTotalBillingAmt] = useState(0);
  const [totalCimsAmt, setTotalCimsAmt] = useState(0);
  const [totalCommissionAmt, setTotalCommissionAmt] = useState(0);
  const [totalShippingAmt, setTotalShippingAmt] = useState(0);
  const [totalDiscountSettlementAmount, setTotalDiscountSettlementAmount] = useState(0);
  const [totalOtherChargesAmount, setTotalOtherChargesAmount] = useState(0);
  const [totalNetAmount, setTotalNetAmount] = useState(0);
  const filteredData = useMemo(() => ({
    nodes: selectedStores?.length > 0
      ? smgr201Data.filter((node) => selectedStores.includes(node.DIV_STORE_NUM))
      : smgr201Data,
  }), [smgr201Data, selectedStores]);
  // compute totals from JSON
  useEffect(() => {
    const {
      nodes = [],
    } = filteredData;
    if (nodes.length > 0) {
      const computeTotalSettlement = nodes
        .map(item => parseFloat(item.SETTLEMENT_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalSettlementAmt(computeTotalSettlement);
      const computeBillingAmount = nodes
        .map(item => parseFloat(item.BILLING_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalBillingAmt(computeBillingAmount);
      const computeCimsAmount = nodes
        .map(item => parseFloat(item.CIMS_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalCimsAmt(computeCimsAmount);
      const computeCommissionAmt = nodes
        .map(item => parseFloat(item.COMMISSION))
        .reduce((accumulator, current) => accumulator + current);
      setTotalCommissionAmt(computeCommissionAmt);
      const computeShippingAmount = nodes
        .map(item => parseFloat(item.SHIPPING_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalShippingAmt(computeShippingAmount);
      const computeDiscountSettlementAmount = nodes
        .map(item => parseFloat(item.DISC_SETL_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalDiscountSettlementAmount(computeDiscountSettlementAmount);
      const computeOtherCharges = nodes
        .map(item => parseFloat(item.OTHER_CHARGES))
        .reduce((accumulator, current) => accumulator + current);
      setTotalOtherChargesAmount(computeOtherCharges);
      const computeTotalNetAmount = nodes
        .map(item => parseFloat(item.NET_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalNetAmount(computeTotalNetAmount);
    } else {
      setTotalSettlementAmt(0);
      setTotalBillingAmt(0);
      setTotalCimsAmt(0);
      setTotalCommissionAmt(0);
      setTotalShippingAmt(0);
      setTotalDiscountSettlementAmount(0);
      setTotalOtherChargesAmount(0);
      setTotalNetAmount(0);
    }
  }, [filteredData]);
  /**
   * Defines column sizes, css styles, and the number of columns
   */
  const theme = useTheme([
    getTheme(),
    {
      Table: `
      --data-table-library_grid-template-columns:  repeat(13, minmax(0, 1fr));
      @media (max-width: 992px) {
          --data-table-library_grid-template-columns:  repeat(13, 1fr);
      `,
    },
  ]);
  /**
   * defines two decimal places for parseFloat functions
   */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;

  /**
   * Sort functions to pass into the expandable sections.
   */
  const sortFns = {
    ACTIVITY_DATE: (array) => [...array].sort((a, b) => dateCompare(a.ACTIVITY_DATE, b.ACTIVITY_DATE)),
    BILLING_AMT: (array) => [...array].sort((a, b) => floatCompare(a.BILLING_AMT, b.BILLING_AMT)),
    CIMS_AMT: (array) => [...array].sort((a, b) => floatCompare(a.CIMS_AMT, b.CIMS_AMT)),
    COMMISSION: (array) => [...array].sort((a, b) => floatCompare(a.COMMISSION, b.COMMISSION)),
    DISC_SETL_AMT: (array) => [...array].sort((a, b) => floatCompare(a.DISC_SETL_AMT, b.DISC_SETL_AMT)),
    DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
    ENTITY_NAME: (array) => [...array].sort((a, b) => a.ENTITY_NAME.localeCompare(b.ENTITY_NAME)),
    MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
    NET_AMT: (array) => [...array].sort((a, b) => floatCompare(a.NET_AMT, b.NET_AMT)),
    OTHER_CHARGES: (array) => [...array].sort((a, b) => floatCompare(a.OTHER_CHARGES, b.OTHER_CHARGES)),
    SETTLEMENT_AMT: (array) => [...array].sort((a, b) => floatCompare(a.SETTLEMENT_AMT, b.SETTLEMENT_AMT)),
    SHIPPING_AMT: (array) => [...array].sort((a, b) => floatCompare(a.SHIPPING_AMT, b.SHIPPING_AMT)),
    STORE: (array) => [...array].sort((a, b) => intCompare(a.DIV_STORE_NUM, b.DIV_STORE_NUM)),
  };

  /**
   * The columns and their corresponding data that will render in the table.
   */
  const COLUMNS = [
    { label: t('reporting.settlementSummary.entityName'), renderCell: (item) => item.ENTITY_NAME, sort: { sortKey: 'ENTITY_NAME' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.store'), renderCell: (item) => item.DIV_STORE_NUM.padStart(10, '0'), sort: { sortKey: 'STORE' } },
    { label: t('reporting.settlementSummary.activityDate'), renderCell: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'ACTIVITY_DATE' } },
    { label: t('reporting.settlementSummary.settlementAmount'), renderCell: (item) => parseFloat(item.SETTLEMENT_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'SETTLEMENT_AMT' } },
    { label: t('reporting.settlementSummary.billingAmount'), renderCell: (item) => parseFloat(item.BILLING_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'BILLING_AMT' } },
    { label: t('reporting.settlementSummary.cimsAmount'), renderCell: (item) => parseFloat(item.CIMS_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'CIMS_AMT' } },
    { label: t('reporting.settlementSummary.shippingAmount'), renderCell: (item) => parseFloat(item.SHIPPING_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'SHIPPING_AMT' } },
    { label: t('reporting.settlementSummary.discountSettlement'), renderCell: (item) => parseFloat(item.DISC_SETL_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'DISC_SETL_AMT' } },
    { label: t('reporting.settlementSummary.otherCharges'), renderCell: (item) => parseFloat(item.OTHER_CHARGES).toLocaleString(convertedLocale, options), sort: { sortKey: 'OTHER_CHARGES' } },
    { label: t('reporting.settlementSummary.commission'), renderCell: (item) => parseFloat(item.COMMISSION).toLocaleString(convertedLocale, options), sort: { sortKey: 'COMMISSION' } },
    { label: t('reporting.settlementSummary.netAmount'), renderCell: (item) => parseFloat(item.NET_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'NET_AMT' } },
  ];

  const csvSummary = [
    `"${t('reporting.uiElements.settlementTotal')}: ${parseFloat(totalSettlementAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.billingTotal')}: ${parseFloat(totalBillingAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.cimsTotal')}: ${parseFloat(totalCimsAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.shippingTotal')}: ${parseFloat(totalShippingAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.discountSettlementTotal')}: ${parseFloat(totalDiscountSettlementAmount).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.otherChargesTotal')}: ${parseFloat(totalOtherChargesAmount).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.commissionTotal')}: ${parseFloat(totalCommissionAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.uiElements.netTotal')}: ${parseFloat(totalNetAmount).toLocaleString(convertedLocale, options)}"\n`,
  ];

  /**
  * Convert table data to CSV and call helper function to download file
  */
  const exportData = () => {
    const {
      nodes = [],
    } = filteredData;
    const exportableColumns = [
      { accessor: (item) => item.ENTITY_NAME, name: t('reporting.settlementSummary.entityName') },
      { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
      { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
      { accessor: (item) => `${item.DIV_STORE_NUM.padStart(10, '0')}\t`, name: t('reporting.uiElements.store') },
      { accessor: (item) => moment(item.ACTIVITY_DATE).format('MM/DD/YYYY'), name: t('reporting.settlementSummary.activityDate') },
      { accessor: (item) => item.SETTLEMENT_AMT, name: t('reporting.settlementSummary.settlementAmount') },
      { accessor: (item) => parseFloat(item.BILLING_AMT).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.billingAmount') },
      { accessor: (item) => parseFloat(item.CIMS_AMT).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.cimsAmount') },
      { accessor: (item) => parseFloat(item.SHIPPING_AMT).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.shippingAmount') },
      { accessor: (item) => parseFloat(item.DISC_SETL_AMT).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.discountSettlement') },
      { accessor: (item) => parseFloat(item.OTHER_CHARGES).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.otherCharges') },
      { accessor: (item) => parseFloat(item.COMMISSION).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.commission') },
      { accessor: (item) => parseFloat(item.NET_AMT).toLocaleString(convertedLocale, options), name: t('reporting.settlementSummary.netAmount') },
    ];
    const csvData = makeCsvData(exportableColumns, nodes);
    const makeStoreList = `${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`;
    downloadReport(csvData, 'Settlement', date, makeStoreList, csvSummary);
  };

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item' id='division'>
              <span className='title'>
                {t('reporting.uiElements.store')}
                {' '}
                :
              </span>
              <span>{storeList.join(', ')}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.settlementTotal')}: `}
              </span>
              <span>
                {parseFloat(totalSettlementAmt).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.billingTotal')}: `}
              </span>
              <span>{parseFloat(totalBillingAmt).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.cimsTotal')}: `}
              </span>
              <span>{parseFloat(totalCimsAmt).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.shippingTotal')}: `}
              </span>
              <span>{parseFloat(totalShippingAmt).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.discountSettlementTotal')}: `}
              </span>
              <span>{parseFloat(totalDiscountSettlementAmount).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.otherChargesTotal')}: `}
              </span>
              <span>{parseFloat(totalOtherChargesAmount).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.commissionTotal')}: `}
              </span>
              <span>{parseFloat(totalCommissionAmt).toLocaleString(convertedLocale, options)}</span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.netTotal')}: `}
              </span>
              <span>{parseFloat(totalNetAmount).toLocaleString(convertedLocale, options)}</span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      {selectedStores.length > 0 ? selectedStores.map(
        d => (
          <CollapsibleSection
            key={crypto.randomUUID()}
            store={d}
            data={smgr201Data}
            theme={theme}
            columns={COLUMNS}
            sortFunctions={sortFns}
          />
        ),
      ) : storeList.map(
        d => (
          <CollapsibleSection
            key={crypto.randomUUID()}
            store={d}
            data={smgr201Data}
            theme={theme}
            columns={COLUMNS}
            sortFunctions={sortFns}
          />
        ),
      )}
      { filteredData.nodes.length === 0 ? (
        <div className='no-data'>
          <i className='fa-solid fa-circle-exclamation' />
          <span>{t('reporting.uiElements.noDataFound')}</span>
        </div>
      ) : (
        <div className='action-buttons'>
          <button type='button' className='btn generate btn-primary' onClick={exportData}>{t('reporting.uiElements.exportData')}</button>
        </div>
      ) }
    </>
  );
};

SettlementSummary.propTypes = {
  date: PropTypes.string.isRequired,
  smgr201Data: PropTypes.arrayOf(
    PropTypes.shape({
      ACTIVITY_DATE: PropTypes.string,
      BILLING_AMT: PropTypes.string,
      CIMS_AMT: PropTypes.string,
      COMMISSION: PropTypes.string,
      DISC_SETL_AMT: PropTypes.string,
      DIVISION: PropTypes.string,
      ENTITY_NAME: PropTypes.string,
      MERCHANT: PropTypes.string,
      NET_AMT: PropTypes.string,
      OTHER_CHARGES: PropTypes.string,
      SETTLEMENT_AMT: PropTypes.string,
      SHIPPING_AMT: PropTypes.string,
      STORE: PropTypes.string,
    }),
  ).isRequired,
};

export default SettlementSummary;
