import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingContext } from '../../context/Reporting/ReportingContext';

const CustomFilter = () => {
  const { t } = useTranslation();
  const { setSelectedStores, setImportToggled, importToggled } = useContext(ReportingContext);

  const toggleimportExpanded = () => {
    setImportToggled(!importToggled);
  };
  const [textAreaValue, setTextAreaValue] = useState('');
  const createStoreList = () => {
    try {
      // replace multiple spaces with just one space
      const singleSpaces = textAreaValue.replaceAll(/\s+/g, ' ');
      // replace single spaces and new lines with commas
      const trimmed = singleSpaces.replaceAll(' ', ',').replaceAll('\n', ',');
      // split our final string out into an array
      const storeArray = trimmed.split(',');
      // if the provided store only contains numbers, pad it with zeroes
      const paddedStoreArray = [];
      storeArray.forEach(store => {
        let paddedStore;
        if (!store.match(/\D+/) && store !== '') {
          paddedStore = store.padStart(10, '0');
        } else {
          paddedStore = store;
        }
        if (store !== '') {
          paddedStoreArray.push(paddedStore);
        }
      });
      if (paddedStoreArray.length > 0) {
        // cut out duplicates
        const paddedStoreSet = [...new Set(paddedStoreArray)];
        setSelectedStores(paddedStoreSet);
        setTextAreaValue('');
        setImportToggled(false);
      } else {
        setSelectedStores([]);
        setImportToggled(false);
      }
    } catch {
      throw new Error('Error reading provided store list');
    }
  };
  return (
    <>
      <button type='button' className='btn generate mt-2 mb-2' onClick={toggleimportExpanded}>{t('reporting.uiElements.importStoreList')}</button>
      {importToggled ? (
        <div className='dropin mt-2'>
          <span>{t('reporting.uiElements.customFilter')}</span>
          <textarea className='form-control mt-2' id='custom-filter' rows='3' onChange={e => setTextAreaValue(e.target.value)} />
          <div className='row mt-2'>
            <div className='col-12 action-buttons'>
              <button className='btn generate dropin' onClick={createStoreList} type='button'>{t('buttons.submit')}</button>
            </div>
          </div>
        </div>
      ) : null }
    </>
  );
};

export default CustomFilter;
