import {
  React, useCallback, useContext, useMemo, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@table-library/react-table-library/sort';
import { useTranslation } from 'react-i18next';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import moment from 'moment';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const HostReconciliationData = ({
  stl132Data, date,
}) => {
  const { t, i18n } = useTranslation();

  const storeList = storeListBuilder(stl132Data, 'STORE');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);
  const reportData = stl132Data;
  const data = useMemo(() => ({
    nodes: selectedStores.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.STORE))
      : reportData,
  }), [reportData, selectedStores]);

  const [totalReqAmt, setTotalReqAmt] = useState(0);
  const [totalAppAmt, setTotalAppAmt] = useState(0);
  const [totalBaseReqAmt, setTotalBaseReqAmt] = useState(0);
  const [totalBaseAppAmt, setTotalBaseAppAmt] = useState(0);
  const [totalCardWrkBal, setTotalCardWrkBal] = useState(0);
  // on data change, compute the totals
  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const computeTotalReqAmt = nodes
        .map(item => parseFloat(item.POS_REQ_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalReqAmt(computeTotalReqAmt);
      const computeTotalAppAmt = nodes
        .map(item => parseFloat(item.POS_APPROVED_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalAppAmt(computeTotalAppAmt);
      const computeTotalBaseReq = nodes
        .map(item => parseFloat(item.BASE_REQ_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalBaseReqAmt(computeTotalBaseReq);
      const computeTotalBaseAppAmt = nodes
        .map(item => parseFloat(item.BASE_APPROVED_AMT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalBaseAppAmt(computeTotalBaseAppAmt);
      const computeTotalCardWrkBal = nodes
        .map(item => parseFloat(item.CARD_WRK_BAL))
        .reduce((accumulator, current) => accumulator + current);
      setTotalCardWrkBal(computeTotalCardWrkBal);
    } else {
      setTotalReqAmt(0);
      setTotalAppAmt(0);
      setTotalBaseAppAmt(0);
      setTotalBaseReqAmt(0);
      setTotalCardWrkBal(0);
    }
  }, [data]);
  /**
  * defines two decimal places for parseFloat functions
  */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;
  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        ACTIVATION_DATE: (array) => [...array].sort((a, b) => dateCompare(a.ACTIVATION_DATE, b.ACTIVATION_DATE)),
        ACTIVATION_DIVISION: (array) => [...array].sort(
          (a, b) => intCompare(a.ACTIVATION_DIVISION, b.ACTIVATION_DIVISION),
        ),
        ACTIVATION_MERCHANT: (array) => [...array].sort(
          (a, b) => intCompare(a.ACTIVATION_MERCHANT, b.ACTIVATION_MERCHANT),
        ),
        ACTIVATION_STORE: (array) => [...array].sort((a, b) => intCompare(a.ACTIVATION_STORE, b.ACTIVATION_STORE)),
        ACTIVATION_TIME: (array) => [...array].sort((a, b) => a.ACTIVATION_TIME.localeCompare(b.ACTIVATION_TIME)),
        ALT_CARD_NUM: (array) => [...array].sort((a, b) => intCompare(a.ALT_CARD_NUM, b.ALT_CARD_NUM)),
        BASE_APPROVED_AMT: (array) => [...array].sort((a, b) => floatCompare(a.BASE_APPROVED_AMT, b.BASE_APPROVED_AMT)),
        BASE_CURR_CODE: (array) => [...array].sort((a, b) => intCompare(a.BASE_CURR_CODE, b.BASE_CURR_CODE)),
        BASE_REQ_AMT: (array) => [...array].sort((a, b) => floatCompare(a.BASE_REQ_AMT, b.BASE_REQ_AMT)),
        CARD_NUM: (array) => [...array].sort((a, b) => intCompare(a.CARD_NUM, b.CARD_NUM)),
        CARD_WRK_BAL: (array) => [...array].sort((a, b) => floatCompare(a.CARD_WRK_BAL, b.CARD_WRK_BAL)),
        CLIENT_CODE: (array) => [...array].sort((a, b) => a.CLIENT_CODE.localeCompare(b.CLIENT_CODE)),
        COUNTRY: (array) => [...array].sort((a, b) => a.COUNTRY.localeCompare(b.COUNTRY)),
        CREDIT_DEBIT_IND: (array) => [...array].sort((a, b) => a.CREDIT_DEBIT_IND.localeCompare(b.CREDIT_DEBIT_IND)),
        CURR_BASE_UNITS: (array) => [...array].sort((a, b) => intCompare(a.CURR_BASE_UNITS, b.CURR_BASE_UNITS)),
        CURR_CONV_FACTOR: (array) => [...array].sort((a, b) => intCompare(a.CURR_CONV_FACTOR, b.CURR_CONV_FACTOR)),
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        HOST_DATE: (array) => [...array].sort((a, b) => dateCompare(a.HOST_DATE, b.HOST_DATE)),
        HOST_TIME: (array) => [...array].sort((a, b) => a.HOST_TIME.localeCompare(b.HOST_TIME)),
        INVOICE_NUM: (array) => [...array].sort((a, b) => a.INVOICE_NUM.localeCompare(b.INVOICE_NUM)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        ORIG_POS_DATE: (array) => [...array].sort((a, b) => dateCompare(a.ORIG_POS_DATE, b.ORIG_POS_DATE)),
        ORIG_POS_TIME: (array) => [...array].sort((a, b) => a.ORIG_POS_TIME.localeCompare(b.ORIG_POS_TIME)),
        ORIG_TRAN_SEQ_NUM: (array) => [...array].sort((a, b) => intCompare(a.ORIG_TRAN_SEQ_NUM, b.ORIG_TRAN_SEQ_NUM)),
        POS_APPROVED_AMT: (array) => [...array].sort((a, b) => floatCompare(a.POS_APPROVED_AMT, b.POS_APPROVED_AMT)),
        POS_CURR_CODE: (array) => [...array].sort((a, b) => intCompare(a.POS_CURR_CODE, b.POS_CURR_CODE)),
        POS_DATE: (array) => [...array].sort((a, b) => dateCompare(a.POS_DATE, b.POS_DATE)),
        POS_REQ_AMT: (array) => [...array].sort((a, b) => floatCompare(a.POS_REQ_AMT, b.POS_REQ_AMT)),
        POS_TIME: (array) => [...array].sort((a, b) => a.POS_TIME.localeCompare(b.POS_TIME)),
        SEQNUM: (array) => [...array].sort((a, b) => intCompare(a.SEQNUM, b.SEQNUM)),
        STATE: (array) => [...array].sort((a, b) => a.STATE.localeCompare(b.STATE)),
        STORE: (array) => [...array].sort((a, b) => intCompare(a.STORE, b.STORE)),
        TRANS_CODE: (array) => [...array].sort((a, b) => intCompare(a.TRANS_CODE, b.TRANS_CODE)),
        TRANSACTION_DESCRIPTION: (array) => [...array].sort((a, b) => a['TRANSACTION DESCRIPTION'].localeCompare(b['TRANSACTION DESCRIPTION'])),
      },
    },
  );
  /**
 * The columns and their corresponding data that will render in the table.
 */
  const COLUMNS = [
    { label: t('reporting.uiElements.clientCode'), renderCell: (item) => item.CLIENT_CODE, sort: { sortKey: 'CLIENT_CODE' } },
    { label: t('misc.card'), renderCell: (item) => item.CARD_NUM, sort: { sortKey: 'CARD_NUM' } },
    { label: t('reporting.hostReconciliationData.altCard'), renderCell: (item) => item.ALT_CARD_NUM, sort: { sortKey: 'ALT_CARD_NUM' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.store'), renderCell: (item) => item.STORE.padStart(10, '0'), sort: { sortKey: 'STORE' } },
    { label: t('reporting.hostReconciliationData.state'), renderCell: (item) => item.STATE, sort: { sortKey: 'STATE' } },
    { label: t('reporting.hostReconciliationData.country'), renderCell: (item) => item.COUNTRY, sort: { sortKey: 'COUNTRY' } },
    { label: t('reporting.hostReconciliationData.transactionCode'), renderCell: (item) => item.TRANS_CODE, sort: { sortKey: 'TRANS_CODE' } },
    { label: t('reporting.hostReconciliationData.description'), renderCell: (item) => item['TRANSACTION DESCRIPTION'], sort: { sortKey: 'TRANSACTION_DESCRIPTION' } },
    { label: t('reporting.hostReconciliationData.creditDebit'), renderCell: (item) => item.CREDIT_DEBIT_IND, sort: { sortKey: 'CREDIT_DEBIT_IND' } },
    { label: t('reporting.hostReconciliationData.sequenceNum'), renderCell: (item) => item.SEQNUM, sort: { sortKey: 'SEQNUM' } },
    { label: t('reporting.hostReconciliationData.invoiceNum'), renderCell: (item) => item.INVOICE_NUM, sort: { sortKey: 'INVOICE_NUM' } },
    { label: t('reporting.hostReconciliationData.hostDate'), renderCell: (item) => moment(item.HOST_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'HOST_DATE' } },
    { label: t('reporting.hostReconciliationData.hostTime'), renderCell: (item) => item.HOST_TIME, sort: { sortKey: 'HOST_TIME' } },
    { label: t('reporting.hostReconciliationData.posDate'), renderCell: (item) => moment(item.POS_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'POS_DATE' } },
    { label: t('reporting.hostReconciliationData.posTime'), renderCell: (item) => item.POS_TIME, sort: { sortKey: 'POS_TIME' } },
    { label: t('reporting.hostReconciliationData.posCurrency'), renderCell: (item) => item.POS_CURR_CODE, sort: { sortKey: 'POS_CURR_CODE' } },
    { label: t('reporting.hostReconciliationData.currBaseUnit'), renderCell: (item) => item.CURR_BASE_UNITS, sort: { sortKey: 'CURR_BASE_UNITS' } },
    { label: t('reporting.hostReconciliationData.origSeqNum'), renderCell: (item) => item.ORIG_TRAN_SEQ_NUM, sort: { sortKey: 'ORIG_TRAN_SEQ_NUM' } },
    { label: t('reporting.hostReconciliationData.currConvFactor'), renderCell: (item) => item.CURR_CONV_FACTOR, sort: { sortKey: 'CURR_CONV_FACTOR' } },
    { label: t('reporting.hostReconciliationData.posRequested'), renderCell: (item) => parseFloat(item.POS_REQ_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'POS_REQ_AMT' } },
    { label: t('reporting.hostReconciliationData.posApplied'), renderCell: (item) => parseFloat(item.POS_APPROVED_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'POS_APPROVED_AMT' } },
    { label: t('reporting.uiElements.baseCurrency'), renderCell: (item) => item.BASE_CURR_CODE, sort: { sortKey: 'BASE_CURR_CODE' } },
    { label: t('reporting.hostReconciliationData.baseRequested'), renderCell: (item) => parseFloat(item.BASE_REQ_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'BASE_REQ_AMT' } },
    { label: t('reporting.hostReconciliationData.baseApplied'), renderCell: (item) => parseFloat(item.BASE_APPROVED_AMT).toLocaleString(convertedLocale, options), sort: { sortKey: 'BASE_APPROVED_AMT' } },
    { label: t('reporting.hostReconciliationData.activationDate'), renderCell: (item) => moment(item.ACTIVATION_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'ACTIVATION_DATE' } },
    { label: t('reporting.hostReconciliationData.activationTime'), renderCell: (item) => item.ACTIVATION_TIME, sort: { sortKey: 'ACTIVATION_TIME' } },
    { label: t('reporting.hostReconciliationData.activationMerch'), renderCell: (item) => item.ACTIVATION_MERCHANT, sort: { sortKey: 'ACTIVATION_MERCHANT' } },
    { label: t('reporting.hostReconciliationData.activationDivision'), renderCell: (item) => item.ACTIVATION_DIVISION, sort: { sortKey: 'ACTIVATION_DIVISION' } },
    { label: t('reporting.hostReconciliationData.activationLocation'), renderCell: (item) => item.ACTIVATION_STORE, sort: { sortKey: 'ACTIVATION_STORE' } },
    { label: t('reporting.hostReconciliationData.originalPOSDate'), renderCell: (item) => moment(item.ORIG_POS_DATE).format('MM/DD/YYYY'), sort: { sortKey: 'ORIG_POS_DATE' } },
    { label: t('reporting.hostReconciliationData.originalPOSTime'), renderCell: (item) => item.ORIG_POS_TIME, sort: { sortKey: 'ORIG_POS_TIME' } },
    { label: t('reporting.hostReconciliationData.cardWrkBal'), renderCell: (item) => parseFloat(item.CARD_WRK_BAL).toLocaleString(convertedLocale, options), sort: { sortKey: 'CARD_WRK_BAL' } },
  ];

  const exportableColumns = [
    { accessor: (item) => item.CLIENT_CODE, name: t('reporting.uiElements.clientCode') },
    { accessor: (item) => `${item.CARD_NUM}\t`, name: t('misc.card') },
    { accessor: (item) => `${item.ALT_CARD_NUM}\t`, name: t('reporting.hostReconciliationData.altCard') },
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.STORE.padStart(10, '0')}\t`, name: t('reporting.uiElements.store') },
    { accessor: (item) => item.STATE, name: t('reporting.hostReconciliationData.state') },
    { accessor: (item) => item.COUNTRY, name: t('reporting.hostReconciliationData.country') },
    { accessor: (item) => item.TRANS_CODE, name: t('reporting.hostReconciliationData.transactionCode') },
    { accessor: (item) => item['TRANSACTION DESCRIPTION'], name: t('reporting.hostReconciliationData.description') },
    { accessor: (item) => item.CREDIT_DEBIT_IND, name: t('reporting.hostReconciliationData.creditDebit') },
    { accessor: (item) => item.SEQNUM, name: t('reporting.hostReconciliationData.sequenceNum') },
    { accessor: (item) => `${item.INVOICE_NUM}\t`, name: t('reporting.hostReconciliationData.invoiceNum') },
    { accessor: (item) => moment(item.HOST_DATE).format('MM/DD/YYYY'), name: t('reporting.hostReconciliationData.hostDate') },
    { accessor: (item) => item.HOST_TIME, name: t('reporting.hostReconciliationData.hostTime') },
    { accessor: (item) => moment(item.POS_DATE).format('MM/DD/YYYY'), name: t('reporting.hostReconciliationData.posDate') },
    { accessor: (item) => item.POS_TIME, name: t('reporting.hostReconciliationData.posTime') },
    { accessor: (item) => item.POS_CURR_CODE, name: t('reporting.hostReconciliationData.posCurrency') },
    { accessor: (item) => item.CURR_BASE_UNITS, name: t('reporting.hostReconciliationData.currBaseUnit') },
    { accessor: (item) => item.ORIG_TRAN_SEQ_NUM, name: t('reporting.hostReconciliationData.origSeqNum') },
    { accessor: (item) => item.CURR_CONV_FACTOR, name: t('reporting.hostReconciliationData.currConvFactor') },
    { accessor: (item) => parseFloat(item.POS_REQ_AMT).toLocaleString(convertedLocale, options), name: t('reporting.hostReconciliationData.posRequested') },
    { accessor: (item) => parseFloat(item.POS_APPROVED_AMT).toLocaleString(convertedLocale, options), name: t('reporting.hostReconciliationData.posApplied') },
    { accessor: (item) => item.BASE_CURR_CODE, name: t('reporting.uiElements.baseCurrency') },
    { accessor: (item) => parseFloat(item.BASE_REQ_AMT).toLocaleString(convertedLocale, options), name: t('reporting.hostReconciliationData.baseRequested') },
    { accessor: (item) => parseFloat(item.BASE_APPROVED_AMT).toLocaleString(convertedLocale, options), name: t('reporting.hostReconciliationData.baseApplied') },
    { accessor: (item) => moment(item.ACTIVATION_DATE).format('MM/DD/YYYY'), name: t('reporting.hostReconciliationData.activationDate') },
    { accessor: (item) => item.ACTIVATION_TIME, name: t('reporting.hostReconciliationData.activationTime') },
    { accessor: (item) => item.ACTIVATION_MERCHANT, name: t('reporting.hostReconciliationData.activationMerch') },
    { accessor: (item) => item.ACTIVATION_DIVISION, name: t('reporting.hostReconciliationData.activationDivision') },
    { accessor: (item) => `${item.ACTIVATION_STORE}\t`, name: t('reporting.hostReconciliationData.activationLocation') },
    { accessor: (item) => moment(item.ORIG_POS_DATE).format('MM/DD/YYYY'), name: t('reporting.hostReconciliationData.originalPOSDate') },
    { accessor: (item) => item.ORIG_POS_TIME, name: t('reporting.hostReconciliationData.originalPOSTime') },
    { accessor: (item) => parseFloat(item.CARD_WRK_BAL).toLocaleString(convertedLocale, options), name: t('reporting.hostReconciliationData.cardWrkBal') },
  ];

  const csvSummary = [
    `"${t('reporting.hostReconciliationData.totalReqAmt')}: ${parseFloat(totalReqAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.hostReconciliationData.totalAppAmt')}: ${parseFloat(totalAppAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.hostReconciliationData.totalBaseReqAmt')}: ${parseFloat(totalBaseReqAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.hostReconciliationData.totalBaseAppAmt')}: ${parseFloat(totalBaseAppAmt).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.hostReconciliationData.totalCardWrkBal')}: ${parseFloat(totalCardWrkBal).toLocaleString(convertedLocale, options)}"\n`,
  ];

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.hostReconciliationData.totalReqAmt')}: `}
              </span>
              <span>
                {parseFloat(totalReqAmt).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.hostReconciliationData.totalAppAmt')}: `}
              </span>
              <span>
                {parseFloat(totalAppAmt).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.hostReconciliationData.totalBaseReqAmt')}: `}
              </span>
              <span>
                {parseFloat(totalBaseReqAmt).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.hostReconciliationData.totalBaseAppAmt')}: `}
              </span>
              <span>
                {parseFloat(totalBaseAppAmt).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.hostReconciliationData.totalCardWrkBal')}: `}
              </span>
              <span>
                {parseFloat(totalCardWrkBal).toLocaleString(convertedLocale, options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        reportName='HostRecon'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />
    </>
  );
};

HostReconciliationData.propTypes = {
  date: PropTypes.string.isRequired,
  stl132Data: PropTypes.arrayOf(
    PropTypes.shape({
      ACTIVATION_DATE: PropTypes.string,
      ACTIVATION_DIVISION: PropTypes.string,
      ACTIVATION_MERCHANT: PropTypes.string,
      ACTIVATION_STORE: PropTypes.string,
      ACTIVATION_TIME: PropTypes.string,
      ALT_CARD_NUM: PropTypes.string,
      BASE_APPROVED_AMT: PropTypes.string,
      BASE_CURR_CODE: PropTypes.string,
      BASE_REQ_AMT: PropTypes.string,
      CARD_NUM: PropTypes.string,
      CARD_WRK_BAL: PropTypes.string,
      CLIENT_CODE: PropTypes.string,
      COUNTRY: PropTypes.string,
      CREDIT_DEBIT_IND: PropTypes.string,
      CURR_BASE_UNITS: PropTypes.string,
      CURR_CONV_FACTOR: PropTypes.string,
      DIVISION: PropTypes.string,
      HOST_DATE: PropTypes.string,
      HOST_TIME: PropTypes.string,
      INVOICE_NUM: PropTypes.string,
      MERCHANT: PropTypes.string,
      ORIG_POS_DATE: PropTypes.string,
      ORIG_POS_TIME: PropTypes.string,
      ORIG_TRAN_SEQ_NUM: PropTypes.string,
      POS_APPROVED_AMT: PropTypes.string,
      POS_CURR_CODE: PropTypes.string,
      POS_DATE: PropTypes.string,
      POS_REQ_AMT: PropTypes.string,
      POS_TIME: PropTypes.string,
      SEQNUM: PropTypes.string,
      STATE: PropTypes.string,
      STORE: PropTypes.string,
      TRANS_CODE: PropTypes.string,
      'TRANSACTION DESCRIPTION': PropTypes.string,
    }),
  ).isRequired,
};

export default HostReconciliationData;
