import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import '../../styles/scss/ContentCard.scss';

const ContentCard = ({
  actions = [],
  classNames,
  header,
  message,
  type,
}) => {
  const navigate = useNavigate();

  let buttonTypeClass;
  let headerTypeClass;
  switch (type) {
  case 'error':
    buttonTypeClass = 'btn-danger';
    headerTypeClass = 'error';
    break;
  case 'success':
    buttonTypeClass = 'btn-success';
    headerTypeClass = 'success';
    break;
  case 'warning':
    buttonTypeClass = 'btn-warning';
    headerTypeClass = 'warning';
    break;
  default:
    buttonTypeClass = 'btn-info';
    headerTypeClass = 'info';
    break;
  }

  const additionalClasses = classNames ? ` ${classNames}` : '';

  return (
    <div className={`content-card${additionalClasses}`}>
      {header
        && (
          <div className='header'>
            <h2 className={headerTypeClass}>{header}</h2>
          </div>
        )}
      <div className='content'>
        {typeof message === 'string'
          ? (
            <p>
              <span>{message}</span>
            </p>
          )
          : message}
      </div>

      <div className='actions'>
        {
          actions?.length > 0
          && actions.map(action => {
            const {
              message: buttonMessage,
              path,
            } = action;
            return (
              <button key={header} type='button' className={`btn ${buttonTypeClass}`} onClick={() => { navigate(path); }}>{buttonMessage}</button>
            );
          })
        }
      </div>

    </div>
  );
};

ContentCard.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  classNames: PropTypes.string,
  header: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

ContentCard.defaultProps = {
  actions: [],
  classNames: '',
  header: '',
};

export default ContentCard;
