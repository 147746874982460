import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { activeFilterTypesPropType } from './FilterPropTypes';

const ActiveFilter = ({ filterData, activeFilters, setActiveFilters }) => {
  const [currentValue, setCurrentValue] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const { label, value } = filterData;

  const inputRef = useRef();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isEditing]);

  const removeFilter = () => {
    const newActiveFilters = activeFilters.filter(({ value: activeValue }) => activeValue !== value);
    setActiveFilters(newActiveFilters);
  };

  const onBlur = (event) => {
    const inputValue = event.target.value;
    if (!inputValue) removeFilter();
    else setIsEditing(false);
  };

  const onChange = (e) => {
    const inputValue = e.target.value;
    setCurrentValue(inputValue);
    const filterIndex = activeFilters.findIndex(({ value: activeValue }) => value === activeValue);
    if (filterIndex > -1) {
      const newActiveFilters = [...activeFilters];
      newActiveFilters[filterIndex].criteria = inputValue.trim();
      setActiveFilters(newActiveFilters);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') setIsEditing(false);
  };

  return (
    <div className='active-filter'>
      <div className='filter-label'>
        {`${label}: `}
      </div>
      <div className={`filter-input${isEditing ? ' selected' : ''}`}>
        {isEditing
          ? (
            <input
              className='form-control-sm'
              onBlur={onBlur}
              onChange={onChange}
              onFocus={(e) => e.target.focus()}
              onKeyDown={onKeyDown}
              ref={inputRef}
              type='text'
              value={currentValue}
            />
          ) : <div onClick={() => setIsEditing(!isEditing)} role='button' tabIndex={0}>{currentValue}</div>}
      </div>
      <button
        className='remove-filter'
        type='button'
        onClick={removeFilter}
        tabIndex={-1}
      >
        <i className='fa-solid fa-close' />
      </button>
    </div>
  );
};

ActiveFilter.propTypes = {
  activeFilters: activeFilterTypesPropType.isRequired,
  filterData: PropTypes.shape({
    criteria: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  setActiveFilters: PropTypes.func.isRequired,
};

export default ActiveFilter;
