import React, {
  useCallback, useEffect, useState, useRef,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserFields from './UserFields';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import FormToggleSwitch from '../../components/Form/FormToggleSwitch';
import useAuthentication from '../../hooks/useAuthentication';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';

const CreateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const initialDataCalled = useRef(false);

  const retrieveUserFromStorage = () => {
    const serializedUser = sessionStorage.getItem('userToEdit');
    return serializedUser ? JSON.parse(serializedUser) : null;
  };

  const [user, setUser] = useState(retrieveUserFromStorage() || location?.state?.user || {});

  const {
    details: {
      groupId,
      userId,
    } = {},
  } = user;

  const [isLoading, setIsLoading] = useState(true);
  const methods = useForm({ defaultValues: user });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: {
      isValid,
    },
  } = methods;

  const getStorePrivileges = useCallback(async () => {
    try {
      const { data, status, statusText } = await callAPI('/api/accessManagement/getStoreUserInfo', { groupId, userId }, 'post');
      if (status === 200) {
        const { resultList: storesList } = data;
        setUser(prevUser => ({
          ...prevUser,
          stores: storesList.map(store => ({
            ...store,
            enabled: false,
          })),
        }));
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      displayErrorNotification(t('site.error', { message }));
    } finally {
      setIsLoading(false);
    }
  }, [callAPI, groupId, userId, t]);

  useEffect(() => {
    if (!initialDataCalled.current) {
      initialDataCalled.current = true;
      getStorePrivileges();
    }
  }, [getStorePrivileges]);

  useEffect(() => reset(user), [user, reset]);

  const { stores: formStores } = getValues();

  const handleToggleChange = (index, privilegeNames) => (newValue) => {
    if (!newValue) {
      privilegeNames.forEach(privName => setValue(`stores[${index}].privileges.${privName}`, false, { shouldDirty: true }));
    }
  };

  const generateToggleSwitch = (storeNumber, storeId, index) => {
    const { privileges, enabled: storeEnabled } = formStores[index];
    const privilegeNames = Object.keys(privileges);
    return (
      <div className='store-assignment' key={storeId}>
        <h4 className={storeEnabled ? 'enabled' : ''}>
          <span>{storeNumber}</span>
          <FormToggleSwitch
            fieldName={`stores[${index}].enabled`}
            handleChange={handleToggleChange(index, privilegeNames)}
            verticalAlign='middle'
            wrapperClassName='storeToggle'
          />
        </h4>
        <div className={`privilege-toggles ${storeEnabled ? ' enabled' : ''}`}>
          {storeEnabled && privilegeNames.map(privName => (
            <FormToggleSwitch
              key={privName}
              topLabel={t(`roles.privileges.${privName}`)}
              fieldName={`stores[${index}].privileges.${privName}`}
              verticalAlign='middle'
              wrapperClassName='privilegeToggle'
            />
          ))}
        </div>
      </div>
    );
  };

  const assignStoreRoles = async ({ newGroupID, stores, newUserID }) => {
    const payload = {
      isNewUser: true,
      refreshForStores: false,
      refreshForUsers: true,
      storeList: stores.filter(({ enabled }) => !!enabled).map(({
        store: {
          merchant, division, storeNumber,
        },
        privileges,
      }) => ({
        division,
        merchant,
        privileges: Object.keys(privileges).filter(priv => !!privileges[priv]),
        storeNumber,
      })),
      userList: [{
        groupId: newGroupID,
        privileges: null,
        userId: newUserID,
      }],
    };
    const response = await callAPI('/api/accessManagement/assignUsersToStores', payload, 'post');
    return response.data.messageReturnCode === '1000';
  };

  const createUser = async ({ merchantList, newUser }) => {
    if (isValid) {
      const response = await callAPI('/api/security/createUser', { merchantList, ...newUser }, 'post');
      if (!response) throw new Error('Failed to create new user.');
      else return response;
    }
    return { successful: false };
  };

  const deleteUser = async (newUser) => {
    if (isValid) {
      const response = await callAPI('/api/security/deleteUser', { userId: newUser }, 'post');
      const { data: { successful } = {} } = response;
      if (!successful) throw new Error('Failed to delete new user.');
      else return { successful };
    }
    return { successful: false };
  };

  const getMerchantList = (stores) => {
    const merchantList = stores.reduce((result, obj) => {
      const { merchant, division } = obj;
      const existingMerchant = result.find(entry => entry.merchantNum === merchant);
      if (existingMerchant) existingMerchant.divisions.push({ division: division.toString(), divisionSelected: true });
      else {
        result.push({
          divisions: [{ division: division.toString(), divisionSelected: true }],
          merchantNum: merchant.toString(),
        });
      }
      return result;
    }, []);
    return merchantList;
  };

  const saveNewFranchiseUser = async ({ newGroupID, newUserID }) => {
    const payload = {
      clientPersonId: '',
      clientRole: '',
      groupId: newGroupID,
      overrideIfExists: false,
      userId: newUserID,
    };
    const response = await callAPI('/api/accessManagement/saveUserInfo', payload, 'post');
    return response.data.messageReturnCode === '1000';
  };

  const validateStoreSelections = (stores) => {
    const storesEnabled = stores.some(({ enabled }) => !!enabled);
    if (!storesEnabled) {
      displayErrorNotification(t('users.atLeastOneStoreMustBeEnabled'));
      return false;
    }
    return true;
  };

  const onSubmit = async (data) => {
    let userCreated = false;
    let newGroupID = null;
    let newUserID = null;
    try {
      setIsLoading(true);
      const { details, stores } = data;
      const {
        emailAddress,
        userName: fullName,
        locale,
        phoneNumber,
        signonId,
      } = details;
      const selectedStores = stores?.filter(s => s.enabled);
      const merchantList = getMerchantList(selectedStores?.map(s => s.store));
      const newUser = {
        email: emailAddress,
        fullName,
        locale,
        phoneNumber,
        signonId,
      };
      // should migrate this store selection validation to a react-hook-form valdiate function in the component
      const storesValid = await validateStoreSelections(stores);
      if (storesValid) {
        const createUserResponse = await createUser({ merchantList, newUser });
        newGroupID = createUserResponse.data.groupId;
        newUserID = createUserResponse.data.userId;
        userCreated = createUserResponse.data.successful;
        if (!userCreated) throw new Error('Error creating user');

        const userSaved = await saveNewFranchiseUser({ newGroupID, newUserID });
        if (!userSaved) throw new Error('Error saving franchise user data.');

        const userAssignedToStores = await assignStoreRoles({ newGroupID, newUserID, stores });
        if (!userAssignedToStores) throw new Error('Error assigning user to stores.');

        displaySuccessNotification(`${t('users.createUser.success')} ${newUser.fullName} (${newUser.signonId})`);
        navigate('/access/users');
      }
      setIsLoading(false);
    } catch {
      if (userCreated) await deleteUser(newUserID);
      displayErrorNotification(t('users.createUser.failure'));
      setIsLoading(false);
    }
  };

  return (
    <div className='container access-management' id='create-user'>
      <div className='row g-2 mb-2'>
        <div className='col-12'>
          <h1>{t('users.createUser.title')}</h1>
        </div>
      </div>
      {isLoading ? elastic : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <UserFields isNewUser />
            <div className='row mb-3'>
              <div className='col-12'>
                <h6>{t('users.storeLevelAccessRoles')}</h6>
              </div>
              <div className='stores-privileges'>
                {formStores?.map(({
                  store: { storeNumber }, storeId,
                }, index) => generateToggleSwitch(storeNumber, storeId, index))}
              </div>
            </div>
            <div className='action-buttons'>
              <button className='btn' type='submit'>Submit</button>
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default CreateUser;
