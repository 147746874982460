import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/scss/ToggleSwitch.scss';

const getThumbLocation = ({ checked, threeSwitch }) => { // extracted all this logic into functions to pass sonarqube
  let location = 'left';
  if (threeSwitch) {
    if (checked === null) location = 'middle';
    if (checked === false) location = 'left';
    if (checked === true) location = 'right';
  } else if (checked === true) location = 'right';
  return location;
};

const getThumbLeftPx = ({ thumbLocation, threeSwitch }) => {
  let leftPx = '0px';

  if (threeSwitch) {
    if (thumbLocation === 'middle') leftPx = '12px';
    if (thumbLocation === 'left') leftPx = '1px';
    if (thumbLocation === 'right') leftPx = '23px';
  } else {
    if (thumbLocation === 'left') leftPx = '1px';
    if (thumbLocation === 'right') leftPx = '23px';
  }

  return leftPx;
};

const getDisabledLabels = ({ thumbLocation, leftLabel, rightLabel }) => {
  let leftLabelDisabled = false; // defaults to right settings
  let topLabelDisabled = false;
  let rightLabelDisabled = false;
  if (leftLabel && rightLabel) {
    if (thumbLocation === 'middle') {
      leftLabelDisabled = true;
      topLabelDisabled = true;
      rightLabelDisabled = true;
    }
    if (thumbLocation === 'left') {
      leftLabelDisabled = false;
      topLabelDisabled = false;
      rightLabelDisabled = true;
    }

    if (thumbLocation === 'right') {
      leftLabelDisabled = true;
      topLabelDisabled = false;
      rightLabelDisabled = false;
    }
  } else if (thumbLocation === 'left') {
    leftLabelDisabled = true;
    topLabelDisabled = true;
    rightLabelDisabled = true;
  }

  return { leftLabelDisabled, rightLabelDisabled, topLabelDisabled };
};

const getNextCheckedValue = ({ checked, threeSwitch, thumbLocation }) => {
  let nextCheckedValue = true; // middle if threeswitch
  if (threeSwitch) {
    if (thumbLocation === 'left') nextCheckedValue = null;
    if (thumbLocation === 'right') nextCheckedValue = false;
  } else {
    nextCheckedValue = !checked;
  }
  return nextCheckedValue;
};

const ToggleSwitch = ({
  checked,
  disabled,
  inline,
  label,
  leftLabel,
  onChange,
  rightLabel,
  threeSwitch,
  topLabel,
  verticalAlign,
  wrapperClassName,
}) => {
  const thumbLocation = getThumbLocation({ checked, threeSwitch });
  const leftPx = getThumbLeftPx({ threeSwitch, thumbLocation });
  const {
    leftLabelDisabled,
    topLabelDisabled,
    rightLabelDisabled,
  } = getDisabledLabels({ leftLabel, rightLabel, thumbLocation });

  const nextCheckedValue = getNextCheckedValue({ checked, threeSwitch, thumbLocation });
  const verticalAlignClassName = verticalAlign ? ` vertical-align-${verticalAlign}` : '';
  return (
    <div className={`${wrapperClassName || ''}${inline ? ' toggle-inline' : ''} `}>
      {topLabel && (
        <div
          className={`toggle-switch label-text${topLabelDisabled ? ' label-text-disabled' : ''} label-text-top`}
        >
          {topLabel}
        </div>
      )}
      {leftLabel && (
        <div
          className={`label-text${leftLabelDisabled ? ' label-text-disabled' : ''} label-text-left${verticalAlignClassName}`}
        >
          {leftLabel}
        </div>
      )}
      <div
        role='button'
        tabIndex={0}
        onClick={() => { if (onChange && !disabled) onChange(nextCheckedValue); }}
        className={`react-toggle${checked || (threeSwitch && checked === false) ? ' react-toggle--checked' : ''}${disabled ? ' react-toggle--disabled' : ''}`}
      >
        <div className='react-toggle-track' />
        <div style={{ left: leftPx }} className='react-toggle-thumb' />
      </div>
      {(rightLabel || label) && (
        <div
          className={`label-text${rightLabelDisabled ? ' label-text-disabled' : ''} label-text-right${verticalAlignClassName}`}
        >
          {rightLabel || label}
        </div>
      )}
    </div>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  label: PropTypes.string,
  leftLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rightLabel: PropTypes.string,
  threeSwitch: PropTypes.bool,
  topLabel: PropTypes.string,
  verticalAlign: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

ToggleSwitch.defaultProps = {
  checked: false,
  disabled: false,
  inline: false,
  label: '',
  leftLabel: '',
  rightLabel: '',
  threeSwitch: false,
  topLabel: '',
  verticalAlign: 'bottom',
  wrapperClassName: undefined,
};

export default ToggleSwitch;
