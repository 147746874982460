export default (state, action) => {
  const {
    payload = {},
    type,
  } = action;
  const {
    selectedStores,
    importToggled,
  } = payload;

  switch (type) {
  case 'SET_SELECTED_STORES':
    return {
      ...state,
      selectedStores,
    };
  case 'SET_IMPORT_TOGGLED':
    return {
      ...state,
      importToggled,
    };
  default:
    return state;
  }
};
