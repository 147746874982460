import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../../hooks/useAuthentication';
import CreatableSelect from '../../../components/Form/CreatableSelect';
import FormInputField from '../../../components/Form/FormInputField';
import ModalForm from '../../../components/ModalForm/ModalForm';
import securityQuestions from './securityQuestions';
import { maxLengths } from '../../../helpers/validation';

const SecurityQuestionSetup = ({ onFailure, onSuccess, ...modalProps }) => {
  const { callAPI } = useAuthentication();
  const { t } = useTranslation();
  const methods = useForm();
  const {
    getValues,
    handleSubmit,
  } = methods;

  const formValues = getValues();

  const initialOptions = securityQuestions.map((securityQuestion) => ({
    label: t(securityQuestion),
    value: securityQuestion,
  }));

  const [options, setOptions] = useState(initialOptions);

  const saveSecurityQuestions = async (questions) => {
    try {
      const {
        'security-answer-1': securityAnswerOne,
        'security-answer-2': securityAnswerTwo,
        'security-answer-3': securityAnswerThree,
        'security-question-1': securityQuestionOne,
        'security-question-2': securityQuestionTwo,
        'security-question-3': securityQuestionThree,
      } = questions;
      const questionAnswerPairs = [
        { answer: securityAnswerOne, question: securityQuestionOne.label },
        { answer: securityAnswerTwo, question: securityQuestionTwo.label },
        { answer: securityAnswerThree, question: securityQuestionThree.label },
      ];
      const { status } = await callAPI('/api/authentication/saveSecurityQuestions', { questionAnswerPairs }, 'post');
      if (status !== 200) throw new Error(t('form.login.mfa.securityQuestions.errors.saveSecurityQuestionError'));
      onSuccess();
    } catch (error) {
      const { message } = error;
      onFailure({ message });
    }
  };

  const handleCreate = useCallback((newOption) => {
    setOptions((prev) => [...prev, newOption]);
  }, []);

  const {
    securityQuestions: {
      QUESTION_MAX_LENGTH,
      ANSWER_MAX_LENGTH,
    },
  } = maxLengths;

  return (
    <FormProvider {...methods}>
      <ModalForm
        {...modalProps}
        confirmAction={handleSubmit(saveSecurityQuestions)}
        modalCancelText={t('buttons.cancel')}
        modalConfirmText={t('buttons.submit')}
        modalTitle={t('form.login.mfa.securityQuestions.title')}
      >
        <form className='mfa' id='mfa-reset'>
          <div className='message'>{t('form.login.mfa.securityQuestions.message')}</div>
          <CreatableSelect
            fieldName='security-question-1'
            handleCreate={handleCreate}
            key='security-question-1'
            options={options.filter(option => {
              const { value } = option;
              const questionTwoValue = formValues['security-question-2']?.value;
              const questionThreeValue = formValues['security-question-3']?.value;
              return value !== questionTwoValue && value !== questionThreeValue;
            })}
            placeholder={t('form.login.mfa.securityQuestions.placeholder')}
            validationRules={{
              required: t('form.required'),
              validate: ({ value }) => value?.length <= QUESTION_MAX_LENGTH || t('form.fieldExceedsMaxLength', { maxLength: QUESTION_MAX_LENGTH }),
            }}
          />
          <div className='row answer'>
            <FormInputField
              autocomplete='off'
              fieldName='security-answer-1'
              placeholder={`${t('form.login.mfa.reset.answerPlaceholder')} 1`}
              type='text'
              maxLength={ANSWER_MAX_LENGTH}
              showCharacterCount
              validationRules={{
                maxLength: {
                  message: t('form.fieldExceedsMaxLength', { maxLength: ANSWER_MAX_LENGTH }),
                  value: ANSWER_MAX_LENGTH,
                },
                required: t('form.required'),
              }}
            />
          </div>
          <CreatableSelect
            fieldName='security-question-2'
            handleCreate={handleCreate}
            key='security-question-2'
            options={options.filter(option => {
              const { value } = option;
              const questionOneValue = formValues['security-question-1']?.value;
              const questionThreeValue = formValues['security-question-3']?.value;
              return value !== questionOneValue && value !== questionThreeValue;
            })}
            placeholder={t('form.login.mfa.securityQuestions.placeholder')}
            validationRules={{
              required: t('form.required'),
              validate: ({ value }) => value?.length <= QUESTION_MAX_LENGTH || t('form.fieldExceedsMaxLength', { maxLength: QUESTION_MAX_LENGTH }),
            }}
          />
          <div className='row answer'>
            <FormInputField
              autocomplete='off'
              fieldName='security-answer-2'
              placeholder={`${t('form.login.mfa.reset.answerPlaceholder')} 2`}
              type='text'
              maxLength={ANSWER_MAX_LENGTH}
              showCharacterCount
              validationRules={{
                maxLength: {
                  message: t('form.fieldExceedsMaxLength', { maxLength: ANSWER_MAX_LENGTH }),
                  value: ANSWER_MAX_LENGTH,
                },
                required: t('form.required'),
              }}
            />
          </div>
          <CreatableSelect
            fieldName='security-question-3'
            handleCreate={handleCreate}
            key='security-question-3'
            options={options.filter(option => {
              const { value } = option;
              const questionOneValue = formValues['security-question-1']?.value;
              const questionTwoValue = formValues['security-question-2']?.value;
              return value !== questionTwoValue && value !== questionOneValue;
            })}
            placeholder={t('form.login.mfa.securityQuestions.placeholder')}
            validationRules={{
              required: t('form.required'),
              validate: ({ value }) => value?.length <= QUESTION_MAX_LENGTH || t('form.fieldExceedsMaxLength', { maxLength: QUESTION_MAX_LENGTH }),
            }}
          />
          <div className='row answer'>
            <FormInputField
              autocomplete='off'
              fieldName='security-answer-3'
              placeholder={`${t('form.login.mfa.reset.answerPlaceholder')} 3`}
              type='text'
              maxLength={ANSWER_MAX_LENGTH}
              showCharacterCount
              validationRules={{
                maxLength: {
                  message: t('form.fieldExceedsMaxLength', { maxLength: ANSWER_MAX_LENGTH }),
                  value: ANSWER_MAX_LENGTH,
                },
                required: t('form.required'),
              }}
            />
          </div>
        </form>
      </ModalForm>
    </FormProvider>
  );
};

SecurityQuestionSetup.propTypes = {
  onFailure: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default SecurityQuestionSetup;
