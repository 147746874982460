import React, {
  Suspense,
  useContext,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles/scss/FranchiseManagement.scss';
import { elastic } from './components/LoadingAnimations/LoadingAnimations';
import RequireAuthentication from './components/RequireAuthentication/RequireAuthentication';
import { GlobalContext } from './context/Global/GlobalContext';
import ChangePassword from './pages/Login/ChangePassword';
import Login from './pages/Login/Login';
import Viewport from './pages/Viewport/Viewport';
import SSOCallback from './pages/Login/SSOCallback';
import { LoginProvider } from './context/Login/LoginProvider';
import { CardOrderingProvider } from './context/CardOrdering/CardOrderingContext';

const FranchiseManagement = () => {
  const {
    clientTheme,
  } = useContext(GlobalContext);

  return (
    <div id='franchise-management' className={`${clientTheme}`}>
      <Suspense fallback={elastic}>
        <Routes>
          <Route
            element={(
              <LoginProvider>
                <Login />
              </LoginProvider>
            )}
            path='/login'
          />
          <Route
            element={<ChangePassword />}
            path='/reset-password'
          />
          <Route
            element={(
              <LoginProvider>
                <SSOCallback />
              </LoginProvider>
            )}
            path='/sso/callback'
          />
          <Route element={<RequireAuthentication />}>
            <Route
              element={(
                <CardOrderingProvider>
                  <Viewport />
                </CardOrderingProvider>
              )}
              path='*'
            />
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
};

export default FranchiseManagement;
