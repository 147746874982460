import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { filterTypesPropType, activeFilterTypesPropType } from './FilterPropTypes';

const FilterSelect = ({
  filterTypes, activeFilters, setActiveFilters, ...selectProps
}) => {
  const [selectedFilter, setSelectedFilter] = useState(null);

  const onChange = useCallback((newValue) => {
    setActiveFilters([...activeFilters, {
      ...newValue,
      criteria: '',
    }]);
    setSelectedFilter(null);
  }, [activeFilters, setActiveFilters]);

  const availableFilters = filterTypes.filter(({
    value: filterValue,
  }) => !activeFilters.some(({ value: activeValue }) => filterValue === activeValue));

  return (
    <Select
      className='filter-select'
      classNamePrefix='filter-select'
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      onChange={onChange}
      options={availableFilters}
      value={selectedFilter}
      {...selectProps}
    />
  );
};

FilterSelect.propTypes = {
  activeFilters: activeFilterTypesPropType.isRequired,
  filterTypes: filterTypesPropType.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
};

export default FilterSelect;
