export default (state, action) => {
  const {
    payload = {},
    type,
  } = action;
  const {
    selectedStore,
    cart,
  } = payload;

  switch (type) {
  case 'SET_SELECTED_STORE':
    return {
      ...state,
      selectedStore,
    };
  case 'SET_CART':
    return {
      ...state,
      cart,
    };
  case 'SET_STATE':
    return payload;
  default:
    return state;
  }
};
