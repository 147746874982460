import React, {
  useEffect, useState, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import '../../styles/scss/Reporting.scss';
import useAuthentication from '../../hooks/useAuthentication';
import SelectionMenu from './SelectionMenu';
import { displayErrorNotification } from '../../components/Notification/Notify';
import { ReportingProvider } from '../../context/Reporting/ReportingContext';
import buildPayloadWithEmulation from '../../utils/emulate';
import { GlobalContext } from '../../context/Global/GlobalContext';

const Reporting = () => {
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    emulationMode: { read: emulationModeEnabled = false },
    emulatedUser = null,
  } = useContext(GlobalContext);

  useEffect(() => {
    const translateReportValue = (reportCode, displayName) => {
      switch (reportCode) {
      // Host Recon
      case 'EXTSTL132':
        return (
          t('reporting.reportOptions.hostReconciliationData')
        );
      // Settlement
      case 'SMGR201E':
        return (
          t('reporting.reportOptions.settlementSummary')
        );
      // Shipments
      case 'SMGR269E':
        return (
          t('reporting.reportOptions.cardShipmentReport')
        );
      // Other Charges
      case 'SMGR270E':
        return (
          t('reporting.reportOptions.otherFees')
        );
      // Discount Settlement
      case 'SMGR300R':
        return (
          t('reporting.reportOptions.redemptions')
        );
      // Commissions
      case 'SMGR310A':
        return (
          t('reporting.reportOptions.commissions')
        );
      // Store Activity Summary
      case 'RPT590A':
        return (
          t('reporting.reportOptions.transactionsByStore')
        );
      default:
        return displayName;
      }
    };
    const getReportList = async () => {
      try {
        const payload = buildPayloadWithEmulation({}, emulationModeEnabled, emulatedUser);
        setIsLoading(true);
        const { data = [], status, statusText } = await callAPI('/api/reporting/getReportDatasets', payload, 'post');
        if (status === 200) {
          const reports = data.map(r => {
            const translated = translateReportValue(r.REPORT_CODE, r.DISPLAY_NAME);
            return { label: translated, value: { code: r.REPORT_CODE, id: r.ID } };
          });
          setReportList(reports);
        } else { throw Error(`${status} ${statusText}`); }
      } catch ({ message }) {
        displayErrorNotification(t('site.error', { message }));
      } finally {
        setIsLoading(false);
      }
    };
    getReportList();
  }, [callAPI, t, emulationModeEnabled, emulatedUser]);

  const reportListComponent = () => (
    reportList?.length > 0
      ? <SelectionMenu reportList={reportList} />
      : <p>{t('reporting.noReports')}</p>
  );

  return (
    <ReportingProvider>
      <div className='container' id='reporting'>
        <header>
          <h1>{t('reporting.uiElements.reporting')}</h1>
        </header>
        {
          isLoading ? elastic : reportListComponent()
        }
      </div>
    </ReportingProvider>
  );
};

export default Reporting;
