import React, {
  useCallback, useEffect, useState, useRef, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import '../../styles/scss/Reporting.scss';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import SettlementSummary from './SettlementSummary';
import CardShipmentReport from './CardShipmentReport';
import Redemptions from './Redemptions';
import Commissions from './Commissions';
import OtherFees from './OtherFees';
import HostReconciliationData from './HostReconciliationData';
import TransactionsByStore from './TransactionsByStore';
import useAuthentication from '../../hooks/useAuthentication';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import buildPayloadWithEmulation from '../../utils/emulate';

const { tz } = moment;

const SelectionMenu = ({ reportList }) => {
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const [displayReport, setDisplayReport] = useState(false);

  const { setSelectedStores } = useContext(ReportingContext);
  const {
    sessionData: { locale },
    emulationMode: { read: emulationModeEnabled = false },
    emulatedUser = null,
  } = useContext(GlobalContext);
    // controls for displaying various dropdowns
  const [date, setDate] = useState('');
  const [noData, setNoData] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);
  const [reportConfirmed, setReportConfirmed] = useState(false);
  // selected report values
  const [selectedReport, setSelectedReport] = useState();

  // state for returned data
  const [returnedData, setReturnedData] = useState([]);
  /**
  * When the user selects a report and store number, we set the list of dates the data is available for.
  */
  useEffect(() => {
    if (selectedReport) {
      const payload = buildPayloadWithEmulation({
        datasetId: selectedReport.value.id,
      }, emulationModeEnabled, emulatedUser);
      const getReportDates = async () => {
        const response = await callAPI('api/reporting/getReportDates', payload, 'post');
        const { data } = response;
        if (response !== null) {
          const dates = data.map(d => ({ label: d.REPORT_DATE, value: d.REPORT_DATE }));
          setAvailableDates(dates);
        }
      };
      getReportDates();
      setReportConfirmed(true);
    }
  }, [selectedReport, callAPI, setReportConfirmed, emulationModeEnabled, emulatedUser]);

  useEffect(() => {
    setDisplayReport(false);
  }, [selectedReport]);

  const reportRef = useRef();
  const dateRef = useRef();

  /**
   * On date selection, get the beginning and ending dates from the react date picker
   */
  const dateChange = useCallback((selectedDate) => {
    setDate(selectedDate.value);
  }, []);

  /**
   * When report type changes, set the selected report and hide report
   * reset date
   */
  const displayReportSelected = useCallback((report) => {
    if (date) {
      dateRef.current.selectOption('');
      setDate(null);
    }
    setNoData(false);
    setSelectedReport(report);
  }, [date]);

  const generateTable = (report) => {
    const { code } = report.value;
    switch (code) {
    case 'EXTSTL132':
      return (
        <HostReconciliationData
          stl132Data={returnedData}
          date={date}
        />
      );
    case 'SMGR201E':
      return (
        <SettlementSummary
          smgr201Data={returnedData}
          date={date}
        />
      );
    case 'SMGR269E':
      return (
        <CardShipmentReport
          smgr269Data={returnedData}
          date={date}
        />
      );
    case 'SMGR270E':
      return (
        <OtherFees
          smgr270Data={returnedData}
          date={date}
        />
      );
    case 'SMGR300R':
      return (
        <Redemptions
          smgr300Data={returnedData}
          date={date}
        />
      );
    case 'SMGR310A':
      return (
        <Commissions
          smgr310Data={returnedData}
          date={date}
        />
      );
    case 'RPT590A':
      return (
        <TransactionsByStore
          rpt590Data={returnedData}
          date={date}
        />
      );
    default:
      return <span>This report is not supported yet!</span>;
    }
  };

  /**
   * When the submit button is clicked, call backend and determine if data was returned
   */
  const onReportSubmit = async () => {
    if (selectedReport) {
      const { code } = selectedReport.value;
      // convert selected date to unix milliseconds
      const convertedDate = tz(date, 'America/Kentucky/Louisville').startOf('day').valueOf();
      let convertedLangCode = '';
      if (locale === 'en_US' || locale === 'en_CA') {
        convertedLangCode = 'en';
      } else {
        convertedLangCode = 'fr';
      }
      const payload = buildPayloadWithEmulation({
        languageCode: convertedLangCode,
        reportCode: code,
        reportDate: convertedDate,
      }, emulationModeEnabled, emulatedUser);
      const { data } = await callAPI('api/reporting/getReportData', payload, 'post');
      if (data.length !== 0) {
        setSelectedStores([]);
        setNoData(false);
        setReturnedData(data);
        setDisplayReport(true);
      } else {
        setNoData(true);
      }
    }
  };

  /**
   * Resets all values back to their defaults so that a user can generate a new report
   */
  const reset = () => {
    setDisplayReport(false);
    setReturnedData(null);
    reportRef.current.selectOption('');
    dateRef.current.selectOption('');
    setSelectedReport('');
    setDate(null);
    setNoData(false);
    setReportConfirmed(false);
  };

  /**
   * If report, division, and date are filled, enable submit button
   */
  const verifySelections = useCallback(
    () => !(selectedReport && date),
    [date, selectedReport],
  );

  const dateSelector = (
    <div className='col-12 col-lg-4 report-control dropin'>
      <h2>{t('reporting.uiElements.reportDate')}</h2>
      <Select
        className='report-date'
        classNamePrefix='report-date'
        onChange={dateChange}
        options={availableDates}
        ref={dateRef}
      />
    </div>
  );

  const noDataFound = (
    <div className='no-data'>
      <i className='fa-solid fa-circle-exclamation' />
      <span>{t('reporting.uiElements.noDataFound')}</span>
    </div>
  );

  const reportSelector = (
    <div className='row report-controls'>
      <div className='col-12 col-lg-4 report-control'>
        <h2>{t('reporting.uiElements.report')}</h2>
        <Select
          className='report-type'
          classNamePrefix='report-type'
          onChange={displayReportSelected}
          options={reportList}
          ref={reportRef}
        />
      </div>
    </div>
  );
  return (
    <>
      {reportSelector}
      {reportConfirmed
        ? (
          <>
            <div className='row'>
              {dateSelector}
            </div>
            <div className='row'>
              <div className='col-12 action-buttons'>
                <button className='btn btn-primary reset-button dropin' id='reset' onClick={reset} tabIndex={0} type='button'>{t('buttons.reset')}</button>
                <button className='btn generate dropin' disabled={verifySelections()} onClick={onReportSubmit} type='button'>{t('buttons.submit')}</button>
              </div>
            </div>
          </>
        )
        : null}
      <div className='generatedReport'>
        {displayReport && !noData && date && generateTable(selectedReport)}
      </div>
      {noData && noDataFound}
    </>
  );
};

SelectionMenu.propTypes = {
  reportList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  ).isRequired,
};

export default SelectionMenu;
