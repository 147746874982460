import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import CreatableReactSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { getValueFromPath } from '../../utils/arrays';

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ''),
});

/**
  If you get an error message about an object being returned instead of a string,
  add the returnObjects property in an argument with the translation function call:

      t(`form.step${step}.fields.myFieldName.fieldMessages`, { returnObjects: true })

  Reference: https://www.i18next.com/translation-function/objects-and-arrays
*/

const CreatableSelect = ({
  fieldClasses,
  fieldMessages,
  fieldName,
  handleCreate,
  icon,
  label,
  placeholder,
  validationRules,
  ...selectProps
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    formState: { errors },
    getValues,
    watch,
    register,
    setValue,
  } = useFormContext();

  const fieldError = getValueFromPath(errors, fieldName);

  const formValues = getValues();

  // watches the fieldName value provided to allow form to track current selection
  watch([fieldName]);

  const {
    name,
    onBlur,
    ref,
  } = { ...register(fieldName, { ...validationRules }) };

  // convert the fieldMessage value to an array if it is a string for later mapping
  const messages = fieldMessages ? [].concat(fieldMessages) : [];

  const onCreate = useCallback((inputValue) => {
    setIsLoading(true);
    const newOption = createOption(inputValue);
    setIsLoading(false);
    handleCreate(newOption);
    setValue(fieldName, newOption);
  }, [fieldName, handleCreate, setValue]);

  return (
    <div className={fieldClasses || 'col-12'}>
      {label && (
        <label className='form-label' htmlFor={fieldName}>
          <span>
            {label}
          </span>
        </label>
      )}
      <div className='input-group'>
        {icon && (
          <span className='input-group-text'>{icon}</span>
        )}
        <CreatableReactSelect
          {...selectProps}
          backspaceRemovesValue
          className={`form-select-creatable form-validation${fieldError ? ' error' : ''}`}
          classNamePrefix='form-select-creatable'
          isDisabled={isLoading}
          isClearable
          isLoading={isLoading}
          name={name}
          onBlur={onBlur}
          onChange={useCallback((newValue) => {
            setValue(fieldName, newValue);
          }, [fieldName, setValue])}
          onCreateOption={onCreate}
          placeholder={placeholder}
          ref={ref}
          value={formValues[fieldName]}
        />
        {!validationRules.required && <small className='form-text form-message optional'>{t('form.optional')}</small>}
        {messages?.map(message => <small className='form-text form-message' key={message}>{message}</small>)}
        <small className={`text-right form-text form-validation error message${fieldError ? ' active' : ''}`}>{fieldError?.message}</small>
      </div>
    </div>
  );
};

CreatableSelect.propTypes = {
  fieldClasses: PropTypes.string,
  fieldMessages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  fieldName: PropTypes.string.isRequired,
  handleCreate: PropTypes.func.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  })),
  placeholder: PropTypes.string,
  validationRules: PropTypes.shape({
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  }),
};

CreatableSelect.defaultProps = {
  fieldClasses: undefined,
  fieldMessages: undefined,
  icon: undefined,
  label: undefined,
  options: [],
  placeholder: 'Select...',
  validationRules: {},
};

export default CreatableSelect;
