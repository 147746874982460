import {
  React, useCallback, useContext, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@table-library/react-table-library/sort';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import { useTranslation } from 'react-i18next';
import { floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const SMGR300 = ({
  smgr300Data, date,
}) => {
  const { t, i18n } = useTranslation();
  const storeList = storeListBuilder(smgr300Data, 'STORE');
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);
  const reportData = smgr300Data;
  const data = useMemo(() => ({
    nodes: selectedStores.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.STORE))
      : reportData,
  }), [reportData, selectedStores]);

  const [totalAmount, setTotalAmount] = useState(0);
  const [discountACHTotal, setDiscountACHTotal] = useState(0);

  // on data change, compute the totals
  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const computeTotalAmount = nodes
        .map(item => parseFloat(item.AMOUNT))
        .reduce((accumulator, current) => accumulator + current);
      setTotalAmount(computeTotalAmount);
      const computeDiscountACHTotal = nodes
        .map(item => parseFloat(item.DISCOUNT_ACH))
        .reduce((accumulator, current) => accumulator + current);
      setDiscountACHTotal(computeDiscountACHTotal);
    } else {
      setTotalAmount(0);
      setDiscountACHTotal(0);
    }
  }, [data]);

  const tableSettings = `--data-table-library_grid-template-columns:  7% 7% 7% 8% 9% 13% 10% 13% 7% 9% 10%;
      @media (max-width: 992px) {
          --data-table-library_grid-template-columns:  17% 15% 15% 20% 22% 20% 26% 27% 20% 22% 25%;
      }
      @media (max-width: 500px) {
          --data-table-library_grid-template-columns:  20% 25% 25% 30% 22% 20% 26% 27% 20% 22% 25%;
      }`;
  /**
  * defines two decimal places for parseFloat functions
  */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };
  const convertedLocale = i18n.language;
  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        AMOUNT: (array) => [...array].sort((a, b) => floatCompare(a.AMOUNT, b.AMOUNT)),
        CARD_NUMBER: (array) => [...array].sort((a, b) => intCompare(a.CARD_NUMBER, b.CARD_NUMBER)),
        DISCOUNT_ACH: (array) => [...array].sort((a, b) => floatCompare(a.DISCOUNT_ACH, b.DISCOUNT_ACH)),
        DISCOUNT_PERCENT: (array) => [...array].sort((a, b) => floatCompare(a.DISCOUNT_PERCENT, b.DISCOUNT_PERCENT)),
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        ENTITY_NAME: (array) => [...array].sort((a, b) => a.ENTITY_NAME.localeCompare(b.ENTITY_NAME)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        STORE: (array) => [...array].sort((a, b) => intCompare(a.STORE, b.STORE)),
        TRAN_CODE: (array) => [...array].sort((a, b) => intCompare(a.TRAN_CODE, b.TRAN_CODE)),
        TRANSACTION_DESCRIPTION: (array) => [...array].sort((a, b) => a['TRANSACTION DESCRIPTION'].localeCompare(b['TRANSACTION DESCRIPTION'])),
        TRANSACTION_TIME: (array) => [...array].sort((a, b) => a.TRANSACTION_TIME.localeCompare(b.TRANSACTION_TIME)),
      },
    },
  );
  /**
 * The columns and their corresponding data that will render in the table.
 */
  const COLUMNS = [
    { label: t('reporting.redemptions.entityName'), renderCell: (item) => item.ENTITY_NAME, sort: { sortKey: 'ENTITY_NAME' } },
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.store'), renderCell: (item) => item.STORE.padStart(10, '0'), sort: { sortKey: 'STORE' } },
    { label: t('reporting.redemptions.transactionCode'), renderCell: (item) => item.TRAN_CODE, sort: { sortKey: 'TRAN_CODE' } },
    { label: t('reporting.redemptions.description'), renderCell: (item) => item['TRANSACTION DESCRIPTION'], sort: { sortKey: 'TRANSACTION_DESCRIPTION' } },
    { label: t('misc.card'), renderCell: (item) => item.CARD_NUMBER, sort: { sortKey: 'CARD_NUMBER' } },
    { label: t('reporting.redemptions.transactionTime'), renderCell: (item) => item.TRANSACTION_TIME, sort: { sortKey: 'TRANSACTION_TIME' } },
    { label: t('reporting.redemptions.amount'), renderCell: (item) => parseFloat(item.AMOUNT).toLocaleString(convertedLocale, options), sort: { sortKey: 'AMOUNT' } },
    { label: t('reporting.redemptions.discountPercent'), renderCell: (item) => parseFloat(item.DISCOUNT_PERCENT).toLocaleString(convertedLocale, { maximumFractionDigits: 3, minimumFractionDigits: 3 }), sort: { sortKey: 'DISCOUNT_PERCENT' } },
    { label: t('reporting.redemptions.discountACHTotal'), renderCell: (item) => parseFloat(item.DISCOUNT_ACH).toLocaleString(convertedLocale, options), sort: { sortKey: 'DISCOUNT_ACH' } },
  ];

  const exportableColumns = [
    { accessor: (item) => item.ENTITY_NAME, name: t('reporting.redemptions.entityName') },
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.STORE.padStart(10, '0')}\t`, name: t('reporting.uiElements.store') },
    { accessor: (item) => item.TRAN_CODE, name: t('reporting.redemptions.transactionCode') },
    { accessor: (item) => item['TRANSACTION DESCRIPTION'], name: t('reporting.redemptions.description') },
    { accessor: (item) => `${item.CARD_NUMBER}\t`, name: t('misc.card') },
    { accessor: (item) => item.TRANSACTION_TIME, name: t('reporting.redemptions.transactionTime') },
    { accessor: (item) => parseFloat(item.AMOUNT).toLocaleString(convertedLocale, options), name: t('reporting.redemptions.amount') },
    { accessor: (item) => parseFloat(item.DISCOUNT_PERCENT).toLocaleString(convertedLocale, { maximumFractionDigits: 3, minimumFractionDigits: 3 }), name: t('reporting.redemptions.discountPercent') },
    { accessor: (item) => parseFloat(item.DISCOUNT_ACH).toLocaleString(convertedLocale, options), name: t('reporting.redemptions.discountACHTotal') },
  ];

  const csvSummary = [
    `"${t('reporting.redemptions.totalAmount')}: ${parseFloat(totalAmount).toLocaleString(convertedLocale, options)}"`,
    `\n"${t('reporting.redemptions.discountACHTotal')}: ${parseFloat(discountACHTotal).toLocaleString(convertedLocale, options)}"\n`,
  ];

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.redemptions.totalAmount')}: `}
              </span>
              <span>
                {parseFloat(totalAmount).toLocaleString(convertedLocale, options)}
              </span>
            </div>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.redemptions.discountACHTotal')}: `}
              </span>
              <span>
                {parseFloat(discountACHTotal).toLocaleString(convertedLocale, options)}
              </span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        columnWidths={tableSettings}
        reportName='Discount_Settlement'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />
    </>
  );
};

SMGR300.propTypes = {
  date: PropTypes.string.isRequired,
  smgr300Data: PropTypes.arrayOf(
    PropTypes.shape({
      AMOUNT: PropTypes.string,
      CARD: PropTypes.string,
      CARD_NUMBER: PropTypes.string,
      DISCOUNT_ACH: PropTypes.string,
      DISCOUNT_PERCENT: PropTypes.string,
      DIVISION: PropTypes.string,
      ENTITY_NAME: PropTypes.string,
      MERCHANT: PropTypes.string,
      STORE: PropTypes.string,
      TRAN_CODE: PropTypes.string,
      'TRANSACTION DESCRIPTION': PropTypes.string,
      TRANSACTION_TIME: PropTypes.string,
    }),
  ).isRequired,
};

export default SMGR300;
