import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../styles/scss/AdminDashboard.scss';

const AdminDashboard = () => {
  const { t } = useTranslation();
  return (
    <div id='admin-dashboard'>
      <header>
        <h1>{t('dashboard.adminDashboard.title')}</h1>
      </header>
      <h2>{t('dashboard.adminDashboard.adminTools')}</h2>
      <div id='admin-links'>
        <Link to='/emulation'>
          <div className='card'>
            <div className='card-body'>
              <i className='fa-solid fa-user-secret' />
            </div>
            <div className='card-footer'>
              {t('emulationMode.title')}
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AdminDashboard;
