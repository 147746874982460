import React, { useCallback } from 'react';
import Select from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { getValueFromPath } from '../../utils/arrays';

const FormReactSelectField = ({
  className,
  classNamePrefix,
  defaultValue,
  fieldClasses,
  fieldName,
  handleChange,
  icon,
  label,
  options,
  placeholder,
  styles,
  validationRules,
  ...selectProps
}) => {
  const {
    control,
    formState,
    setValue,
    watch,
  } = useFormContext();

  const currentValue = watch(fieldName);

  const handleOnChange = useCallback(({ value }) => {
    setValue(fieldName, value, { shouldDirty: true, shouldValidate: true });
    if (handleChange) handleChange(value);
  }, [fieldName, handleChange, setValue]);

  const { errors } = formState;
  const fieldError = getValueFromPath(errors, fieldName);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={fieldName}
      rules={validationRules}
      render={
        useCallback(({
          field: {
            onBlur,
            ref,
          },
        }) => (
          <div className={fieldClasses}>
            { label && (
              <label className='form-label' htmlFor={fieldName}>
                {label}
              </label>
            )}
            <div className='input-group'>
              {icon && (
                <span className='input-group-text'>{icon}</span>
              )}
              <Select
                {...selectProps}
                className={`${className || 'react-select'}`}
                classNamePrefix={`${classNamePrefix || 'react-select'}`}
                placeholder={placeholder}
                options={options}
                onBlur={onBlur}
                onChange={handleOnChange}
                ref={ref}
                styles={styles}
                value={options.find(option => option.value === currentValue) || null}
              />
            </div>
            <small className={`text-right form-text form-validation error message${fieldError ? ' active' : ''}`}>{fieldError?.message}</small>
          </div>
        ), [
          className,
          classNamePrefix,
          currentValue,
          fieldClasses,
          fieldError,
          fieldName,
          handleOnChange,
          icon,
          label,
          options,
          placeholder,
          selectProps,
          styles,
        ])
      }
    />
  );
};

FormReactSelectField.propTypes = {
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  defaultValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  fieldClasses: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  icon: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  })),
  placeholder: PropTypes.string,
  styles: PropTypes.shape({
    clearIndicator: PropTypes.objectOf(PropTypes.string),
    container: PropTypes.objectOf(PropTypes.string),
    control: PropTypes.objectOf(PropTypes.string),
    dropdownIndicator: PropTypes.objectOf(PropTypes.string),
    indicatorsContainer: PropTypes.objectOf(PropTypes.string),
    indicatorSeparator: PropTypes.objectOf(PropTypes.string),
    menu: PropTypes.objectOf(PropTypes.string),
    multiValue: PropTypes.objectOf(PropTypes.string),
    multiValueLabel: PropTypes.objectOf(PropTypes.string),
    multiValueRemove: PropTypes.objectOf(PropTypes.string),
    option: PropTypes.objectOf(PropTypes.string),
    placeholder: PropTypes.objectOf(PropTypes.string),
    singleValue: PropTypes.objectOf(PropTypes.string),
    valueContainer: PropTypes.objectOf(PropTypes.string),
  }),
  validationRules: PropTypes.shape({
    maxLength: PropTypes.number,
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    setValueAs: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
};

FormReactSelectField.defaultProps = {
  className: '',
  classNamePrefix: '',
  defaultValue: undefined,
  fieldClasses: 'form-react-select',
  handleChange: undefined,
  icon: undefined,
  label: '',
  options: [],
  placeholder: 'Select...',
  styles: {},
  validationRules: {},
};

export default FormReactSelectField;
