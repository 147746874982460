import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import ResetPassword from './ResetPassword';
import RegisterMFA from './MFA/RegisterMFA';
import ResetMFA from './MFA/ResetMFA';
import SecurityQuestionSetup from './MFA/SecurityQuestionSetup';
import SignonMFA from './MFA/SignonMFA';
import LoginHeader from '../../components/Header/LoginHeader';
import FormInputField from '../../components/Form/FormInputField';
import LocaleSelector from '../../components/Header/LocaleSelector';
import '../../styles/scss/Login.scss';
import { maxLengths } from '../../helpers/validation';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import useLogin from '../../hooks/useLogin';
import '../../styles/scss/Header.scss';

const Login = () => {
  const {
    awaitingSSO,
    displayRegisterMFA,
    displayResetMFA,
    displayResetPassword,
    displaySecurityQuestionSetup,
    displaySignonMFA,
    getUserDetails,
    handleCancelRegisterMFA,
    handleCancelResetMFA,
    handleCancelResetPassword,
    handleCancelSecurityQuestionSetup,
    handleCancelSignonMFA,
    handleLoginFailure,
    handleRegisterMFASuccess,
    handleResetPasswordFailure,
    handleResetPasswordSuccess,
    handleResetToken,
    handleResetTokenSuccess,
    handleSecurityQuestionSetupSuccess,
    handleSignonMFASuccess,
    logIn,
    isSSO,
    resetQuestions,
    setDisplayResetPassword,
    userDetailsRetrieved,
  } = useLogin();

  const { t } = useTranslation();
  const methods = useForm();
  const { getValues, handleSubmit } = methods;
  const formValues = getValues();

  const nonSSOUserRetrieved = userDetailsRetrieved && !isSSO;

  const onSubmit = async data => {
    const { username } = data;
    const trimmedPayload = {
      ...data,
      username: username.trim(),
    };

    if (nonSSOUserRetrieved) logIn(trimmedPayload);
    if (!userDetailsRetrieved) {
      await getUserDetails(trimmedPayload.username);
    }
  };

  const {
    accessManagement: { SIGNON_MAX_LENGTH },
    login: { PASSWORD_MAX_LENGTH },
  } = maxLengths;

  const loginComponent = (
    <>
      <header id='login-header'>
        <div id='header-links'>
          <div className='header-menu'>
            <LocaleSelector />
          </div>
        </div>
      </header>
      <div className='login container'>
        <RegisterMFA
          cancelAction={handleCancelRegisterMFA}
          onFailure={handleLoginFailure}
          onSuccess={handleRegisterMFASuccess}
          visible={displayRegisterMFA}
        />
        <ResetMFA
          cancelAction={handleCancelResetMFA}
          resetQuestions={resetQuestions}
          onFailure={handleLoginFailure}
          onSuccess={handleResetTokenSuccess}
          visible={displayResetMFA}
        />
        <ResetPassword
          cancelAction={handleCancelResetPassword}
          onFailure={handleResetPasswordFailure}
          onSuccess={handleResetPasswordSuccess}
          visible={displayResetPassword}
        />
        <SignonMFA
          cancelAction={handleCancelSignonMFA}
          onFailure={handleLoginFailure}
          onSuccess={handleSignonMFASuccess}
          resetAction={handleResetToken}
          visible={displaySignonMFA}
        />
        <SecurityQuestionSetup
          cancelAction={handleCancelSecurityQuestionSetup}
          onFailure={handleLoginFailure}
          onSuccess={handleSecurityQuestionSetupSuccess}
          visible={displaySecurityQuestionSetup}
        />
        {!awaitingSSO && <LoginHeader />}
        {
          // prevent Login form from rendering to avoid submitting this for when Enter is pressed in a modal form
          !awaitingSSO
          && !displayRegisterMFA
          && !displayResetMFA
          && !displayResetPassword
          && !displaySignonMFA
          && !displaySecurityQuestionSetup
          && (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                {(!userDetailsRetrieved)
                  && (
                    <FormInputField
                      fieldClasses='login-field'
                      fieldName='username'
                      key='username'
                      icon={<i className='fa-solid fa-user' />}
                      placeholder={t('form.login.username.label')}
                      type='text'
                      validationRules={{
                        maxLength: {
                          message: t('form.fieldExceedsMaxLengthGeneric'),
                          value: SIGNON_MAX_LENGTH,
                        },
                        required: t('form.login.username.validationMessage'),
                      }}
                    />
                  )}
                {nonSSOUserRetrieved
                  && (
                    <>
                      <h2>
                        <span>{`${t('form.login.username.label')}: ${formValues.username}`}</span>
                      </h2>
                      <FormInputField
                        autocomplete='off'
                        fieldClasses='login-field'
                        fieldName='password'
                        icon={<i className='fa-solid fa-lock' />}
                        key='password'
                        placeholder={t('form.login.password.label')}
                        type='password'
                        validationRules={{
                          maxLength: {
                            message: t('form.fieldExceedsMaxLengthGeneric'),
                            value: PASSWORD_MAX_LENGTH,
                          },
                          required: t('form.login.password.validationMessage'),
                        }}
                      />
                    </>
                  )}
                <div id='submit-button'>
                  <button className='btn' type='submit'>
                    <i className='fa-solid fa-right-to-bracket' />
                  </button>
                </div>
              </form>
              {nonSSOUserRetrieved
                && (
                  <div className='login-controls'>
                    <button className='button-link' onClick={() => setDisplayResetPassword(!displayResetPassword)} type='button'>{t('form.login.password.reset.linkText')}</button>
                  </div>
                )}
            </FormProvider>
          )
        }
      </div>
    </>
  );
  return awaitingSSO
    ? elastic
    : loginComponent;
};

export default Login;
