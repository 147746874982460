import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import FormInputField from '../../components/Form/FormInputField';
import FormReactSelectField from '../../components/Form/FormReactSelectField';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { emailAddressPattern, usernamePattern, maxLengths } from '../../helpers/validation';
import FormInputLookup from '../../components/Form/FormInputLookup';
import '../../styles/scss/UserFields.scss';
import PasswordDisclaimer from '../../components/PasswordDisclaimer/PasswordDisclaimer';

const UserFields = ({ isNewUser, signonId }) => {
  const { t } = useTranslation();

  const {
    sessionData: { userId: editorUserId, groupId: editorGroupId },
  } = useContext(GlobalContext);

  const {
    getValues,
  } = useFormContext();

  const {
    details: {
      groupId: groupIdToEdit,
      userId: userIdToEdit,
    } = {},
  } = getValues();

  const ADMIN_GROUP = 'FMADMIN';

  const userToEditIsAdmin = groupIdToEdit === ADMIN_GROUP;
  const editorIsAdmin = editorGroupId === ADMIN_GROUP;
  const editingSelf = editorUserId === userIdToEdit && editorGroupId === groupIdToEdit;
  const canEdit = (editorIsAdmin && !userToEditIsAdmin) || editingSelf;

  const localeOptions = ['en_US', 'en_CA', 'fr_CA'].map((locale) => ({
    label: locale,
    value: locale,
  }));

  const emailAddress = getValues('details.emailAddress');
  const locale = getValues('details.locale');
  const phoneNumber = getValues('details.phoneNumber');
  const username = getValues('details.userName');
  const usernameFieldEnabled = !!emailAddress && !!locale && !!phoneNumber && !!username && editorIsAdmin;

  const {
    accessManagement: {
      EMAIL_MAX_LENGTH,
      NAME_MAX_LENGTH,
      PHONE_MAX_LENGTH,
      SIGNON_MAX_LENGTH,
    },
  } = maxLengths;

  const isLockedField = !isNewUser && (userToEditIsAdmin || !canEdit);

  return (
    <>
      <div className='row field-group'>
        <div className='col-12'>
          <h6>{t('users.userDetails')}</h6>
        </div>
        <FormInputField
          fieldClasses='col-12 col-sm-6 user-field'
          fieldName='details.userName'
          icon={(
            <>
              <i
                alt={t('form.user.fullName')}
                className='fa-solid fa-circle-user'
                data-tooltip-id='form.user.fullName-tooltip'
                data-tooltip-content={t('users.userFields.tooltips.fullName')}
                data-tooltip-place='top'
              />
              <Tooltip id='form.user.fullName-tooltip' />
            </>
          )}
          key='firstName'
          placeholder={t('form.user.fullName')}
          readOnly={isLockedField}
          type='text'
          showCharacterCount={!isLockedField}
          maxLength={NAME_MAX_LENGTH}
          validationRules={{
            maxLength: {
              message: t('form.fieldExceedsMaxLength', { maxLength: NAME_MAX_LENGTH }),
              value: NAME_MAX_LENGTH,
            },
            required: t('form.required'),
          }}
        />
        <FormInputField
          fieldClasses='col-12 col-sm-6 user-field'
          fieldName='details.phoneNumber'
          icon={(
            <>
              <i
                alt={t('users.phoneNumber')}
                className='fa-solid fa-phone'
                data-tooltip-id='details.phoneNumber-tooltip'
                data-tooltip-content={t('users.userFields.tooltips.phoneNumber')}
                data-tooltip-place='top'
              />
              <Tooltip id='details.phoneNumber-tooltip' />
            </>
          )}
          key='phoneNumber'
          placeholder={t('users.phoneNumber')}
          readOnly={isLockedField}
          type='tel'
          showCharacterCount={!isLockedField}
          maxLength={PHONE_MAX_LENGTH}
          validationRules={{
            maxLength: {
              message: t('form.fieldExceedsMaxLength', { maxLength: PHONE_MAX_LENGTH }),
              value: PHONE_MAX_LENGTH,
            },
            required: t('form.required'),
          }}
        />
        <FormInputField
          fieldClasses='col-12 col-sm-6 user-field'
          fieldName='details.emailAddress'
          icon={(
            <>
              <i
                alt={t('form.user.emailAddress')}
                className='fa-solid fa-at'
                data-tooltip-id='form.user.emailAddress-tooltip'
                data-tooltip-content={t('users.userFields.tooltips.emailAddress')}
                data-tooltip-place='top'
              />
              <Tooltip id='form.user.emailAddress-tooltip' />
            </>
          )}
          key='emailAddress'
          placeholder={t('form.user.emailAddress')}
          type='email'
          readOnly={isLockedField}
          showCharacterCount={!isLockedField}
          maxLength={EMAIL_MAX_LENGTH}
          validationRules={{
            maxLength: {
              message: t('form.fieldExceedsMaxLength', { maxLength: EMAIL_MAX_LENGTH }),
              value: EMAIL_MAX_LENGTH,
            },
            pattern: {
              message: t('form.user.invalidEmail'),
              value: emailAddressPattern,
            },
            required: t('form.required'),
          }}
        />
        <FormReactSelectField
          disabled={!isNewUser && !canEdit}
          fieldName='details.locale'
          fieldClasses='col-12 col-sm-6 user-field'
          icon={(
            <>
              <i
                alt={t('users.userFields.tooltips.locale')}
                className='fa-solid fa-globe'
                data-tooltip-id='details.locale-tooltip'
                data-tooltip-content={t('users.userFields.tooltips.locale')}
                data-tooltip-place='top'
              />
              <Tooltip id='details.locale-tooltip' />
            </>
          )}
          key='locale'
          options={localeOptions}
          validationRules={{
            required: t('form.required'),
          }}
        />
        <PasswordDisclaimer />
      </div>
      <div className='row field-group'>
        <div className='col-12'>
          <h6>{t('users.username.title')}</h6>
        </div>
        <FormInputLookup
          defaultValue={signonId || getValues('details.userName')?.replace(/ /g, '.').toLowerCase()}
          disabled={!usernameFieldEnabled}
          fieldClasses='col-12 col-sm-6 user-field'
          fieldName='details.signonId'
          icon={(
            <>
              <i
                alt={t('users.username.title')}
                className='fa-solid fa-user'
                data-tooltip-id='users.username.title-tooltip'
                data-tooltip-content={t('users.userFields.tooltips.signonId')}
                data-tooltip-place='top'
              />
              <Tooltip id='users.username.title-tooltip' />
            </>
          )}
          key='signonId'
          lookupAPI='/api/security/validateUsername'
          lookupErrorMessage={t('users.username.validation.failure')}
          lookupAvailableMessage={t('users.username.availability.isAvailable')}
          lookupNoAvailableMessage={t('users.username.availability.isNotAvailable')}
          placeholder={t('users.username.placeholder')}
          readOnly={!isNewUser || !usernameFieldEnabled}
          type='text'
          maxLength={SIGNON_MAX_LENGTH}
          showCharacterCount={isNewUser && usernameFieldEnabled}
          validationRules={{
            maxLength: {
              message: t('form.fieldExceedsMaxLength', { maxLength: SIGNON_MAX_LENGTH }),
              value: SIGNON_MAX_LENGTH,
            },
            pattern: {
              message: t('form.user.invalidUsername'),
              value: usernamePattern,
            },
            required: t('form.required'),
          }}
        />
      </div>
    </>
  );
};

UserFields.propTypes = {
  isNewUser: PropTypes.bool,
  signonId: PropTypes.string,
};

UserFields.defaultProps = {
  isNewUser: false,
  signonId: null,
};

export default UserFields;
