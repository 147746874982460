import React from 'react';
import '../../styles/scss/LoadingAnimations.scss';

export const carousel = <div className='loading-animation'><div className='dot-carousel' /></div>;

export const colliding = <div className='loading-animation'><div className='dot-collision' /></div>;

export const elastic = <div className='loading-animation'><div className='dot-elastic' /></div>;

export const falling = <div className='loading-animation'><div className='dot-falling' /></div>;

export const fire = <div className='loading-animation'><div className='dot-fire' /></div>;

export const flashing = <div className='loading-animation'><div className='dot-flashing' /></div>;

export const floating = <div className='loading-animation'><div className='dot-floating' /></div>;

export const pulsing = <div className='loading-animation'><div className='dot-pulse' /></div>;

export const square = <div className='loading-animation'><div className='dot-square' /></div>;

export const spin = <div className='loading-animation'><div className='dot-spin' /></div>;

export const typing = <div className='loading-animation'><div className='dot-typing' /></div>;

export const windmill = <div className='loading-animation'><div className='dot-windmill' /></div>;
