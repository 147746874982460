import React, { useContext } from 'react';
import { GlobalContext } from '../../context/Global/GlobalContext';
import '../../styles/scss/SidebarToggle.scss';

const SidebarToggle = () => {
  const {
    sidebarToggled,
    setSidebarToggled,
  } = useContext(GlobalContext);

  return (
    <div className='menu-toggle'>
      <button
        className={`${sidebarToggled ? 'toggled' : ''}`}
        onClick={() => setSidebarToggled(!sidebarToggled)}
        type='button'
      >
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};

export default SidebarToggle;
