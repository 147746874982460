import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const AuthenticationContext = createContext({});

export const AuthenticationProvider = ({ children }) => {
  const storedContext = sessionStorage.getItem('GlobalContext');
  const [authenticated, setAuthenticated] = useState(storedContext ? JSON.parse(storedContext).userLoggedIn : false);

  /**
 *
 * @param {string} path API path to be called (e.g., /api/authentication/login)
 * @param {Object} data Object to be sent in the body of the request.
 */
  const callAPI = async (path, body = {}, method = 'post', alwaysReturnResponse = false) => {
    try {
      const config = {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method,
      };
      const response = await (method === 'post' ? axios.post(path, body, config) : axios.get(path, config));
      const { data, status, statusText } = response;

      return { data, status, statusText };
    } catch (error) {
      const {
        response,
      } = error;
      const {
        data: {
          error: errorMessage,
          message = '',
        },
        status,
      } = response;
      if (status === 401) {
        setAuthenticated(false);
        return response;
      }
      if (status === 403) {
        return response;
      }
      if (status === 423) {
        setAuthenticated(false);
        return response;
      }

      if (alwaysReturnResponse) {
        return response;
      }
      throw new Error(errorMessage || `${status}\n${message}`);
    }
  };

  const providerValue = useMemo(() => ({ authenticated, callAPI, setAuthenticated }), [authenticated]);
  return (
    <AuthenticationContext.Provider value={providerValue}>
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthenticationContext;
