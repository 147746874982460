import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context/Global/GlobalContext';
import SidebarItem from './SidebarItem';
import '../../styles/scss/Sidebar.scss';

const Sidebar = () => {
  const {
    notifications,
    sidebarToggled,
    setSidebarToggled,
    userPrivileges,
    isAdmin,
    emulationMode: {
      read: emulationEnabled = false,
    },
    emulatedUser = null,
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const emulatingAdmin = emulationEnabled && emulatedUser?.isAdmin;
  const adminAccess = isAdmin || emulatingAdmin;

  // reporting tab only available if you're an admin or have at least one store (or emulating a user that satisfies these conditions)
  const hasReporting = adminAccess || !!userPrivileges?.length;

  const CARD_ORDER_ENTRY_PRIVILEGE = 'CARD_ORDERING_PLACE_ORDERS';
  const hasCardOrderingPrivilege = userPrivileges.some(({
    privileges,
  }) => privileges?.includes(CARD_ORDER_ENTRY_PRIVILEGE));
  const canOrderCards = adminAccess || hasCardOrderingPrivilege;

  return (
    <nav className={`${sidebarToggled ? 'toggled' : ''}`} id='sidebar'>
      <ul>
        <SidebarItem icon='fa-solid fa-home' path='/' title={t('site.sidebar.dashboard')} />
        {notifications.length && <SidebarItem icon='fa-solid fa-circle-exclamation' path='/notifications' title={t('site.sidebar.notifications')} />}
        {hasReporting && <SidebarItem icon='fa-solid fa-line-chart' path='/reporting' title={t('site.sidebar.reporting')} />}
        {isAdmin && <SidebarItem icon='fa-solid fa-shield' path='/access/users' title={t('site.sidebar.accessManagement.title')} />}
        {canOrderCards && <SidebarItem icon='fa-solid fa-credit-card' path='/card-ordering/entry' title={t('site.sidebar.cardOrdering.orderEntry')} />}
        {emulationEnabled && <SidebarItem icon='fa-solid fa-user-secret' path='/emulation' title={t('site.sidebar.emulationMode')} />}
      </ul>
      <button onClick={() => setSidebarToggled(!sidebarToggled)} type='button'>
        <i className={`fa-solid fa-angles-${sidebarToggled ? 'left' : 'right'}`} />
      </button>
    </nav>

  );
};

export default Sidebar;
