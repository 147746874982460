import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Link, useNavigate } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';
import '../../styles/scss/CardOrderCheckout.scss';
import '../../styles/scss/StyledSelect.scss';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { getReadableCountryAndState, translateFromCodes } from '../../utils/codes';
import ProductCard from '../../components/CardOrdering/ProductCard';
import { maxLengths } from '../../helpers/validation';
import CharacterCounter from '../../components/Form/CharacterCounter';
import { buildCardOrderingStoreListPayload, formatCurrency, formatCardOrderingStoreList } from '../../utils/helpers';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';

const {
  cardOrdering: {
    CUSTOMER_PO_MAX_LENGTH,
  },
} = maxLengths;

const CardOrderCheckout = () => {
  const { t, i18n: { language: currentLocale = 'en-US' } = {} } = useTranslation();
  const { callAPI } = useAuthentication();
  const {
    cart,
    currencyDetails,
    invalidProductsInCart,
    resetOrderContext,
    selectedStore,
    refreshCart,
    refreshStore,
  } = useContext(CardOrderingContext);

  const {
    codes,
    emulatedUser,
    emulationMode: {
      read: emulationEnabled = false,
    },
    isAdminCreatedUser,
  } = useContext(GlobalContext);

  const [products, setProducts] = useState([]);
  const [poNumber, setPoNumber] = useState('');
  const navigate = useNavigate();
  const [refreshedCart, setRefreshedCart] = useState(false);
  const [refreshedStore, setRefreshedStore] = useState(false);
  const [carrierService, setCarrierService] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const carrierCode = selectedStore?.carrier;
  const { businessLogicType: carrierBlt } = codes?.CARRIERID?.find(({ value }) => value === carrierCode) || {};

  const carrierServiceCode = selectedStore?.carrierService;
  const carrierServices = codes[`CARRIER_SERVICE_${carrierBlt}`]?.map(({ value }) => ({
    label: translateFromCodes(codes, `CARRIER_SERVICE_${carrierBlt}`, `CARRIER.CARRIER_SERVICE_${carrierBlt}`, value),
    value,
  })) || [];

  useEffect(() => {
    if (!carrierService && carrierServiceCode) {
      setCarrierService(carrierServiceCode);
    }
  }, [carrierServiceCode, carrierService]);

  const selectedStoreCimsId = selectedStore?.value;

  useEffect(() => {
    const checkStore = async () => {
      try {
        setIsLoading(true);

        const payload = buildCardOrderingStoreListPayload(isAdminCreatedUser, emulationEnabled, emulatedUser);
        const {
          data: {
            resultList = [],
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/stores/getStoreList', payload, 'post', true);

        if (status !== 200 || !['1000', '1001'].includes(messageReturnCode)) {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }

        const formattedResults = formatCardOrderingStoreList(resultList);
        refreshStore(formattedResults);
      } catch ({ message }) {
        const errMsg = t('dashboard.failedToRetrieveStores', {
          message: message || t('site.unknownErrorOccurred'),
        });
        displayErrorNotification(errMsg);
      } finally {
        setIsLoading(false);
        setRefreshedStore(true);
      }
    };

    if (selectedStoreCimsId && !refreshedStore && cart?.length) {
      checkStore();
    }
  }, [callAPI, refreshStore, selectedStoreCimsId, cart, t, emulatedUser,
    emulationEnabled, refreshedStore, refreshedCart, isAdminCreatedUser]);

  const shippingAddress = selectedStore?.shippingAddress || {};

  const {
    address1 = '',
    address2 = '',
    city = '',
    contactName = '',
    contactPhone = '',
    countryCode,
    postalCode = '',
    stateProvince,
    storePrimaryEmail = '',
  } = shippingAddress;

  const { readableCountry, readableStateProvince } = getReadableCountryAndState(countryCode, stateProvince, codes);

  useEffect(() => {
    const getStoreProductInventory = async () => {
      try {
        setIsLoading(true);
        const {
          data: {
            resultList = [],
            messageReturnCode,
          } = {},
          status,
          statusText,
        } = await callAPI('/api/cardOrdering/getStoreProductInventory', {
          includeDetachedProductsWithInventory: false,
          includeNotOrderedProducts: true,
          inWarehouseOnly: true,
          productTypeFilter: -1,
          skuFilter: null,
          storeId: selectedStoreCimsId,
          usageDays: -1,
        }, 'post', true);
        if (status !== 200 || !['1000', '1001'].includes(messageReturnCode)) {
          const errMsg = messageReturnCode || `${status} ${statusText}`;
          throw Error(errMsg);
        }
        setProducts(resultList);
        refreshCart(resultList);
      } catch ({ message }) {
        const errMsg = t('cardOrdering.errors.failedToRetrieveProducts', {
          message: message || t('site.unknownErrorOccurred'),
        });
        displayErrorNotification(errMsg);
      } finally {
        setIsLoading(false);
        setRefreshedCart(true);
      }
    };

    if (selectedStoreCimsId && !refreshedCart && refreshedStore && cart?.length) {
      getStoreProductInventory();
    }
  }, [selectedStoreCimsId, callAPI, refreshCart, cart, t, refreshedStore, refreshedCart]);

  const onSelectCarrierService = useCallback(({ value }) => setCarrierService(value), [setCarrierService]);

  const createOrder = useCallback(async () => {
    try {
      setIsSubmitting(true);
      const orderLineItems = {};
      cart.forEach(({ productId: cartProductId, quantityInCart }) => {
        const { unitSize, sku, skuDescription } = products?.find(({ productId }) => productId === cartProductId) || {};
        if (!unitSize) {
          throw Error(t('cardOrdering.errors.invalidUnitSize', { label: `${sku} - ${skuDescription}` }));
        }
        orderLineItems[cartProductId] = unitSize * quantityInCart;
      });

      const orderType = codes?.ORDER_TYPE?.find(({ businessLogicType: blt }) => blt === 'MANUALENTRY')?.value;
      if (!orderType) {
        throw Error(t('cardOrdering.errors.invalidOrderType'));
      }

      const {
        data: {
          resultList = [],
          messageReturnCode,
        } = {},
        status,
        statusText,
      } = await callAPI('/api/cardOrdering/createOrder', {
        carrierService,
        orderLineItems,
        orderType,
        poNum: poNumber,
        storeId: selectedStore.value,
      }, 'post', true);

      if (status !== 200 || messageReturnCode !== '1000') {
        const errMsg = messageReturnCode || `${status} ${statusText}`;
        throw Error(errMsg);
      }

      const [{ orderId } = {}] = resultList;
      displaySuccessNotification(t('cardOrdering.orderCheckout.orderPlaced', { orderId }), false);
      resetOrderContext(null);
      setPoNumber('');
      setCarrierService(null);
      setProducts([]);
      navigate('/card-ordering/entry');
    } catch ({ message }) {
      const errMsg = t('cardOrdering.errors.orderSubmissionFailed', {
        message: message || t('site.unknownErrorOccurred'),
      });
      displayErrorNotification(errMsg, false);
    } finally {
      setIsSubmitting(false);
    }
  }, [cart, poNumber, callAPI, carrierService, selectedStore, navigate, t, products, codes, resetOrderContext]);

  const productIdsInCart = cart.map(({ productId }) => productId);

  const onChangePoNumber = (event) => setPoNumber(event.target.value);

  const cartSize = cart?.length;

  const subtotal = cart?.map(({ productId: cartProductId, quantityInCart }) => {
    const { skuUnitRate } = products.find(({ productId }) => cartProductId === productId) || {};
    const skuTotal = (skuUnitRate || 0) * quantityInCart;
    return skuTotal;
  }).reduce((acc, currValue) => acc + currValue, 0) || 0;

  const perOrderFee = selectedStore?.perOrderFee || 0;

  const orderTotal = subtotal + perOrderFee;

  const disableOrderSubmit = !cartSize || !refreshedCart || !refreshedStore
    || invalidProductsInCart.length || !carrierService || isSubmitting;

  const renderCheckout = () => {
    if (isLoading) {
      return <div>{elastic}</div>;
    }

    return cartSize > 0 && refreshedStore && refreshedCart ? (
      <>
        <h2 className='mb-2'>{t('cardOrdering.orderCheckout.cart', { cartSize })}</h2>
        <div className='panels'>
          <div className='cart-panel'>
            <div className='cart-contents'>
              {products.filter(({ productId }) => productIdsInCart.includes(productId)).map((product) => (
                <ProductCard
                  data={product}
                  key={product.productId}
                  trackErrors
                />
              ))}
            </div>
          </div>
          <div className='order-details'>
            <div className='mb-1'>
              <span className='label'>{`${t('reporting.uiElements.store')}:`}</span>
              <span className='store'>{selectedStore?.label}</span>
            </div>
            <div className='mb-1'>
              <span className='label'>{t('storeDetails.shippingAddress')}</span>
              <address>
                <div>{address1}</div>
                {address2 && (<div>{address2}</div>)}
                <div>{`${city}, ${readableStateProvince} ${postalCode}`}</div>
                <div>{readableCountry}</div>
              </address>
            </div>
            <div className='mb-3'>
              <span className='label'>{t('storeDetails.shippingContact')}</span>
              <address>
                <div>{contactName}</div>
                <div>{contactPhone}</div>
                <div>{storePrimaryEmail}</div>
              </address>
            </div>
            <div className='mb-1'>
              <span className='label'>{`${t('cardOrdering.orderCheckout.carrier')}:`}</span>
              {translateFromCodes(codes, 'CARRIERID', 'CARRIER.CARRIERID', carrierCode)}
            </div>
            <div className='mb-2'>
              <div className='mb-1'>
                <span className='label'>{t('cardOrdering.orderCheckout.carrierService')}</span>
              </div>
              <Select
                className='styled-select'
                classNamePrefix='styled-select'
                onChange={onSelectCarrierService}
                options={carrierServices}
                placeholder={t('cardOrdering.orderCheckout.carrierServicePlaceholder')}
                value={carrierServices?.find(({ value }) => carrierService === value) || null}
              />
            </div>
            <div className='mb-3'>
              <div className='flexLabel'>
                <span className='label'>{t('cardOrdering.orderCheckout.comment')}</span>
                <span className='optional'>{t('form.optional')}</span>
              </div>
              <input
                className='form-control mt-1'
                maxLength={CUSTOMER_PO_MAX_LENGTH}
                value={poNumber}
                onChange={onChangePoNumber}
              />
              <CharacterCounter
                inputValue={poNumber}
                maxLength={CUSTOMER_PO_MAX_LENGTH}
              />
            </div>
            <div className='mb-1'>
              <span className='label'>{`${t('cardOrdering.orderCheckout.subtotal')}:`}</span>
              <span className='store'>{formatCurrency(subtotal, currentLocale, currencyDetails)}</span>
            </div>
            <div className='mb-1'>
              <span className='label'>{`${t('cardOrdering.orderCheckout.processingFee')}:`}</span>
              <span className='store'>{formatCurrency(perOrderFee, currentLocale, currencyDetails)}</span>
            </div>
            <div className='mb-1'>
              <span className='label'>{`${t('cardOrdering.orderCheckout.orderTotal')}:`}</span>
              <span className='store'>
                {formatCurrency(orderTotal, currentLocale, currencyDetails)}
              </span>
            </div>
            { emulationEnabled ? (
              <div className='emulation-order-msg py-1'>
                <i className='fa-solid fa-exclamation-circle me-2' />
                <span>
                  {t('cardOrdering.orderCheckout.emulationRestrictionMsg')}
                </span>
              </div>
            ) : (
              <button
                className='btn btn-primary order-btn mt-2'
                type='button'
                disabled={disableOrderSubmit}
                onClick={createOrder}
              >
                {t('cardOrdering.orderCheckout.placeOrder')}
              </button>
            )}
          </div>
        </div>
      </>
    ) : <p>{t('cardOrdering.orderCheckout.cartIsEmpty')}</p>;
  };

  return (
    <div className='container' id='card-order-checkout'>
      <header className='mb-3'>
        <h1>{t('cardOrdering.orderCheckout.title')}</h1>
      </header>
      { renderCheckout() }
      <Link to='/card-ordering/entry'>
        <button className='btn btn-primary mt-2' type='button'>
          {t('cardOrdering.orderCheckout.backToOrderEntry')}
        </button>
      </Link>
    </div>
  );
};

export default CardOrderCheckout;
