export default (state, action) => {
  const {
    payload = {},
    type,
  } = action;

  const {
    codes,
    emulationMode,
    isAdmin,
    isAdminCreatedUser,
    notifications,
    sessionData,
    sidebarToggled,
    emulatedUser,
    userLoggedIn,
    userPrivileges,
  } = payload;

  switch (type) {
  case 'SET_CODES':
    return {
      ...state,
      codes,
    };
  case 'SET_EMULATED_USER':
    return {
      ...state,
      emulatedUser,
    };
  case 'SET_EMULATION_MODE':
    return {
      ...state,
      emulationMode,
    };
  case 'SET_IS_ADMIN':
    return {
      ...state,
      isAdmin,
    };
  case 'SET_IS_ADMIN_CREATED_USER':
    return {
      ...state,
      isAdminCreatedUser,
    };
  case 'SET_NOTIFICATIONS':
    return {
      ...state,
      notifications,
    };
  case 'SET_SESSION':
    return {
      ...state,
      sessionData,
    };
  case 'SET_SIDEBAR_TOGGLED':
    return {
      ...state,
      sidebarToggled,
    };
  case 'SET_USER_LOGGED_IN':
    return {
      ...state,
      userLoggedIn,
    };
  case 'SET_USER_PRIVILEGES':
    return {
      ...state,
      userPrivileges,
    };
  default:
    return state;
  }
};
