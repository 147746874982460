import {
  React, useEffect, useState, useCallback, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@table-library/react-table-library/sort';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import '../../styles/scss/FranchiseManagement.scss';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateCompare, floatCompare, intCompare } from '../../helpers/reportingComparisons';
import storeListBuilder from '../../helpers/storeListBuilder';
import DisplayData from '../../components/Reporting/DisplayData';
import { ReportingContext } from '../../context/Reporting/ReportingContext';
import CustomFilter from '../../components/Reporting/CustomFilter';

const OtherFees = ({
  smgr270Data, date,
}) => {
  const [otherFeesTotal, setOtherFeesTotal] = useState(0);
  const { selectedStores, setSelectedStores } = useContext(ReportingContext);

  const { t, i18n } = useTranslation();

  const storeList = storeListBuilder(smgr270Data, 'DIV_STORE_NUM');
  const reportData = smgr270Data;
  const data = useMemo(() => ({
    nodes: selectedStores.length > 0
      ? reportData.filter((node) => selectedStores.includes(node.DIV_STORE_NUM))
      : reportData,
  }), [reportData, selectedStores]);

  // Calculates the total amount for other fees
  useEffect(() => {
    const {
      nodes = [],
    } = data;
    if (nodes.length > 0) {
      const tempOtherFeesTotal = nodes
        .map(item => parseFloat(item.AMOUNT))
        .reduce((accumulator, current) => accumulator + current);
      setOtherFeesTotal(tempOtherFeesTotal);
    } else {
      setOtherFeesTotal(0);
    }
  }, [data]);

  /**
   * defines two decimal places for parseFloat functions
   */
  const options = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const convertedLocale = i18n.language;

  /**
   * Adds sort functionality to the table. Defines sort functions for each column.
   */
  const sort = useSort(
    data,
    {
      onChange: null,
    },
    {
      sortFns: {
        AMOUNT: (array) => [...array].sort((a, b) => floatCompare(a.AMOUNT, b.AMOUNT)),
        AMOUNT_PER_STORE: (array) => [...array].sort(
          (a, b) => floatCompare(a.AMOUNT_PER_STORE, b.AMOUNT_PER_STORE),
        ),
        DATE: (array) => [...array].sort((a, b) => dateCompare(a.DATE, b.DATE)),
        DESCRIPTION: (array) => [...array].sort((a, b) => a.DESCRIPTION.localeCompare(b.DESCRIPTION)),
        DIV_STORE_NUM: (array) => [...array].sort((a, b) => intCompare(a.DIV_STORE_NUM, b.DIV_STORE_NUM)),
        DIVISION: (array) => [...array].sort((a, b) => intCompare(a.DIVISION, b.DIVISION)),
        MERCHANT: (array) => [...array].sort((a, b) => intCompare(a.MERCHANT, b.MERCHANT)),
        STORE_COUNT: (array) => [...array].sort((a, b) => intCompare(a.STORE_COUNT, b.STORE_COUNT)),
      },
    },
  );

  /**
   * The columns and their corresponding data that will render in the table.
   */
  const COLUMNS = [
    { label: t('reporting.uiElements.merchant'), renderCell: (item) => item.MERCHANT, sort: { sortKey: 'MERCHANT' } },
    { label: t('reporting.uiElements.division'), renderCell: (item) => item.DIVISION, sort: { sortKey: 'DIVISION' } },
    { label: t('reporting.uiElements.store'), renderCell: (item) => item.DIV_STORE_NUM.padStart(10, '0'), sort: { sortKey: 'DIV_STORE_NUM' } },
    { label: t('reporting.uiElements.date'), renderCell: (item) => moment(item.DATE).format('MM/DD/YYYY'), sort: { sortKey: 'DATE' } },
    { label: t('reporting.otherFees.description'), renderCell: (item) => item.DESCRIPTION, sort: { sortKey: 'DESCRIPTION' } },
    { label: t('reporting.otherFees.storeCount'), renderCell: (item) => item.STORE_COUNT, sort: { sortKey: 'STORE_COUNT' } },
    { label: t('reporting.otherFees.amountPerStore'), renderCell: (item) => parseFloat(item.AMOUNT_PER_STORE).toLocaleString(convertedLocale, options), sort: { sortKey: 'AMOUNT_PER_STORE' } },
    { label: t('reporting.otherFees.amount'), renderCell: (item) => parseFloat(item.AMOUNT).toLocaleString(convertedLocale, options), sort: { sortKey: 'AMOUNT' } },
  ];

  const exportableColumns = [
    { accessor: (item) => item.MERCHANT, name: t('reporting.uiElements.merchant') },
    { accessor: (item) => item.DIVISION, name: t('reporting.uiElements.division') },
    { accessor: (item) => `${item.DIV_STORE_NUM.padStart(10, '0')}\t`, name: t('reporting.uiElements.store') },
    { accessor: (item) => moment(item.DATE).format('MM/DD/YYYY'), name: t('reporting.uiElements.date') },
    { accessor: (item) => item.DESCRIPTION, name: t('reporting.otherFees.description') },
    { accessor: (item) => item.STORE_COUNT, name: t('reporting.otherFees.storeCount') },
    { accessor: (item) => parseFloat(item.AMOUNT_PER_STORE).toLocaleString(convertedLocale, options), name: t('reporting.otherFees.amountPerStore') },
    { accessor: (item) => parseFloat(item.AMOUNT).toLocaleString(convertedLocale, options), name: t('reporting.otherFees.amount') },
  ];

  const csvSummary = [
    `"${t('reporting.uiElements.otherChargesTotal')}: ${otherFeesTotal?.toLocaleString(convertedLocale, options)}"\n`,
  ];

  const typeahead = useCallback(selection => {
    setSelectedStores(selection);
  }, [setSelectedStores]);

  return (
    <>
      <div className='row'>
        <div className='col-12 col-lg-4'>
          <div className='report-summary'>
            <div className='item'>
              <span className='title'>
                {`${t('reporting.uiElements.otherChargesTotal')}: `}
              </span>
              <span>{otherFeesTotal?.toLocaleString(convertedLocale, options)}</span>
            </div>
          </div>
        </div>
      </div>
      {storeList.length > 1 ? (
        <>
          <div className='input-group'>
            <span className='input-group-text'>{t('reporting.uiElements.filterStore')}</span>
            <Typeahead
              options={storeList}
              id='filterByStore'
              multiple
              onChange={typeahead}
              selected={selectedStores}
            >
              {({ onClear, selected }) => (
                <div className='rbt-aux'>
                  {!!selected.length && <ClearButton id='clearTypeahead' onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
          <CustomFilter />
        </>
      ) : null}
      <DisplayData
        tableData={data}
        sortFunctions={sort}
        columnNames={COLUMNS}
        exportableColumns={exportableColumns}
        reportName='Other_Charges'
        reportDate={date}
        storeList={`${t('reporting.uiElements.stores')}: ${selectedStores?.length > 0 ? selectedStores.join(',') : 'ALL'}`}
        csvSummary={csvSummary}
      />
    </>
  );
};

OtherFees.propTypes = {
  date: PropTypes.string.isRequired,
  smgr270Data: PropTypes.arrayOf(
    PropTypes.shape({
      AMOUNT: PropTypes.string,
      AMOUNT_PER_STORE: PropTypes.string,
      DESCRIPTION: PropTypes.string,
      DIV_STORE_NUM: PropTypes.string,
      DIVISION: PropTypes.string,
      ENTITY_NAME: PropTypes.string,
      MERCHANT: PropTypes.string,
      PERIOD: PropTypes.string,
      STORE_COUNT: PropTypes.string,
    }),
  ).isRequired,
};

export default OtherFees;
