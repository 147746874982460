import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../styles/scss/UserCollection.scss';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context/Global/GlobalContext';

const UserCollection = ({ users = [] }) => {
  const {
    sessionData: { userId: loggedInUserId, groupId: loggedInGroupId },
  } = useContext(GlobalContext);

  const { t } = useTranslation();

  const sortedUsers = [...users];
  sortedUsers.sort((
    { details: { userName: nameA } },
    { details: { userName: nameB } },
  ) => {
    const lowerA = nameA.toLowerCase();
    const lowerB = nameB.toLowerCase();
    if (lowerA > lowerB) {
      return 1;
    }
    if (lowerA < lowerB) {
      return -1;
    }
    return 0;
  });

  return (
    <div className='user-collection'>
      {sortedUsers?.map(user => {
        const {
          details: {
            userName,
            userId,
            emailAddress,
            groupId,
            phoneNumber,
          },
        } = user;

        const ADMIN_GROUP = 'FMADMIN';
        const currentUserIsAdmin = loggedInGroupId === ADMIN_GROUP;
        const userIsAdmin = groupId === ADMIN_GROUP;

        const userLabels = [];

        const isCurrentUser = loggedInUserId === userId && loggedInGroupId === groupId;
        if (isCurrentUser) {
          userLabels.push('you');
        }

        userLabels.push(userIsAdmin ? 'admin' : 'user');

        const isLink = (currentUserIsAdmin && !userIsAdmin) || isCurrentUser;

        const userCard = (
          <>
            <div className='user-content'>
              <h6 className='user-name'>
                {userName}
              </h6>
              <div className='user-info'>
                {emailAddress}
              </div>
              <div className='user-info'>
                {phoneNumber}
              </div>
            </div>
            <div className='user-labels'>
              { userLabels.map(label => (
                <div key={`${userId}-${label}`} className={`user-label ${label}`}>
                  {t(`users.userLabel.${label}`)}
                </div>
              ))}
            </div>
          </>
        );

        return isLink ? (
          <Link
            className='user-card'
            key={`${userId}-link`}
            state={{ user }}
            to={isCurrentUser ? '/profile' : '/access/users/manage'}
          >
            {userCard}
          </Link>
        ) : <div className='user-card' key={`${userId}`}>{userCard}</div>;
      })}
    </div>
  );
};

UserCollection.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    details: PropTypes.shape({
      accessLevel: PropTypes.number,
      emailAddress: PropTypes.string,
      fullName: PropTypes.string,
      groupId: PropTypes.string,
      locale: PropTypes.string,
      originalEmail: PropTypes.string,
      phoneNumber: PropTypes.string,
      status: PropTypes.number,
      userId: PropTypes.string,
    }),
  })).isRequired,
};

export default UserCollection;
