import React, {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from '@table-library/react-table-library/theme';
import { getTheme } from '@table-library/react-table-library/baseline';
import { useSort } from '@table-library/react-table-library/sort';
import { useTranslation } from 'react-i18next';
import { usePagination } from '@table-library/react-table-library/pagination';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import Filter from '../../components/Filter/Filter';
import '../../styles/scss/UserSelection.scss';
import { GlobalContext } from '../../context/Global/GlobalContext';
import { displayErrorNotification, displaySuccessNotification } from '../../components/Notification/Notify';
import useAuthentication from '../../hooks/useAuthentication';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';
import Paginator from '../../components/Paginator/Paginator';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';

const {
  REACT_APP_ADMIN_GROUP,
} = process.env;

const UserSelection = () => {
  const {
    getUserPrivileges,
    emulatedUser = null,
    setEmulatedUser,
    setUserPrivileges,
  } = useContext(GlobalContext);

  const {
    resetOrderContext,
    removeOtherCartsOnEmulationEnd,
  } = useContext(CardOrderingContext);

  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const { t } = useTranslation();
  const { callAPI } = useAuthentication();
  const pagination = usePagination(users, {
    state: {
      page: 0,
      size: 15,
    },
  });

  const {
    state: {
      page: currentPage = 0,
      size: pageSize = 15,
      getTotalPages,
    } = {},
    fns: {
      onSetPage,
      onSetSize,
    } = {},
  } = pagination;

  const onPageChange = useCallback((newPage) => onSetPage(newPage), [onSetPage]);
  const onPageSizeChange = useCallback((newSize) => onSetSize(newSize), [onSetSize]);

  const navigate = useNavigate();

  const filterTypes = useMemo(() => [
    { label: t('users.userFields.tooltips.fullName'), value: 'userName' },
    { label: t('users.userFields.tooltips.signonId'), value: 'signonId' },
    { label: t('emulationMode.columns.userId'), value: 'userId' },
    { label: t('emulationMode.columns.groupId'), value: 'groupId' },
    { label: t('users.userFields.tooltips.emailAddress'), value: 'emailAddress' },
    { label: t('emulationMode.columns.userStatus'), value: 'userStatus' },
  ], [t]);

  const COLUMNS = [
    { label: t('users.userFields.tooltips.fullName'), renderCell: ({ userName }) => userName || '--', sort: { sortKey: 'userName' } },
    { label: t('users.userFields.tooltips.signonId'), renderCell: ({ signonId }) => signonId || '--', sort: { sortKey: 'signonId' } },
    { label: t('users.userFields.tooltips.emailAddress'), renderCell: ({ emailAddress }) => emailAddress || '--', sort: { sortKey: 'emailAddress' } },
    { label: t('emulationMode.columns.userId'), renderCell: ({ userId }) => userId || '--', sort: { sortKey: 'userId' } },
    { label: t('emulationMode.columns.groupId'), renderCell: ({ groupId }) => groupId || '--', sort: { sortKey: 'groupId' } },
    { label: t('emulationMode.columns.userStatus'), renderCell: ({ userStatus }) => userStatus || '--', sort: { sortKey: 'userStatus' } },
    {
      label: t('emulationMode.columns.actions'),
      renderCell: ({
        userId, groupId, signonId, userName,
      }) => (emulatedUser?.userId === userId && emulatedUser?.groupId === groupId ? (
        <div className='emulated-user'>{t('emulationMode.emulating')}</div>
      ) : (
        <>
          <button
            className='action-button btn btn-primary'
            data-tooltip-id='emulate-user'
            data-tooltip-content={t('emulationMode.buttons.emulateUser')}
            data-tooltip-place='left'
            key={`${userId}-${groupId}`}
            onClick={async () => {
              const emulatedUserIsAdmin = groupId === REACT_APP_ADMIN_GROUP;
              resetOrderContext(null);
              removeOtherCartsOnEmulationEnd();
              setUserPrivileges([]);
              setEmulatedUser({
                groupId,
                isAdmin: emulatedUserIsAdmin,
                signonId,
                userId,
                userName,
              });
              if (!emulatedUserIsAdmin) {
                await getUserPrivileges({ oboUser: { groupId, userId } });
              }
              displaySuccessNotification(t('emulationMode.confirmation', { userName }));
              navigate('/');
            }}
            type='submit'
          >
            <i className='fa-solid fa-user-secret' />
          </button>
          <Tooltip id='emulate-user' />
        </>
      )),
    },
  ];

  const sort = useSort(
    users,
    {
      onChange: () => onSetPage(0),
    },
    {
      sortFns: {
        emailAddress: (array) => [...array].sort((a, b) => a.emailAddress.localeCompare(b.emailAddress)),
        groupId: (array) => [...array].sort((a, b) => a.groupId.localeCompare(b.groupId)),
        signonId: (array) => [...array].sort((a, b) => a.signonId.localeCompare(b.signonId)),
        userId: (array) => [...array].sort((a, b) => a.userId.localeCompare(b.userId)),
        userName: (array) => [...array].sort((a, b) => a.userName.localeCompare(b.userName)),
        userStatus: (array) => [...array].sort((a, b) => a.userStatus.localeCompare(b.userStatus)),
      },
    },
  );

  const theme = useTheme([
    getTheme(),
    {
      BaseCell: `
        &:last-of-type {
          padding: 0.5rem 1rem;
          text-align: center;
        }
      `,
      Table: `
      --data-table-library_grid-template-columns:  repeat(6, minmax(0, 1fr)) auto;
      @media (max-width: 992px) {
          --data-table-library_grid-template-columns:  repeat(7, 1fr);
      `,
    },
  ]);

  const onSubmit = useCallback(async (activeFilters) => {
    try {
      onSetPage(0);
      setHasSearched(true);
      setIsLoading(true);
      const payload = {};
      const appliedFilters = activeFilters.filter(({ criteria }) => !!criteria);
      filterTypes.forEach(({ value: filterKey }) => {
        const matchedFilter = appliedFilters.find(({ value: appliedFilterKey }) => appliedFilterKey === filterKey);
        payload[filterKey] = matchedFilter ? matchedFilter.criteria : null;
      });

      const response = await callAPI('/api/emulation/getFranchiseUserDetails', payload, 'post');
      const { data: { resultList = [] }, status, statusText } = response;
      if (status === 200) {
        setUsers(resultList);
      } else {
        throw Error(`${status} ${statusText}`);
      }
    } catch ({ message }) {
      setUsers([]);
      const TOO_MANY_RESULTS = '2005';
      if (message === TOO_MANY_RESULTS) {
        displayErrorNotification(t('soaResponse.tooManyResults'));
      } else {
        displayErrorNotification(t('site.error', { message }));
      }
    } finally {
      setIsLoading(false);
    }
  }, [callAPI, filterTypes, t, onSetPage]);

  const renderResults = () => {
    if (isLoading) {
      return (
        <div className='results'>
          {elastic}
        </div>
      );
    }

    const resultCount = users?.length || 0;

    if (!!resultCount && hasSearched) {
      return (
        <div className='results'>
          <div className='mb-2'>
            {t('misc.totalResults', { count: resultCount })}
          </div>
          <CompactTable
            columns={COLUMNS}
            data={{ nodes: users }}
            layout={{ custom: true, horizontalScroll: true }}
            sort={sort}
            theme={theme}
            pagination={pagination}
          />
          <Paginator
            currentPage={currentPage}
            totalPages={getTotalPages(users)}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            startAtZero
          />
        </div>
      );
    }
    if (hasSearched && !resultCount) return <div>{t('emulationMode.noUsersFound')}</div>;

    return null;
  };

  return (
    <div className='container' id='user-selection'>
      <header>
        <h1>{t('emulationMode.title')}</h1>
      </header>
      <p className='my-3'>{t('emulationMode.searchPrompt')}</p>
      <Filter
        filterTypes={filterTypes}
        onSubmit={onSubmit}
        placeholder={t('filter.placeholder')}
      />
      {renderResults()}
    </div>
  );
};

export default UserSelection;
