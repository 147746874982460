import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../hooks/useAuthentication';
import { getValueFromPath } from '../../utils/arrays';
import { displayErrorNotification } from '../Notification/Notify';
import CharacterCounter from './CharacterCounter';

const FormInputLookup = ({
  autocomplete,
  defaultValue,
  disabled,
  fieldClasses,
  fieldMessages,
  fieldName,
  icon,
  lookupAPI,
  lookupAvailableMessage,
  lookupNotAvailableMessage,
  lookupErrorMessage,
  label,
  maxLength,
  placeholder,
  readOnly,
  showCharacterCount,
  type,
  validationRules,
}) => {
  const {
    clearErrors,
    formState: { errors },
    getValues,
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useFormContext();
  const { t } = useTranslation();
  const [hasLookupError, setHasLookupError] = useState(null);
  const [isAvailable, setIsAvailable] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const fieldError = getValueFromPath(errors, fieldName);
  const formValues = getValues();
  const { callAPI } = useAuthentication();
  const fieldValue = watch(fieldName);

  const {
    REACT_APP_DEBOUNCE_DELAY_MS,
  } = process.env;
  const debounceDelay = parseInt(REACT_APP_DEBOUNCE_DELAY_MS, 10);

  const checkAvailability = useCallback(async (timeoutId) => {
    try {
      if (fieldValue && !readOnly) {
        setIsSearching(true);
        clearTimeout(timeoutId);

        const response = await callAPI(lookupAPI, { value: fieldValue }, 'post');
        const { data } = response;
        const { successful } = data;
        setHasLookupError(!successful);
        setIsAvailable(successful);
        if (successful) clearErrors(fieldName);
      }
    } catch {
      setIsAvailable(false);
      displayErrorNotification(lookupErrorMessage);
    } finally {
      setIsSearching(false);
    }
    return null;
  }, [callAPI, clearErrors, fieldName, fieldValue, lookupAPI, lookupErrorMessage, readOnly]);

  useEffect(() => {
    let timeoutId;
    if (fieldValue && !readOnly) {
      timeoutId = setTimeout(() => checkAvailability(timeoutId), debounceDelay);
    } else {
      setValue(fieldName, defaultValue);
    }
    return () => clearTimeout(timeoutId);
  }, [checkAvailability, debounceDelay, defaultValue, fieldName, fieldValue, readOnly, setValue]);

  useEffect(() => {
    if (hasLookupError) {
      const validateField = async () => {
        setError(fieldName, {
          message: lookupNotAvailableMessage,
          type: 'manual',
        });
      };

      validateField();
    } else {
      clearErrors(fieldName);
    }
  }, [clearErrors, fieldName, hasLookupError, lookupNotAvailableMessage, setError]);

  const {
    name,
    onBlur,
    onChange,
    ref,
  } = { ...register(fieldName, validationRules) };

  const messages = fieldMessages && [].concat(fieldMessages);

  const inputValue = getValueFromPath(formValues, fieldName);

  return (
    <div className={fieldClasses || 'col-12'}>
      {label && (
        <label className='form-label' htmlFor={fieldName}>
          {label}
          {!validationRules.required && !readOnly && (
            <small className='form-text form-message optional'>{t('form.optional')}</small>
          )}
          {readOnly && (
            <small className='form-text form-message optional'>{t('form.readOnly')}</small>
          )}
        </label>
      )}
      <div className='input-group'>
        {icon && (
          <span className='input-group-text'>{icon}</span>
        )}
        <input
          autoComplete={autocomplete}
          className={`form-control form-validation${fieldError ? ' error' : ''}`}
          data-testid={`${fieldName}`}
          defaultValue={defaultValue || inputValue}
          disabled={disabled}
          id={fieldName}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            trigger(fieldName);
          }}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          type={type}
        />
        {readOnly ? <span className='input-group-text'><i className='fa-solid fa-lock' /></span> : null}
        {fieldValue && !readOnly
          && (
            isSearching
              ? (
                <span className='input-group-text'>
                  <i className='fa-solid fa-spinner fa-spin' />
                </span>
              )
              : hasLookupError !== null && (
                <span
                  className={`input-group-text${isAvailable ? ' available' : ' not-available'}`}
                  data-tooltip-id={`${fieldName}-tooltip`}
                  data-tooltip-content={isAvailable ? lookupAvailableMessage : lookupNotAvailableMessage}
                  data-tooltip-place='right'
                >
                  <i className={`fas ${isAvailable ? 'fa-check' : 'fa-times'}`} />
                </span>
              )
          )}
      </div>
      { showCharacterCount && (
        <CharacterCounter
          inputValue={inputValue}
          maxLength={maxLength}
          validationRules={validationRules}
        />
      )}
      {messages?.map((message, index) => {
        const currentKey = index;
        return (
          <small className='form-text form-message' key={`${message}-${currentKey}`}>
            {message}
          </small>
        );
      })}
      <Tooltip id={`${fieldName}-tooltip`} />
    </div>
  );
};

FormInputLookup.propTypes = {
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  fieldMessages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  fieldName: PropTypes.string.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string,
  lookupAPI: PropTypes.string.isRequired,
  lookupAvailableMessage: PropTypes.string,
  lookupErrorMessage: PropTypes.string.isRequired,
  lookupNotAvailableMessage: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  showCharacterCount: PropTypes.bool,
  type: PropTypes.string.isRequired,
  validationRules: PropTypes.shape({
    maxLength: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        message: PropTypes.string,
        value: PropTypes.number,
      }),
    ]),
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    setValueAs: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
};

FormInputLookup.defaultProps = {
  autocomplete: 'on',
  defaultValue: undefined,
  disabled: false,
  fieldClasses: undefined,
  fieldMessages: undefined,
  icon: undefined,
  label: undefined,
  lookupAvailableMessage: '',
  lookupNotAvailableMessage: '',
  maxLength: undefined,
  placeholder: undefined,
  readOnly: undefined,
  showCharacterCount: false,
  validationRules: {},
};

export default FormInputLookup;
