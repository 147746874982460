import React, {
  useState, useEffect, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import cardPlaceholder from '../../pages/CardOrdering/images/svs-giftcard-placeholder.png';
import { CardOrderingContext } from '../../context/CardOrdering/CardOrderingContext';
import Image from '../Image/Image';
import { formatCurrency } from '../../utils/helpers';
import '../../styles/scss/ProductCard.scss';
import {
  initializeCart,
  manageErrorList,
  parseInput,
  removeFromCart,
  validateAndSetErrors,
  processCartEvent,
} from './productCardLogic';

const {
  REACT_APP_CIMS_SKU_IMAGE_PATH,
} = process.env;

const ProductCard = ({
  data, quantity: specifiedQuantity, onQuantityChange, trackErrors = false,
}) => {
  const { t, i18n: { language: currentLocale } } = useTranslation();
  const {
    cart,
    currencyDetails,
    setCart,
    invalidProductsInCart,
    setInvalidProductsInCart,
  } = useContext(CardOrderingContext);

  const {
    productId,
    sku,
    skuDescription,
    whsAvailQty,
    unitSize,
    inventoryActive,
    skuUnitRate,
  } = data;

  const cartIndex = cart.findIndex(({ productId: cartProductId }) => cartProductId === productId);
  const inCart = cartIndex > -1;

  const {
    cartButtonClassName, cartButtonTextKey, initialQuantity, quantityInCart,
  } = initializeCart({
    cart,
    cartIndex,
    inCart,
    specifiedQuantity,
  });

  const label = `${sku} - ${skuDescription}`;

  const [quantity, setQuantity] = useState(initialQuantity);
  const [errors, setErrors] = useState([]);

  const outOfStock = !whsAvailQty || !unitSize || whsAvailQty < unitSize;

  const maxPacks = Math.floor(whsAvailQty / unitSize) || 0;

  const validateInventory = () => setErrors(validateAndSetErrors(quantity, maxPacks, outOfStock, inventoryActive));
  useEffect(validateInventory, [quantity, outOfStock, inventoryActive, maxPacks]);

  const isValid = !errors.length && !outOfStock && inventoryActive;

  const matchesCartQuantity = quantityInCart === quantity;

  const editingQuantityInCart = inCart && !matchesCartQuantity;
  const settingInitialQuantity = !inCart && quantity;
  const editingClassName = editingQuantityInCart || settingInitialQuantity ? ' uncommitted' : '';

  const disallowSubmission = !isValid || quantity <= 0 || !matchesCartQuantity;
  const belongsInErrorList = trackErrors && inCart && disallowSubmission;
  const inErrorList = !!invalidProductsInCart.find(errProductId => errProductId === productId);

  useEffect(() => {
    manageErrorList(productId, invalidProductsInCart, setInvalidProductsInCart, belongsInErrorList, inErrorList);
  }, [inErrorList, belongsInErrorList, productId, invalidProductsInCart, setInvalidProductsInCart]);

  const handleQuantityChange = (event) => {
    const newQuantity = parseInput(event);
    setQuantity(newQuantity);
    if (onQuantityChange) {
      onQuantityChange({ newQuantity, productId });
    }
  };

  const handleCartUpdate = () => {
    processCartEvent({
      cart,
      cartIndex,
      inCart,
      label,
      productId,
      quantity,
      setCart,
      setQuantity,
      t,
    });
  };

  const handleRemoveCart = () => {
    removeFromCart({
      cart,
      message: t('cardOrdering.productCard.notifications.removedFromCart', { label, quantity }),
      productId,
      setCart,
      setQuantity,
    });
  };

  const skuUnitRateParsed = skuUnitRate || 0;
  const skuTotal = formatCurrency(skuUnitRateParsed * quantity, currentLocale, currencyDetails);

  const noCartQuantity = !inCart && !quantity;
  const disabledCartButton = !isValid || noCartQuantity || matchesCartQuantity;

  return (
    <div className={`product-card card${editingClassName}`}>
      <div className='card-img-top'>
        <Image
          src={`${REACT_APP_CIMS_SKU_IMAGE_PATH}/${sku}.png`}
          className='img-fluid'
          alt={label}
          fallback={cardPlaceholder}
        />
      </div>
      <div className='card-body'>
        <h5 className='card-title'>{label}</h5>
        <div className='card-text'>
          {t('cardOrdering.productCard.packSize', { packSize: unitSize?.toLocaleString(currentLocale) })}
        </div>
        <div className='card-text'>
          {t('cardOrdering.productCard.packRate', { skuRate: formatCurrency(skuUnitRateParsed, currentLocale, currencyDetails) })}
          {!skuUnitRateParsed && (
            <>
              <i
                className='rate-unavailable fa-solid fa-exclamation-circle ms-2'
                data-tooltip-id='rate-unavailable-tooltip'
                data-tooltip-content={t('cardOrdering.productCard.tooltips.rateDataUnavailable')}
                data-tooltip-place='bottom'
              />
              <Tooltip id='rate-unavailable-tooltip' />
            </>
          )}
        </div>
        {!isValid && (
          <div className='errors mt-1'>
            {errors.map((err) => (
              <div className='card-text error input-msg' key={`${productId}-${err}`}>
                {t(`cardOrdering.productCard.errors.${err}`)}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className='card-footer'>
        <div className='sku-total card-text mb-2'>{`${skuTotal}`}</div>
        {!outOfStock && inventoryActive && (
          <div className='actions'>
            <div className='pack-quantity'>
              <div className='input-group'>
                <input
                  className='form-control'
                  type='number'
                  min={0}
                  max={maxPacks}
                  step={1}
                  disabled={outOfStock}
                  onChange={(event) => handleQuantityChange(event)}
                  onWheel={(e) => e.target.blur()} // disable scrolling in number field
                  value={quantity}
                />
                <span className='input-group-text'>{t(`cardOrdering.productCard.pack${quantity === 1 ? '' : 's'}`)}</span>
              </div>
            </div>
            <div id='cart-buttons'>
              <div>
                <button
                  className={`btn btn-primary${cartButtonClassName}`}
                  type='button'
                  onClick={handleCartUpdate}
                  disabled={disabledCartButton}
                  data-tooltip-id='add-update-cart-tooltip'
                  data-tooltip-content={t(`cardOrdering.productCard.tooltips.${cartButtonTextKey}`)}
                  data-tooltip-place='top'
                >
                  <i className='fa-solid fa-cart-plus' />
                </button>
                <Tooltip id='add-update-cart-tooltip' />
              </div>
              {inCart && (
                <div>
                  <button
                    className='btn btn-primary remove-from-cart'
                    type='button'
                    onClick={handleRemoveCart}
                    data-tooltip-id='remove-from-cart-tooltip'
                    data-tooltip-content={t('cardOrdering.productCard.tooltips.removeFromCart')}
                    data-tooltip-place='top'
                  >
                    <i className='fa-solid fa-trash' />
                  </button>
                  <Tooltip id='remove-from-cart-tooltip' />
                </div>
              )}
            </div>
          </div>
        )}
        {outOfStock && (
          <div className='card-text error'>
            {t('cardOrdering.productCard.outOfStock')}
          </div>
        )}
        {!inventoryActive && (
          <div className='card-text error'>
            {t('cardOrdering.productCard.inactiveProduct')}
          </div>
        )}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  data: PropTypes.shape({
    inventoryActive: PropTypes.bool,
    maxQty: PropTypes.number,
    minQty: PropTypes.number,
    productId: PropTypes.number,
    sku: PropTypes.string,
    skuDescription: PropTypes.string,
    skuRate: PropTypes.string,
    skuUnitRate: PropTypes.number,
    unitSize: PropTypes.number,
    whsAvailQty: PropTypes.number,
  }).isRequired,
  onQuantityChange: PropTypes.func,
  quantity: PropTypes.number,
  trackErrors: PropTypes.bool,
};

ProductCard.defaultProps = {
  onQuantityChange: undefined,
  quantity: undefined,
  trackErrors: false,
};

export default ProductCard;
