/**
 * Takes a report and returns a list of stores for filtering (and other uses)
 * @param {object} reportData - Data for a report
 * @param {string} columnName - the name of the column that store data is in
 * @returns an array containing the list of stores in a report
 */
const storeListBuilder = (reportData, columnName) => {
  const storeList = [];
  reportData.forEach(datum => {
    try {
      storeList.push(datum[columnName].padStart(10, '0'));
    } catch {
      throw new Error(`Error fetching store for column name ${columnName}`);
    }
  });
  // convert to set because it filters the dups out
  return [...new Set(storeList)];
};

export default storeListBuilder;
