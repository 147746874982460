import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Auth0Provider } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { displayErrorNotification } from '../../components/Notification/Notify';
import { elastic } from '../../components/LoadingAnimations/LoadingAnimations';

const SSOProvider = ({ children }) => {
  const [ssoConfiguration, setSSOConfiguration] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const retrievedSsoConfig = useRef(false);

  const baseURL = new URL(window.location.href).origin;

  useEffect(() => {
    const getSSOConfiguration = async () => {
      const config = {
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };
      try {
        const { data = {} } = await axios.get('/api/authentication/getSSOConfiguration', config);
        setSSOConfiguration(data);
      } catch {
        displayErrorNotification(t('sso.errors.ssoUnavailable'), false);
      } finally {
        setLoading(false);
      }
    };

    if (!retrievedSsoConfig.current) { // prevents extraneous calls on locale change
      getSSOConfiguration();
      retrievedSsoConfig.current = true;
    }
  }, [t]);

  if (loading) return elastic;
  // don't use SSO provider if config isn't loaded, but need to handle this in Login component to disable SSO login logic
  if (!Object.keys(ssoConfiguration).length) return children;

  return (
    <Auth0Provider
      domain={ssoConfiguration.AUTH0_DOMAIN}
      clientId={ssoConfiguration.AUTH0_CLIENT}
      authorizationParams={{
        redirect_uri: `${baseURL}${ssoConfiguration.SSO_CALLBACK}`,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

SSOProvider.propTypes = {
  children: PropTypes.element,
};

SSOProvider.defaultProps = {
  children: null,
};

export default SSOProvider;
