import React from 'react';
import ReactDOM from 'react-dom/client';
import createContainer from './createContainer';
import NotificationManager from './NotificationManager';

const container = createContainer('notification-container');
let notify;

const root = ReactDOM.createRoot(container);
const notifyHandler = notifyFunction => {
  notify = notifyFunction;
};
root.render(
  <NotificationManager
    setNotify={notifyHandler}
  />,
);

export const displayErrorNotification = (children, autoClose = true) => notify({ autoClose, children, type: 'error' });
export const displayInfoNotification = (children, autoClose = true) => notify({ autoClose, children, type: 'info' });
export const displaySuccessNotification = (children, autoClose = true) => notify({ autoClose, children, type: 'success' });
export const displayWarningNotification = (children, autoClose = true) => notify({ autoClose, children, type: 'warning' });
