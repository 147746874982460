import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getValueFromPath } from '../../utils/arrays';
import CharacterCounter from './CharacterCounter';

/**
  If you get an error message about an object being returned instead of a string,
  add the returnObjects property in an argument with the translation function call:

      t(`form.step${step}.fields.myFieldName.fieldMessages`, { returnObjects: true })

  Reference: https://www.i18next.com/translation-function/objects-and-arrays
*/

const FormInputField = ({
  autocomplete,
  defaultValue,
  fieldClasses,
  fieldMessages,
  fieldName,
  icon,
  label,
  maxLength,
  placeholder,
  readOnly,
  type,
  validationRules,
  showCharacterCount,
}) => {
  const {
    formState,
    getValues,
    watch,
    register,
  } = useFormContext();
  const { t } = useTranslation();

  const { errors } = formState;
  const fieldError = getValueFromPath(errors, fieldName);

  const formValues = getValues();

  // watches the fieldName value provided to allow form to track current selection
  watch([fieldName]);

  const {
    disabled,
    name,
    onBlur,
    onChange,
    ref,
  } = { ...register(fieldName, validationRules) };

  // convert the fieldMessage value to an array if it is a string for later mapping
  const messages = fieldMessages && [].concat(fieldMessages);

  const inputValue = getValueFromPath(formValues, fieldName);

  return (
    <div className={fieldClasses || 'col-12'}>
      {label && (
        <label className='form-label' htmlFor={fieldName}>
          {label}
          {!validationRules.required && !readOnly && <small className='form-text form-message optional'>{t('form.optional')}</small>}
          {readOnly && <small className='form-text form-message optional'>{t('form.readOnly')}</small>}
        </label>
      )}
      <div className='input-group'>
        {icon && (
          <span className='input-group-text'>{icon}</span>
        )}
        <input
          autoComplete={autocomplete}
          className={`form-control form-validation${fieldError ? ' error' : ''}`}
          data-testid={fieldName}
          defaultValue={defaultValue || inputValue}
          disabled={disabled}
          id={fieldName}
          maxLength={maxLength}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
          type={type}
        />
        { readOnly ? <span className='input-group-text'><i className='fa-solid fa-lock' /></span> : null }
      </div>
      {showCharacterCount && (
        <CharacterCounter
          inputValue={inputValue}
          maxLength={maxLength}
          validationRules={validationRules}
        />
      )}
      {messages?.map((message, index) => {
        const currentKey = index;
        return <small className='form-text form-message' key={`${message}-${currentKey}`}>{message}</small>;
      })}
      <small className={`text-right form-text form-validation error message${fieldError ? ' active' : ''}`}>{fieldError?.message}</small>
    </div>
  );
};

FormInputField.propTypes = {
  autocomplete: PropTypes.string,
  defaultValue: PropTypes.string,
  fieldClasses: PropTypes.string,
  fieldMessages: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  fieldName: PropTypes.string.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  showCharacterCount: PropTypes.bool,
  type: PropTypes.string.isRequired,
  validationRules: PropTypes.shape({
    maxLength: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        message: PropTypes.string,
        value: PropTypes.number,
      }),
    ]),
    ref: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          message: PropTypes.string,
          value: PropTypes.bool,
        }),
      ]),
    ]),
    required: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    setValueAs: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
};

FormInputField.defaultProps = {
  autocomplete: 'on',
  defaultValue: undefined,
  fieldClasses: undefined,
  fieldMessages: undefined,
  icon: undefined,
  label: undefined,
  maxLength: undefined,
  placeholder: undefined,
  readOnly: undefined,
  showCharacterCount: false,
  validationRules: {},
};

export default FormInputField;
