import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import FormInputField from '../../components/Form/FormInputField';
import FormReactSelectField from '../../components/Form/FormReactSelectField';
import LoginHeader from '../../components/Header/LoginHeader';
import ModalForm from '../../components/ModalForm/ModalForm';
import useAuthentication from '../../hooks/useAuthentication';
import { displayErrorNotification } from '../../components/Notification/Notify';
import { specialCharactersPattern } from '../../helpers/validation';
import LocaleSelector from '../../components/Header/LocaleSelector';

const ChangePassword = () => {
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState('');
  const [jwt, setJWT] = useState('');
  const [identities, setIdentities] = useState([]);
  const [isExpiredPassword, setIsExpiredPassword] = useState(false);
  const { callAPI } = useAuthentication();
  const methods = useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const {
    handleSubmit, watch,
  } = methods;
  const newPasswordFieldValue = watch('newPassword');
  const generatePasswordRuleInformation = () => {
    const {
      passwordRules: {
        maxPasswordLength,
        minPasswordLength,
        passwordMinCapitals,
        passwordMinNumerics,
        passwordMinSpecialChar,
        passwordSpecialCharSet,
      },
    } = selectedIdentity;
    return (
      <ul id='rules'>
        <li>{t('form.login.password.create.rules.numerics', { passwordMinNumerics, returnObjects: true })}</li>
        <li>{t('form.login.password.create.rules.capitals', { passwordMinCapitals, returnObjects: true })}</li>
        <li>{t('form.login.password.create.rules.length', { maxPasswordLength, minPasswordLength, returnObjects: true })}</li>
        <li>
          {t('form.login.password.create.rules.special', { passwordMinSpecialChar, returnObjects: true })}
          <span>{passwordSpecialCharSet}</span>
        </li>
      </ul>
    );
  };

  const generatePasswordFields = () => {
    const {
      passwordRules: {
        maxPasswordLength,
        minPasswordLength,
        passwordMinCapitals,
        passwordMinNumerics,
        passwordMinSpecialChar,
        passwordSpecialCharSet,
      },
    } = selectedIdentity;
    return (
      <>
        <FormInputField
          fieldClasses='password'
          fieldName='newPassword'
          icon={<i className='fa-solid fa-lock' />}
          key='newPassword'
          placeholder={t('form.login.password.create.newPassword.label')}
          type='password'
          validationRules={{
            maxLength: {
              message: t('form.login.password.create.validation.maxLength', { maxPasswordLength, returnObjects: true }),
              value: maxPasswordLength,
            },
            minLength: {
              message: t('form.login.password.create.validation.minLength', { minPasswordLength, returnObjects: true }),
              value: minPasswordLength,
            },
            pattern: {
              message: t('form.login.password.create.validation.pattern', {
                passwordMinCapitals, passwordMinNumerics, passwordMinSpecialChar, returnObjects: true,
              }),
              value: specialCharactersPattern(passwordSpecialCharSet),
            },
            required: t('form.login.password.create.validation.required'),
          }}
        />
        <FormInputField
          fieldClasses='password'
          fieldName='confirmPassword'
          icon={<i className='fa-solid fa-lock' />}
          key='confirmPassword'
          placeholder={t('form.login.password.create.confirmPassword.label')}
          type='password'
          validationRules={{
            required: t('form.login.password.create.validation.required'),
            validate: (value) => value === newPasswordFieldValue || t('form.login.password.create.validation.mismatch'),
          }}
        />
      </>
    );
  };

  const handleMessageAcknowledgment = useCallback(() => {
    setPasswordChanged(false);
    navigate('/login');
  }, [navigate]);

  const setPassword = async (data) => {
    try {
      const {
        newPassword: password,
      } = data;
      const body = {
        isExpiredPassword,
        locId: selectedIdentity.locId,
        password,
        signonId: selectedIdentity.signonId,
        token: jwt,
      };
      const response = await callAPI('/api/security/changePassword', body, 'post');
      const { data: { code } } = response;
      switch (code) {
      case '1000': // SUCCESSFUL
        setPasswordChanged(true);
        break;
      case '2009': // PASSWORD_ALREADY_USED
        displayErrorNotification(t('form.login.password.create.errors.previousUsage'));
        break;
      case '2014': // PASSWORD_VIOLATES_RULES
        displayErrorNotification(t('form.login.password.create.errors.ruleViolation'));
        break;
      case '2017': // AUTHENTICATION_SERVER_ERROR
        displayErrorNotification(t('form.login.password.create.errors.serverError'));
        break;
      default:
        displayErrorNotification(t('form.login.password.create.errors.unknown'));
        break;
      }
    } catch {
      displayErrorNotification(t('form.login.password.create.errors.unknown'));
    }
  };

  const updateUsername = useCallback(signonId => {
    const [identityObject] = identities.filter((i) => i.signonId === signonId);
    setSelectedIdentity(identityObject);
  }, [identities]);

  useEffect(() => {
    const validateToken = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const token = decodeURIComponent(searchParams.get('token'));
        setJWT(token);
        const response = await callAPI('/api/authentication/validateToken', { token }, 'post');
        const {
          data: {
            identities: i,
            locale,
          } = {},
        } = response;
        setIdentities(i);
        if (!i?.length) navigate('/login');
        if (i?.length === 1) {
          const [identity] = i;
          if (identity?.isExpiredPassword) setIsExpiredPassword(true);
          setSelectedIdentity(identity);
        }
        i18n.changeLanguage(locale.replace('_', '-'));
      } catch {
        navigate('/login');
      }
    };
    validateToken();
  }, [callAPI, i18n, location, navigate]);

  return (
    <>
      <ModalForm
        confirmAction={handleMessageAcknowledgment}
        cancelAction=''
        modalTitle={t('form.login.password.create.confirmationTitle')}
        visible={passwordChanged}
      >
        <div>{t('form.login.password.create.confirmationMessage')}</div>
      </ModalForm>
      <div className='header-menu'>
        <LocaleSelector />
      </div>
      <div className='login reset container'>
        <LoginHeader />
        <FormProvider {...methods}>
          <form className='row g-2' onSubmit={handleSubmit(setPassword)}>
            {!selectedIdentity

              ? identities.length > 1 && (
                <>
                  <div className='text-center mb-2'>{t('form.login.password.create.selectUsername')}</div>
                  <FormReactSelectField
                    className='username-select'
                    classNamePrefix='username-select'
                    fieldName='identity'
                    handleChange={updateUsername}
                    options={
                      identities.map((identity) => {
                        const { signonId } = identity;
                        return ({
                          label: signonId,
                          value: signonId,
                        });
                      })
                    }
                  />
                </>
              ) : (
                <div>
                  <h2>
                    <span>{`${t('form.login.username.label')}: ${selectedIdentity.signonId}`}</span>
                    {identities.length > 1 && <i className='fa-regular fa-pen-to-square' id='shippingEditBtn' onClick={() => setSelectedIdentity('')} role='button' tabIndex={0} />}
                  </h2>
                  <p>{t('form.login.password.create.rules.description')}</p>
                  {generatePasswordRuleInformation()}
                  {generatePasswordFields()}
                  <div id='login-controls'>
                    <div id='submit-button'>
                      <button className='btn w-100' type='submit'>{t('form.login.password.create.submitButtonText')}</button>
                    </div>
                  </div>
                </div>
              )}
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default ChangePassword;
